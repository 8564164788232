import React from 'react';
import styled from 'styled-components';

const StyledLevel2Nav = styled.div`
  position: fixed;
  z-index: 11;
  top: 179px;
  left: 0;
  width: 300px;
  list-style: none;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[20]};
  ${(props) => props.theme.media.tablet`
      top: 60px;
    `}
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
      background-color: ${props.theme.colors.kuluttaja2021.tummaPetroli[10]};
      max-width: 310px;
      width: 95vw;
      height: 100vh;
      left: inherit;
      right: 0;
      > li:first-of-type{
          border-top: 1px solid ${props.theme.colors.kuluttaja2021.harmaa[20]};
        }
  `};
  ${(props) => props.theme.media.mobileSmall`
    top: 0;
    padding-top: 60px;
  `};
`;

const OverflowScroll = styled.ul`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - ${(props) => props.$headerHeight}px);
  > li:last-child {
    margin-bottom: 60px;
  }
  ${(props) => props.theme.media.tablet`
    border-top: 1px solid ${props.theme.colors.kuluttaja2021.harmaa[20]};
    `}
  ${(props) => props.theme.media.tablet`
    height: calc(100vh - 60px);
  `}
`;

const Level2Nav = ({
  children,
  size,
  useColumns,
  category,
  title,
  tabletHeader,
  headerHeight,
  ...rest
}) => {
  return (
    <StyledLevel2Nav $tabletHeader={tabletHeader} {...rest}>
      <OverflowScroll $headerHeight={headerHeight}>{children}</OverflowScroll>
    </StyledLevel2Nav>
  );
};

export default Level2Nav;
