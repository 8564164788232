import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WpImage from '@/components/Images/WpImage';
import _get from 'lodash.get';
import Container from '@/browser/Container';
import Content from '@/browser/Content';

/**
 * Styled component for the LiftGrid.
 */
const LiftGrid = styled.div`
  width: 100%;
  padding: 100px 0;
  margin: 50px 0 40px;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #f6fafb;
  }
`;

/**
 * Styled component for the LiftGridInner.
 */
const LiftGridInner = styled(Container)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 20px;
`;

/**
 * Styled component for the LiftAuthor.
 */
const LiftAuthor = styled.div`
  text-align: center;
  flex-basis: 30%;
  width: 30%;
  padding: 0 80px 0 40px;
  overflow: visible;

  ${(props) => props.theme.media.tablet`
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    order:2;
    margin-top:25px;
  `};

  img {
    width: 214px;
    height: 214px;
    min-width: 214px;
    min-height: 214px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto 20px;
  }
`;

/**
 * Styled component for the AuthorName.
 */
const AuthorName = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 800;
  font-size: 17px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

/**
 * Styled component for the AuthorEmail.
 */
const AuthorEmail = styled.a`
  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: 800;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.darkGrey};
  text-transform: uppercase;
  text-decoration: none;
`;

/**
 * Styled component for the LiftContent.
 */
const LiftContent = styled.div`
  flex-basis: 70%;
  max-width: 70%;
  order: 2;
  p {
    margin-bottom: 30px;
    margin-top: 0;
  }
  ${(props) => props.theme.media.tablet`
    flex-basis: 100%;
    max-width: 100%;
    order:1;
  `};
`;

/**
 * Styled component for the LiftCategory.
 */
const LiftCategory = styled.div`
  font-family: ${(props) => props.theme.fonts.primary};
  text-transform: uppercase;
  font-size: 11px;
  color: ${(props) => props.theme.colors.grey};
  font-weight: 800;
  line-height: 17px;
  letter-spacing: 1.65px;
  margin-bottom: 8px;
`;

/**
 * Styled component for the LiftText.
 */
const LiftText = styled(Content)`
  & > div {
    columns: 2;
    ${(props) => props.theme.media.mobile`
      columns:1
    `};

    .dropcap {
      font-size: 16px;
      color: ${(props) => props.theme.colors.darkGrey};

      p {
        margin-top: 0;
        &:first-letter {
          font-size: 80px;
          color: ${(props) => props.theme.colors.gunmetal};
          font-family: ${(props) => props.theme.fonts.secondary};
          font-weight: 900;
        }
      }
    }
  }
`;

/**
 * React component for displaying the head of an article.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Object} props.article - Article data.
 * @param {string} props.content - Article content.
 * @returns {JSX.Element} React component.
 */
const ArticleHeadLift = ({ article, content }) => {
  if (!article || typeof article !== 'object') {
    return null;
  }

  const articleText = content
    ? content
    : `<div class="dropcap">${article.excerpt}</div>`;

  const categoryTitle = _get(article, 'categories.nodes[0].name');
  const authorExtraName = _get(article, 'extra.article_author');
  const authorExtraEmail = _get(article, 'extra.author_email');
  const authorExtraImage = _get(article, 'extra.author_image');

  return (
    <LiftGrid>
      <LiftGridInner>
        <LiftContent>
          {categoryTitle && <LiftCategory>{categoryTitle}</LiftCategory>}
          <h2>{article.title}</h2>
          <LiftText html={articleText} />
        </LiftContent>
        <LiftAuthor>
          {authorExtraImage ? (
            <WpImage image={authorExtraImage} />
          ) : (
            <img
              src={article.author.node.avatar.url}
              alt={article.author.node.name}
            />
          )}
          <div>
            <AuthorName>
              {authorExtraName || article.author.node.name}
            </AuthorName>
            <AuthorEmail
              href={'mailto:' + authorExtraEmail || article.author.node.email}
            >
              {authorExtraEmail || article.author.node.email}
            </AuthorEmail>
          </div>
        </LiftAuthor>
      </LiftGridInner>
    </LiftGrid>
  );
};

ArticleHeadLift.propTypes = {
  article: PropTypes.object,
  content: PropTypes.string
};

ArticleHeadLift.defaultProps = {
  article: {},
  content: ''
};

export default ArticleHeadLift;
