export const CART_INCREMENT = 'cart/count/INCREMENT';
export const SET_DETAILS = 'cart/SET_DETAILS';

const initialState = {
  details: {},
  itemCount: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_INCREMENT:
      return {
        ...state,
        itemCount: state.itemCount + 1
      };
    case SET_DETAILS:
      return {
        ...state,
        details: Object.assign({}, state.details, action.data)
      };
    default:
      return state;
  }
};

export default reducer;

export const incrementCount = () => {
  return {
    type: CART_INCREMENT
  };
};

export const setDetails = (data) => {
  return {
    type: SET_DETAILS,
    data
  };
};
