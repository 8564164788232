import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import MetaValue from './MetaValue';
import MetaValueProtection from './MetaValueProtection';
import Reference from './Reference';
import DropdownOpen from '@/components/Buttons/DropdownOpen';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const LeftColWidth = '450px';
const CompWidth = '184px';

/**
 * Styled component for the comparison row.
 */
const StyledRow = styled.div`
  width: 100%;
  &.fadeslide-enter {
    overflow: hidden;
    max-height: 1px;
  }
  &.fadeslide-enter-active {
    overflow: hidden;
    max-height: 1000px;
    transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &.fadeslide-exit {
    overflow: hidden;
    max-height: 1000px;
  }
  &.fadeslide-exit-active {
    overflow: hidden;
    max-height: 1px;
    transition: all 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

/**
 * Styled component for the inner row content.
 */
const StyledRowInner = styled.div`
  display: flex;
`;

/**
 * Styled component for the left cell.
 */
const LeftCell = styled.div`
  font-weight: ${(props) =>
    props.$isDropDown || props.$level === '1' ? 'bold' : 'normal'};
  font-size: ${(props) =>
    props.$isDropDown || props.$level === '1' ? '11px' : 'inherit'};
  font-family: ${(props) =>
    props.$isDropDown
      ? props.theme.fonts.secondary
      : props.theme.fonts.primary};
  text-transform: ${(props) =>
    props.$isDropDown || props.$level === '1' ? 'uppercase' : 'none'};
  letter-spacing: ${(props) =>
    props.$isDropDown || props.$level === '1' ? '2.2px' : 'normal'};
  color: ${(props) =>
    props.$isDropDown || props.$level === '1'
      ? props.theme.colors.gunmetal
      : 'inherit'};

  width: ${LeftColWidth};
  min-width: ${LeftColWidth};
  padding: 12px 16px 12px
    ${(props) =>
      props.$level > 0 ? (32 * parseFloat(props.$level)).toString() : '32'}px;
  cursor: ${(props) => (props.$isDropDown ? 'pointer' : 'normal')};
  display: flex;
  align-items: center;
  &.leftcell-lvl-P {
    text-transform: capitalize;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    font-size: 17px;
  }
  z-index: 2;

  background-color: #fff;
  border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  border-style: solid;
  border-width: 0 2px 1px 0;
  box-sizing: border-box;
  position: relative;
  ${(props) => props.theme.media.desktop`
    position: sticky;
    left: 0;
  `};
  &:before {
    ${(props) => props.theme.media.tablet`
      display: flex;
      content: ' ';
      background: linear-gradient(to right, rgba(164, 166, 208,0.65) 0%,rgba(164, 166, 208,0) 100%);
      opacity: 0.2;
      width: 5px;
      position: absolute;
      right: -7px;
      height: calc(100% + 1px);
      top: 0;
    `}
  }
`;

/**
 * Styled component for the comparison column.
 */
const CompCol = styled.div`
  padding: 12px 16px;
  width: ${CompWidth};
  min-width: ${CompWidth};
  color: ${(props) => props.theme.colors.gunmetal};
  border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  border-style: solid;
  border-width: 0 2px 1px 0;
  text-align: center;
  box-sizing: border-box;
`;

/**
 * Styled component for the dropdown button.
 */
const Dropdown = styled(DropdownOpen)`
  border: 0;
  &:before,
  &:after {
    background-color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    background-color: transparent;
    &:before,
    &:after {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

/**
 * Styled component for the weight information.
 */
const Weight = styled.div`
  color: ${(props) => props.theme.colors.grey};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  justify-self: flex-end;
  flex-grow: 1;
  text-align: right;
`;

/**
 * Styled component for the cell title.
 */
const CellTitle = styled.div`
  flex-grow: 0;
`;

/**
 * Functional component for the ComparisonRow.
 *
 * @param {Object} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
const ComparisonRow = (props) => {
  const { row, products, children, references, reference_descriptions } = props;

  const [active, setActive] = useState(true); // Use state hook for 'active' state

  const hasChildren =
    children && Array.isArray(children) && children.length > 0;

  const toggleActive = (event) => {
    event.preventDefault();
    if (!event.target.classList.contains('infoIcon')) {
      setActive(!active); // Update the 'active' state
    }
  };

  const onClick = hasChildren ? toggleActive : null;

  return (
    <StyledRow
      $even={row.orderNum % 2 === 0}
      $level={row.level}
      $hasChildren={hasChildren}
      className={`comprow row-lvl-${row.level ? row.level : '0'}`}
    >
      <StyledRowInner>
        <LeftCell
          $level={row.level}
          onClick={onClick}
          $isDropDown={hasChildren && row.level !== 'P'}
          className={`leftcell leftcell-lvl-${row.level ? row.level : '0'}`}
        >
          {hasChildren && row.level !== 'P' && <Dropdown $active={active} />}
          <CellTitle>
            {row.name.toLowerCase() === 'hinta' ? 'Hintaluokka' : row.name}{' '}
          </CellTitle>
          {row.weight && <Weight>{row.weight}</Weight>}
          <Reference
            column={row}
            references={references}
            descriptions={reference_descriptions}
          />
        </LeftCell>
        {products.map((p) => (
          <CompCol key={`cell-${row.name}-${p.id}`} className={`compCol`}>
            <MetaValueProtection column={row}>
              <MetaValue column={row} product={p} />
              <Reference
                column={row}
                product={p}
                descriptions={reference_descriptions}
              />
            </MetaValueProtection>
          </CompCol>
        ))}
      </StyledRowInner>
      <TransitionGroup>
        {hasChildren &&
          active &&
          children.map((row, rowIndex) => (
            <CSSTransition
              timeout={250}
              classNames="fadeslide"
              key={`row-${row.name}-${rowIndex}`}
            >
              <ComparisonRow
                row={row}
                children={row.children}
                products={products}
                references={references}
                reference_descriptions={reference_descriptions}
              />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledRow>
  );
};

ComparisonRow.propTypes = {
  row: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  children: PropTypes.array,
  references: PropTypes.array,
  reference_descriptions: PropTypes.array
};

export default ComparisonRow;
