import React from 'react';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import styled from 'styled-components';
import Link from '@/components/Link/Link';
import _get from 'lodash.get';
import QUERY_GET_MENU from '@/graphql/wordpress/menus/queryGetMenu';

const StyledMenu = styled.ul`
  padding: 0;
  margin: 0;
  background: transparent;
  display: flex;
  justify-content: space between;
  flex-wrap: wrap;
  width: 98%;
  max-width: 1000px;
  ${(props) => props.theme.media.tablet`
    justify-content: start;
  `}
  ${(props) => props.theme.media.mobileSmall`
    display: block;
  `};
  li {
    padding-right: 30px;
    margin-bottom: 16px;
    ${(props) => props.theme.media.mobileSmall`
      &:last-of-type{
        margin-bottom: 0;
      }
    `};
    &,
    a {
      text-decoration: underline;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: ${(props) => props.theme.colors.white};
      font-size: 10px;
    }
  }
`;

/**
 * FooterMenu component for displaying the footer menu.
 *
 * @returns {JSX.Element} - Rendered FooterMenu component.
 */
const FooterMenu = () => {
  const ga_data = { event: 'nav_link', prefix: 'nav', nav_type: 'footermenu' };
  return (
    <QueryHandler
      query={QUERY_GET_MENU}
      variables={{ id: 'footer_menu' }}
      fetchPolicy="cache-first"
      hide_loader
    >
      {({ menu }) => {
        const footerMenu = _get(menu, 'menuItems.nodes');

        if (!footerMenu) {
          return null;
        }

        return (
          <StyledMenu>
            {footerMenu.map((node) => (
              <li key={node.id}>
                <Link to={node.uri} ga_data={ga_data}>
                  {node.label}
                </Link>
              </li>
            ))}
          </StyledMenu>
        );
      }}
    </QueryHandler>
  );
};

export default FooterMenu;
