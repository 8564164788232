import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@/components/Link/Link';

const StyledCartButton = styled(Link)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  margin-right: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.media.tablet`
    margin-right: 0;
    font-size: 22px;
    min-width: 60px;
    height: 60px;
    padding: 0 3px;
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};

    &:hover {
      color: ${(props) => props.theme.colors.white};
    }

    span {
      font-size: 16px;
    }
  `};
`;

const AnimationWrapper = styled.div`
  transform-origin: top center;
  animation-name: ${(props) => props.$swing};
  animation-duration: 1s;
  animation-fill-mode: both;

  @keyframes swing {
    20% {
      transform: rotate3d(0, 0, 1, 15deg);
    }
    40% {
      transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
      transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
      transform: rotate3d(0, 0, 1, -5deg);
    }
    to {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
`;

const CartButton = (props) => {
  if (!props) {
    return null;
  }
  const { isLink, swing, cartCount, to, onClick } = props;
  if (isLink) {
    return (
      <StyledCartButton to={to}>
        <AnimationWrapper $swing={swing}>
          <FontAwesomeIcon icon={['fal', 'shopping-basket']} />
          &nbsp;
          <span>{cartCount}</span>
        </AnimationWrapper>
      </StyledCartButton>
    );
  }
  return (
    <StyledCartButton onClick={onClick} to={to}>
      <AnimationWrapper $swing={swing}>
        <FontAwesomeIcon icon={['fal', 'shopping-basket']} />
        &nbsp;
        <span>{cartCount}</span>
      </AnimationWrapper>
    </StyledCartButton>
  );
};
CartButton.propTypes = {
  isLink: PropTypes.bool,
  swing: PropTypes.string,
  cartCount: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export default CartButton;
