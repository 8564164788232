/**
 * This is the query to get the posts for article list.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_CATEGORY_CHILDREN_MODEL from '@/graphql/wordpress/_fragments/fragmentCategoryChildrenModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const QUERY_GET_PAGINATED_POSTS = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_CATEGORY_CHILDREN_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  query getPaginatedPosts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $termIdString: String
    $taxonomy: TaxonomyEnum = CATEGORY
  ) {
    posts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        taxQuery: {
          taxArray: [
            {
              terms: [$termIdString]
              field: ID
              includeChildren: true
              taxonomy: $taxonomy
            }
          ]
        }
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        paywall {
          premium
        }
        ...ContentNodeBasicModel
        title
        slug
        excerpt
        content
        date
        featuredImage {
          node {
            ...FeaturedImageModel
          }
        }
        categories {
          nodes {
            ...CategoryChildrenModel
          }
        }
      }
    }
  }
`;

export default QUERY_GET_PAGINATED_POSTS;
