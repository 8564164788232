import styled from 'styled-components';
import ExclIcon from '@/icons/exclamationCircleSolid.svg';

const ErrorBarWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.lightTan};
  padding: 17px 24px 19px 53px;
  font-size: 14px;
  position: relative;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 16px;
`;

const Icon = styled.img`
  width: 1.4em;
  position: absolute;
  top: 15px;
  left: 24px;
  cursor: pointer;
`;

/**
 * Generic error bar component
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Function} props.children - Render prop function to render children with augmented posts.
 * @returns {JSX.Element} React component.
 */
const ErrorBar = ({ children }) => {
  return (
    <ErrorBarWrapper className="errorbar">
      <Icon src={ExclIcon} alt="error icon" />
      {children}
    </ErrorBarWrapper>
  );
};

export default ErrorBar;
