import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tabs, StyledTab } from '@/components/Navigation/Subnavigation';
import Link from '@/components/Link/Link';

const StyledTabs = styled(Tabs)`
  margin-bottom: 1.5em;
  display: flex;
  > li {
    flex: auto;

    a {
      width: 100%;

      &.active {
        background-color: ${(props) =>
          props.theme.colors.kuluttaja2021.tummaPetroli.full};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
  ${(props) => props.theme.media.mobile`
    border: 0;
    margin-bottom: 0;
    > li {
      padding: 8px;
      background-color: transparent;
      &:hover{
        background-color: transparent;
      }
      > a,
      > div {
        display: block;
        height: 8px;
        width: 8px;
        padding: 0;
        border-radius: 50%;

        span {
          display: none;
        }
      }

      > a {
        background-color: ${props.theme.colors.kuluttaja2021.harmaa[20]};
      }
    }
  `}
`;

const LinklessStyledTab = styled.li`
  display: inline-block;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  cursor: default;
  background-color: ${(props) =>
    props.$active
      ? props.$bgcolor || props.theme.colors.kuluttaja2021.tummaPetroli.full
      : '#FFF'};
  transition: all 150ms;
  text-align: center;
  color: ${(props) => (props.$active ? '#FFF' : props.theme.colors.primary)};
  > * {
    display: inline-block;
    padding: 1.2em 2em;
    color: ${(props) => (props.$active ? '#FFF' : props.theme.colors.primary)};
  }
  ${(props) =>
    props.$active
      ? `border-bottom: solid 5px ${props.theme.mediumBlue}`
      : 'border-bottom: none;'};
  ${(props) => props.theme.media.mobile`
      > a,
      > div {
        ${(props) =>
          props.$active
            ? `background-color: ${props.theme.colors.paleteal};`
            : `background-color: ${props.theme.colors.kuluttaja2021.harmaa[20]};`};
      }
  `}
`;

const CheckoutTab = styled.div`
  padding: 16.8px 28px;
`;

const CheckoutTabs = ({ tabs }) => {
  if (tabs.length === 0) {
    return null;
  }
  return (
    <StyledTabs>
      {tabs.map((t, i) => {
        const Tab = ({ active, bgcolor, ...rest }) =>
          t.disabled ? (
            <LinklessStyledTab $active={active} $bgcolor={bgcolor} {...rest} />
          ) : (
            <StyledTab $active={active} $bgcolor={bgcolor} {...rest} />
          );
        const label = `${t.orderNum}. ${t.title}`;
        return (
          <Tab active={t.active} key={i}>
            {t.disabled ? (
              <CheckoutTab>
                <span>{label}</span>
              </CheckoutTab>
            ) : (
              <Link to={t.path}>
                <span>{label}</span>
              </Link>
            )}
          </Tab>
        );
      })}
    </StyledTabs>
  );
};

CheckoutTabs.propTypes = {
  tabs: PropTypes.array.isRequired
};
CheckoutTabs.defaultTypes = {
  tabs: []
};

export default CheckoutTabs;
