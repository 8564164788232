import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 0.6875rem;

  img {
    width: 4rem;
    height: auto;
  }
`;

const PrisjaktCoOperation = () => {
  return (
    <Wrapper>
      <FormattedMessage
        id="productReview.text.commercialCooperation"
        tagName="strong"
      />
      <img src={'/hintaopas_logo_fi_dark_small.png'} alt="Hintaopas" />
    </Wrapper>
  );
};

export default PrisjaktCoOperation;
