import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Content from '@/browser/Content';
import FaAngleRight from '@/icons/FaAngleRight.svg';

const BlackListContainer = styled.div`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};

  &:last-of-type {
    border-bottom: 0;
  }
`;
const BlackListTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 18% 2%;
  padding: 17px 22px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background: ${(props) => props.theme.colors.kuluttaja2021.lila[10]};
  }

  h2,
  h3,
  h4 {
    font-size: 16px;
    line-height: 22px;
    margin: 0;

    span {
      display: none;
      ${(props) => props.theme.media.tablet`
        display:inline-block;
        font-weight:400;
      `};
    }

    ${(props) => props.theme.media.tablet`
      margin-bottom:16px;
    `};
  }
  ${(props) => props.theme.media.tablet`
    display:block;
  `};
`;

const MobileMetaTag = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary};
  color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[80]};
  letter-spacing: 1.5px;
  margin-bottom: 4px;
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
    `}
`;

const BlackListReviewsExpander = styled.div`
  background-image: url(${FaAngleRight});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 22px;
  width: 22px;
  font-size: 16px;
  transform: rotate(90deg);
  transition: all 300ms ease;
  ${(props) => props.theme.media.tablet`
    float:right;
    margin-top:-10px;
  `};
`;
const BlackListReviewsReducer = styled(BlackListReviewsExpander)`
  transform: rotate(-90deg);
`;
const ReviewWrapper = styled.div`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  border-top: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  padding: 33px 65px 38px 56px;
`;
const ReviewEntry = styled.div`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  padding: 23px 0;
  display: grid;
  grid-template-columns: minmax(200px, 338px) auto;
  ${(props) => props.theme.media.mobile`
    grid-template-columns: auto;
  `} &:last-of-type {
    border-bottom: 0;
  }
`;
const TitleColumn = styled.div`
  h5 {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
`;
const ReviewDate = styled.div`
  font-size: 11px;
  line-height: 17px;
  color: grey;
  letter-spacing: 1.65px;
`;
const ContentColumn = styled.div`
  > div {
    padding-bottom: 20px;
    line-height: 26px;
  }
  > h6 {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
`;

class BlackListEntry extends Component {
  static propTypes = {
    company: PropTypes.string,
    industry: PropTypes.string,
    location: PropTypes.string,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        content: PropTypes.string,
        solution: PropTypes.string
      })
    )
  };

  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { company, industry, location, reviews } = this.props;
    const { active } = this.state;

    if (!company) {
      return null;
    }

    return (
      <BlackListContainer>
        <BlackListTitleWrapper onClick={this.toggleActive}>
          <div>
            <MobileMetaTag>
              <FormattedMessage id="blacklist.company" />
            </MobileMetaTag>
            <h2>{company}</h2>
          </div>
          <div>
            {industry && (
              <React.Fragment>
                <MobileMetaTag>
                  <FormattedMessage id="blacklist.industry" />
                </MobileMetaTag>
                <h3>{industry}</h3>
              </React.Fragment>
            )}
          </div>
          <div>
            {location && (
              <React.Fragment>
                <MobileMetaTag>
                  {' '}
                  <FormattedMessage id="blacklist.location" />
                </MobileMetaTag>
                <h4>{location}</h4>
              </React.Fragment>
            )}
          </div>
          <div>
            {Array.isArray(reviews) ? (
              <h4>
                <FormattedMessage id="blacklist.reviewCount" /> {reviews.length}
              </h4>
            ) : (
              <h4>
                <FormattedMessage id="blacklist.reviewCount" /> 0
              </h4>
            )}
          </div>
          <div>
            {Array.isArray(reviews) &&
              (!active ? (
                <BlackListReviewsExpander />
              ) : (
                <BlackListReviewsReducer />
              ))}
          </div>
        </BlackListTitleWrapper>
        {Array.isArray(reviews) && active && (
          <ReviewWrapper>
            {reviews.map((review, index) => (
              <ReviewEntry key={index}>
                <TitleColumn>
                  <h4>{review.title}</h4>
                  <ReviewDate>{review.date}</ReviewDate>
                </TitleColumn>
                <ContentColumn>
                  <Content html={review.content} />
                  <FormattedMessage
                    id="blacklist.reviewSolution"
                    tagName="h6"
                  />
                  <Content html={review.solution} />
                </ContentColumn>
              </ReviewEntry>
            ))}
          </ReviewWrapper>
        )}
      </BlackListContainer>
    );
  }
}

export default BlackListEntry;
