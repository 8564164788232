import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  getStars,
  getMetaValue,
  isNumericValue
} from '@/containers/Test/product-review';

const formatValue = (value, type, intl) => {
  if (isNumericValue(value)) {
    const numericValue = parseFloat(value.replace(',', '.'));
    switch (type) {
      case 'stars(1-100)':
        return getStars(numericValue, type);
      case 'num(1)':
        return intl.formatNumber(numericValue, { maximumFractionDigits: 0 });
      case 'num(0.1)':
        return intl.formatNumber(numericValue, { maximumFractionDigits: 1 });
      case 'num(0.01)':
        return intl.formatNumber(numericValue, { maximumFractionDigits: 2 });
      case 'num(0.001)':
        return intl.formatNumber(numericValue, { maximumFractionDigits: 3 });
      default:
        break;
    }
  }

  return value;
};

const MetaValue = (props) => {
  const { column, product, intl } = props;
  let value = getMetaValue(column.name, product);
  value = formatValue(value, column.type, intl);

  return (
    <span>
      {value} {column.suffix}
    </span>
  );
};

MetaValue.propTypes = {
  column: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  intl: PropTypes.any.isRequired
};

export default injectIntl(MetaValue);
