import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ComparisonTable from './Comparison/ComparisonTable';
import { BackButton } from '@/components/Buttons/BackForwardButtons';
import { getSelectedIDs, setSelectedIDs } from './test-utils';

/**
 * Styled component for the comparison container.
 */
const ComparisonContainer = styled.div`
  background-color: #fff;
`;

/**
 * Styled component for the previous comparison section.
 */
const ComparisonPrevious = styled.div`
  position: fixed;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[80]};
  padding: 1em;
  z-index: 11;
`;

/**
 * Functional component for the Comparison page.
 *
 * @param {Object} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
const Comparison = (props) => {
  const { test, previousSection, accessible, history, location } = props;

  const handleRemove = (review) => {
    const { product_reviewId } = review;
    let selected = getSelectedIDs(props);
    selected = selected.filter((id) => id !== product_reviewId);
    setSelectedIDs(selected.length === 0 ? null : selected, props);

    if (selected.length === 0) {
      handleBack(true);
    }
  };

  const handleBack = (clearParams = false) => {
    const path = location.pathname.replace('/compare', '');
    const fullPath = clearParams ? path : path + location.search;
    history.push(fullPath);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0
    });

    // Cleanup the scroll position on unmount
    return () => {
      window.scroll({
        top: 0,
        left: 0
      });
    };
  }, []);

  useEffect(() => {
    if (getSelectedIDs(props).length === 0) {
      handleBack();
    }
  }, [props]);

  const selected = getSelectedIDs(props);
  if (selected.length === 0) {
    return null;
  }

  const selectedReviews = accessible
    ? test.product_reviews.reviews.filter((r) =>
        selected.includes(r.product_reviewId)
      )
    : selected.map((r) =>
        test.product_reviews.reviews.find((ri) => ri.product_reviewId === r)
      );

  return (
    <ComparisonContainer>
      <ComparisonTable
        previousSection={previousSection}
        test={test}
        products={selectedReviews}
        onRemove={handleRemove}
        accessible={accessible}
      />
      <ComparisonPrevious>
        <BackButton
          color="kuluttaja2021.tummaPetroli[40]"
          txtcolor="white"
          onClick={() => handleBack()}
        >
          <FormattedMessage id="search.comparison.return" />
        </BackButton>
      </ComparisonPrevious>
    </ComparisonContainer>
  );
};

Comparison.propTypes = {
  test: PropTypes.object.isRequired,
  previousSection: PropTypes.string,
  accessible: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Comparison);
