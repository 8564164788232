// Define action type as a constant
export const STORE_DATA = 'prisjakt/STORE_DATA';

// Define the initial state for the Prisjakt reducer
const initialState = {
  productData: {}
};

// Define the Prisjakt reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DATA:
      return action.payload;
    default:
      return state;
  }
};

// Export the Prisjakt reducer
export default reducer;

// Define an action creator function to dispatch the STORE_DATA action
export const setData = (data) => ({
  type: STORE_DATA,
  payload: data
});
