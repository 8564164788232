import React from 'react';
import styled from 'styled-components';
import Money from '../../browser/Money';

const Price = styled.span`
  color: ${(props) =>
    props.$color
      ? props.theme.colors[props.$color]
      : props.theme.colors.kuluttaja2021.harmaa.full};
  font-size: 17px;
  font-weight: bold;
`;

const ProductPrice = (props) => {
  const { product, color } = props;

  if (!product) {
    return null;
  }

  const priceUnitMeta = product.meta_data.filter((m) => m.key === 'price_unit');

  const priceUnit = priceUnitMeta.length
    ? ` / ${priceUnitMeta[0].value}`
    : null;

  return (
    <Price $color={color}>
      <Money value={product.price} />
      &nbsp;
      {priceUnit}
    </Price>
  );
};
export default ProductPrice;
