import styled from 'styled-components';

const LiftGrid = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  ${(props) => props.theme.media.tablet`
    grid-template-columns: 50% 50%;
  `};
  ${(props) => props.theme.media.mobileSmall`
    display: block;
  `};
  grid-gap: 22px 2%;
`;

export default LiftGrid;
