import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Money from '@/browser/Money';
import { getMinMaxPrice } from './PrisjaktUtil';
import PrisjaktCoOperation from '@/components/Prisjakt/PrisjaktCoOperation';
import PrisjaktLink from '@/components/Prisjakt/PrisjaktLink';

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.colors.gunmetal};
  padding: 1rem;
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.kuluttaja2021.lila[5]};

  ${(props) =>
    props.$hasPrices &&
    `
    background-color: ${(props) => props.theme.colors.kuluttaja2021.lila[5]};
  `};
`;

/**
 * Styled component for displaying a message.
 */
const Message = styled.div`
  color: ${(props) => props.theme.colors.grey};
  font-size: 11px;
  text-transform: uppercase;
  opacity: 1;
`;

/**
 * PrisjaktPrice component that displays price information.
 * @param {Object} props - The component's props.
 */
const PrisjaktPrice = ({ productId, prisjaktData }) => {
  const priceArray = getMinMaxPrice(productId, prisjaktData);

  if (Array.isArray(priceArray) && priceArray.length) {
    return (
      <Wrapper $hasPrices={true}>
        <PrisjaktCoOperation />
        {priceArray.length > 1 &&
        priceArray[0] !== priceArray[priceArray.length - 1] ? (
          <strong>
            <Money value={priceArray[0]} digits={2} />
            {' - '}
            <Money value={priceArray[priceArray.length - 1]} digits={2} />
          </strong>
        ) : (
          <strong>
            <Money value={priceArray[0]} digits={2} />
          </strong>
        )}
        <PrisjaktLink productId={productId}>
          <FormattedMessage id="productReview.text.comparePrices" />
        </PrisjaktLink>
      </Wrapper>
    );
  } else {
    return (
      <div>
        <Message>
          <FormattedMessage
            id="productReview.text.noUpdatedPrice"
            tagName="strong"
          />
        </Message>
      </div>
    );
  }
};

/**
 * Prop types for the PrisjaktPrice component.
 */
PrisjaktPrice.propTypes = {
  productId: PropTypes.string.isRequired,
  prisjaktData: PropTypes.object
};

/**
 * Connects the PrisjaktPrice component to the Redux store and provides it with Prisjakt data.
 */
export default connect((state) => ({ prisjaktData: state.prisjakt }))(
  PrisjaktPrice
);
