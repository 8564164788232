import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { store } from '../../../store/reducers/auth';
import RenewSession from './RenewSession';
import LoggedIn from './LoggedIn';
import Link from '../../../components/Link/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserMenu = styled.div`
  display: flex;
  > a {
    margin-left: 20px;
    font-size: 11px;
    text-transform: uppercase;
  }
`;

class UserPanel extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };
  render() {
    const { user, token } = this.props.auth;
    if (!token) {
      return (
        <UserMenu>
          <Link to="/käyttäjä/rekisteröidy" color="white">
            Rekisteröidy
          </Link>
          <Link to="/käyttäjä/kirjaudu" color="white">
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              icon={['fal', 'user']}
            />
            &nbsp;Kirjaudu
          </Link>
        </UserMenu>
      );
    } else if (token && !user) {
      return <RenewSession />;
    }
    return <LoggedIn user={user} />;
  }
}

export default connect((state) => ({ auth: state.auth }), { store })(UserPanel);
