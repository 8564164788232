import React, { Component } from 'react';
import styled from 'styled-components';

const StyledFixedHeader = styled.div`
  transition: all 100ms;
  ${(props) => props.theme.media.tablet`
    background-color: #fff;
    box-shadow: 0 0 10px 0px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    z-index: 10;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    background-color: #fff;
    box-shadow: 0 0 10px 0px rgba(0,0,0,0.3);
    position: fixed;
    width: 100%;
    top: ${props.$beforeSticky ? '-180px' : '0'};
    left: 0;
    z-index: 10;
  `}
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktop`
    .submenu-dropdown{
      top: 115px;
      height: calc(100vh - 115px);
    }
  `}
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
    .submenu-dropdown{
      top: 101px;
      height: calc(100vh - 101px);
    }
  `}
`;

class FixedHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforeSticky: false,
      isSticky: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    if (window.scrollY > 181 && window.scrollY < 400) {
      this.setState({
        beforeSticky: true,
        isSticky: true
      });
    } else if (window.scrollY > 400) {
      this.setState({
        beforeSticky: false,
        isSticky: true
      });
    } else {
      this.setState({
        beforeSticky: false,
        isSticky: false
      });
    }
  }
  render() {
    const { beforeSticky, isSticky } = this.state;
    return (
      <React.Fragment>
        {this.props.children({ beforeSticky, isSticky })}
      </React.Fragment>
    );
  }
}

export default FixedHeader;
export { StyledFixedHeader };
