import React from 'react';
import styled from 'styled-components';
import KStar from '../../icons/KStar.svg';

const Paywall = styled.div`
  position: relative;
`;

const Asterisk = styled.div`
  background-image: url(${KStar});
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  height: 46px;
  width: calc(2vw + 10px);
  min-width: 20px;
  max-width: 46px;
  position: absolute;
  top: 8%;
  left: 6%;
`;

const ImagePaywall = (props) => {
  const { children } = props;
  return (
    <Paywall>
      {children}
      <Asterisk />
    </Paywall>
  );
};

export default ImagePaywall;
