/**
 * This is the query to get post "BodyBuilder" ACF blocks.
 */

import gql from 'graphql-tag';

import FRAGMENT_SEO from '@/graphql/wordpress/_fragments/fragmentSeoModel';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';
import FRAGMENT_ARTICLE_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleLift';
import FRAGMENT_ARTICLE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleList';
import FRAGMENT_ARTICLE_HEAD_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleHeadLift';
import FRAGMENT_BLACK_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentBlackList';
import FRAGMENT_CATEGORY_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentCategoryList';
import FRAGMENT_HERO_UNIT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentHeroUnit';
import FRAGMENT_HERO_CONTENT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentHeroContent';
import FRAGMENT_IMAGE_SET from '@/graphql/wordpress/_fragments/bodybuilder/fragmentImageSet';
import FRAGMENT_KULUTTAJA_INFO from '@/graphql/wordpress/_fragments/bodybuilder/fragmentKuluttajaInfo';
import FRAGMENT_MAGAZINE_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMagazineLift';
import FRAGMENT_MAGAZINE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMagazineList';
import FRAGMENT_MEMBER_PROMO from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMemberPromo';
import FRAGMENT_SUBSTORY from '@/graphql/wordpress/_fragments/bodybuilder/fragmentSubstory';
import FRAGMENT_TEST_RELATION from '@/graphql/wordpress/_fragments/bodybuilder/fragmentTestRelation';
import FRAGMENT_WYSIWYG from '@/graphql/wordpress/_fragments/bodybuilder/fragmentWysiwyg';

const QUERY_GET_POST_BODYBUILDER = gql`
  ${FRAGMENT_SEO}
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}
  ${FRAGMENT_ARTICLE_LIFT}
  ${FRAGMENT_ARTICLE_LIST}
  ${FRAGMENT_ARTICLE_HEAD_LIFT}
  ${FRAGMENT_BLACK_LIST}
  ${FRAGMENT_CATEGORY_LIST}
  ${FRAGMENT_HERO_UNIT}
  ${FRAGMENT_HERO_CONTENT}
  ${FRAGMENT_IMAGE_SET}
  ${FRAGMENT_KULUTTAJA_INFO}
  ${FRAGMENT_MAGAZINE_LIFT}
  ${FRAGMENT_MAGAZINE_LIST}
  ${FRAGMENT_MEMBER_PROMO}
  ${FRAGMENT_SUBSTORY}
  ${FRAGMENT_TEST_RELATION}
  ${FRAGMENT_WYSIWYG}

  query getPostBodybuilder($slug: ID!) {
    post(id: $slug, idType: URI) {
      ...SeoModel
      ...ContentNodeBasicModel
      title
      postId
      date
      slug
      excerpt
      content
      commentStatus
      paywall {
        logged_in
        restricted
        accessible
        products {
          title
          description
          price
          price_unit
          marketing_text
          excerpt
          link
          uri
          featuredImage {
            node {
              ...FeaturedImageModel
            }
          }
        }
      }
      author {
        node {
          id
          name
        }
      }
      featuredImage {
        node {
          ...FeaturedImageModel
        }
      }
      extra {
        picture_author
        article_author
        test_author
      }
      comments {
        nodes {
          id
          commentId
          approved
          dateGmt
          parentId
          author {
            node {
              ... on User {
                commentingName
              }
              ... on CommentAuthor {
                commentingName
              }
            }
          }
          content
        }
      }
      tags(first: 50) {
        nodes {
          name
          slug
        }
      }
      categories(where: { childless: true }) {
        nodes {
          categoryId
          slug
        }
      }
      acf {
        __typename
        ...ArticleLift
        ...ArticleList
        ...ArticleHeadLift
        ...BlackList
        ...CategoryList
        ...HeroUnit
        ...HeroContent
        ...ImageSet
        ...KuluttajaInfo
        ...MagazineLift
        ...MagazineList
        ...MemberPromo
        ...Substory
        ...TestRelation
        ...Wysiwyg
      }
    }
  }
`;

export default QUERY_GET_POST_BODYBUILDER;
