import styled from 'styled-components';

const StyledLevel4Nav = styled.ul`
  list-style: none;
  & > li {
    margin-left: 20px;
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`
      margin-left: 0;
      a {
        padding: 0 16px 16px;
      }
    `};
  }
`;

const Level4Nav = ({
  children,
  size,
  useColumns,
  category,
  title,
  tabletHeader,
  headerHeight,
  ...rest
}) => {
  return (
    <StyledLevel4Nav $tabletHeader={tabletHeader} {...rest}>
      {children}
    </StyledLevel4Nav>
  );
};

export default Level4Nav;
