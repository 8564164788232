import React from 'react';
import styled from 'styled-components';
import Container from '@/browser/Container';
import Content from '@/browser/Content';

const Toprow = styled(Container)`
  display: flex;
  ${(props) =>
    props.$fullWidth &&
    `
    min-width: 100%;
    width: 100%;
  `}
`;

const TextCol = styled.div`
  min-width: ${(props) => (props.$overlap ? '50%' : '450px')};
  max-width: ${(props) => (props.$overlap ? '50%' : '450px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[20]};
  padding-right: 30px;
  padding-top: 60px;
  ${(props) =>
    props.$fullWidth &&
    `
      padding-left: 32px;
  `}
  font-size: 20px;
  h1 {
    overflow-wrap: break-word;
    hyphens: auto;
  }
  ${(props) => props.theme.media.mobile`
    min-width: inherit;
    max-width: inherit;
    padding-bottom: 60px;
  `}
`;

const RightCol = styled.div`
  display: flex;
  padding-top: 60px;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli.full};
`;

const Hero = styled.div`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli.full};
  padding-bottom: ${(props) => (props.$overlap ? '160px' : '0')};
  margin-bottom: ${(props) => (props.$overlap ? '-100px' : '0')};
  h1 {
    margin-bottom: 8px;
  }
  ${(props) => props.theme.media.mobile`
    margin-bottom: 0;
    padding-bottom: 0;
    `}
`;

class ComparisonHero extends React.Component {
  render() {
    const { overlap, test, children } = this.props;
    const fullWidth = children !== undefined;
    return (
      <Hero $overlap={overlap}>
        <Toprow $fullWidth={fullWidth}>
          <TextCol $overlap={overlap} $fullWidth={fullWidth}>
            {test.title && (
              <h1 dangerouslySetInnerHTML={{ __html: test.title }} />
            )}
            {test.excerpt && <Content html={test.excerpt} />}
          </TextCol>
          <RightCol>{children}</RightCol>
        </Toprow>
      </Hero>
    );
  }
}

export default ComparisonHero;
