import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Container from '@/browser/Container';
import { Label, Multiselect } from '@/components/Form/FormStyler';
import Link from '@/components/Link/Link';
import WpImage from '@/components/Images/WpImage';

// Styled component for MagazinesContainer
const MagazinesContainer = styled(Container)`
  padding: 64px 0;
  h2 {
    margin-bottom: 22px;
  }
`;

// Styled component for Magazines
const Magazines = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 20.5%);
  grid-gap: 32px 6%;
  ${(props) => props.theme.media.tablet`
    grid-template-columns: repeat(3, 29.33%);
  `};
  ${(props) => props.theme.media.mobile`
    grid-template-columns: repeat(2, 47%);
  `};
`;

// Styled component for Magazine
const Magazine = styled.div`
  text-align: center;
  img {
    margin-bottom: 22px;
  }
`;

// Styled component for Menu
const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  label {
    margin-right: 18px;
    font-weight: bold;
  }
  .react-select-container {
    min-width: 200px;
  }
`;

/**
 * React component for displaying a list of magazines.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.magazine_categories - An array of magazine categories.
 * @returns {JSX.Element} React component.
 */
const MagazineList = ({ magazine_categories }) => {
  const parents = magazine_categories
    .filter((mag) => mag.parent === null)
    .reverse();

  const [selected, setSelected] = useState(parents[0]);

  /**
   * Handles the change of the selected magazine.
   *
   * @param {Object} opt - The selected option.
   */
  const handleChange = (opt) => {
    const magazine = magazine_categories.find((mag) => mag.id === opt.value);
    setSelected(magazine);
  };

  if (!magazine_categories) {
    return null;
  }

  // WordPress doesn't seem to return categories as a hierarchy, so we'll need to do it here
  const options = parents.map((mag) => ({ value: mag.id, label: mag.name }));

  const selection = { value: selected.id, label: selected.name };

  const children = magazine_categories
    .filter(
      (mag) =>
        mag.parent !== null &&
        mag.parent.node.id === selected.id &&
        mag.category_page &&
        mag.category_page.uri &&
        mag.cover_image &&
        mag.cover_image.sourceUrl
    )
    .reverse();

  return (
    <MagazinesContainer>
      <FormattedMessage id="bodybuilder.magazinelist.title" tagName="h2" />
      <Menu>
        <Label>
          <FormattedMessage id="bodybuilder.magazinelist.year" />
        </Label>
        <Multiselect
          value={selection}
          options={options}
          onChange={handleChange}
          multi={false}
        />
      </Menu>

      <Magazines>
        {children &&
          children.map((mag) => (
            <Magazine key={mag.id}>
              <Link to={mag.category_page.uri}>
                <WpImage image={mag.cover_image} />
              </Link>
              <Link to={mag.category_page.uri}>
                <strong>{mag.name}</strong>
              </Link>
            </Magazine>
          ))}
      </Magazines>
    </MagazinesContainer>
  );
};

MagazineList.propTypes = {
  magazine_categories: PropTypes.array.isRequired
};

export default MagazineList;
