import React from 'react';
import transition from 'styled-transition-group';
import MobileUserNav from './UserPanel/MobileUserNav';
import SocialNetworks from '@/components/Social/SocialNetworks';
import InstallWebApp from './InstallWebApp';
import styled from 'styled-components';
import Menu from './Menu';
import Loader from '@/components/Loader/Loader';

const MobileNavigation = transition.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
  position: absolute;
  top: 60px;
  right: 0;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  width: 95vw;
  max-width: 310px;
  height: calc(100vh - 60px);
  box-shadow: -4px 12px 14px 0 rgba(99, 99, 99, 0.5);
  overflow: scroll;
  &:enter {
    transform: translate(310px, 0);
  }
  &:enter-active {
    transform: translate(0, 0);
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:exit {
    transform: translate(0, 0);
  }
  &:exit-active {
    transform: translate(310px, 0);
    transition: all 250ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;
const MenuFooter = styled.div`
  padding: 0 15px;
`;

class MobileMenu extends React.Component {
  componentWillUnmount() {
    document.body.classList.remove('subnav-open');
  }
  render() {
    if (!this.props) {
      return null;
    }
    const { active, menus, loading } = this.props;
    const menuElements = menus.map((menu, index) => (
      <Menu key={index} menu={menu} size={index > 0 && 'small'} />
    ));
    return (
      <MobileNavigation in={active} timeout={500} unmountOnExit>
        <div>
          <MobileUserNav />
          <Loader visible={loading} />
          {menuElements}
          <InstallWebApp />
          <MenuFooter>
            <SocialNetworks />
          </MenuFooter>
          <br />
          <div>&nbsp;</div>
        </div>
      </MobileNavigation>
    );
  }
}

export default MobileMenu;
