import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WpImage from '@/components/Images/WpImage';

const SlideContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0px 7px;
  text-align: center;
  background: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
`;

const SlideContentWrapper = styled.div`
  padding-top: 80px;
  color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[20]};
  font-weight: 700;
`;
const SlideImageWrapper = styled.div`
  height: 165px;
  width: 165px;
  display: flex;
  margin: 0 auto 30px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const SlideSmallText = styled.div`
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 9px;
  color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[20]};
`;
const SlideLargeText = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 22px;
  line-height: 28px;
`;

const CodeScannerTutorialSlide = ({
  image,
  imageUrl,
  smallText,
  largeText,
  pageBackgroundColor
}) => {
  if (!imageUrl && !smallText && !largeText) {
    return null;
  }

  return (
    <SlideContainer>
      <SlideContentWrapper>
        <SlideImageWrapper>
          <WpImage image={image} />
        </SlideImageWrapper>
        <SlideSmallText>{smallText}</SlideSmallText>
        <SlideLargeText>{largeText}</SlideLargeText>
      </SlideContentWrapper>
    </SlideContainer>
  );
};

CodeScannerTutorialSlide.propTypes = {
  image: PropTypes.object,
  smallText: PropTypes.string,
  largeText: PropTypes.string,
  pageBackgroundColor: PropTypes.string
};

export default CodeScannerTutorialSlide;
