import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from '@/containers/Header/logo.svg';
import ImagePaywall from './ImagePaywall';
import { getOptimalImageForWidth } from '@/utils/helpers';

// Styled container for the placeholder image
const PlaceholderContainer = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  background-color: #f5f5f5;
  img {
    opacity: 0.1;
  }
`;

// Image component responsible for rendering the actual image
const Image = ({ image, optimalWidth, ...rest }) => {
  // If no image or image.node, return null
  if (!image && !image.node) {
    return null;
  }

  // Extract the image node
  const imageNode = image.node || image;

  // Initialize responsiveProps object
  const responsiveProps = {};

  // Initialize source with the original sourceUrl
  let source = imageNode.sourceUrl;

  // If mediaDetails exist, handle responsive image properties
  if (imageNode.mediaDetails) {
    const SIZE_NAMES = [
      'thumbnail',
      'medium',
      'medium_large',
      'large',
      '1536x1536'
    ];
    const sizes = imageNode.mediaDetails.sizes.filter((size) =>
      SIZE_NAMES.includes(size.name)
    );

    const imgSrcSet = sizes.map(
      (size) => `${size.sourceUrl}.webp ${size.width}w`
    );
    const imgSizes = sizes.map(
      (size) => `(max-width: ${size.width}px) ${size.width}px`
    );

    // If image width is less than 768, add original image as webp to srcSet and sizes
    if (
      imageNode.mediaDetails.width &&
      parseInt(imageNode.mediaDetails.width, 10) < 768
    ) {
      // add original image to srcSet
      imgSrcSet.push(`${source}.webp ${imageNode.mediaDetails.width}w`);
      // add original image to sizes
      imgSizes.push(
        `(max-width: ${imageNode.mediaDetails.width}px) ${imageNode.mediaDetails.width}px`
      );
    }

    // Construct srcSet and sizes for responsive images
    responsiveProps.srcSet = imgSrcSet.join(', ');
    responsiveProps.sizes = imgSizes.join(', ');

    // If optimalWidth is provided, get the preferred image for that width
    if (optimalWidth) {
      const preferredImage = getOptimalImageForWidth(
        optimalWidth,
        imageNode.mediaDetails.sizes
      );
      if (preferredImage) {
        source = preferredImage.sourceUrl;
      }
    }
  }

  // Render the image with responsive properties
  return (
    <img
      src={`${source}.webp`}
      alt={imageNode.altText || imageNode.title}
      title={imageNode.title}
      {...responsiveProps}
      {...rest}
    />
  );
};

// Prop types for the Image component
Image.propTypes = {
  image: PropTypes.object,
  optimalWidth: PropTypes.number
};

/**
 * Represents an image component with optional paywall and placeholder support.
 * @param {Object} props - Component properties.
 * @param {Object} props.image - The image data.
 * @param {boolean} props.placeholder - Indicates whether to show a placeholder image.
 * @param {number} props.optimalWidth - The optimal width for the image.
 * @returns {JSX.Element|null} - The rendered component.
 */
const WpImage = ({ image, restricted, title, style, placeholder, ...rest }) => {
  // If no image and no placeholder, return null
  if (!image && !placeholder) {
    return null;
  }

  // Handle different cases
  const imageComponent = image ? (
    restricted ? (
      // If image is restricted, wrap it with ImagePaywall
      <ImagePaywall>
        <Image image={image} title={title} {...rest} />
      </ImagePaywall>
    ) : (
      // If image exists, render it
      <Image image={image} title={title} {...rest} />
    )
  ) : (
    // If no image, render a placeholder
    <PlaceholderContainer className="placeholder-img" style={style}>
      <img src={logo} alt="Ei kuvaa" title={title} />
    </PlaceholderContainer>
  );

  return imageComponent;
};

// Prop types for the WpImage component
WpImage.propTypes = {
  image: PropTypes.object,
  placeholder: PropTypes.bool,
  optimalWidth: PropTypes.number
};

// Export the WpImage component
export default WpImage;
