import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ComparisonTable from './Comparison/ComparisonTable';
import NotFound from '../../components/NotFound/NotFound';
import { parse } from 'qs';
import ProductComparisonBar from './Components/ProductComparisonBar';

class SingleProduct extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    test: PropTypes.object.isRequired
  };
  render() {
    const { location, test, history, previousSection, accessible } = this.props;
    if (!test) {
      return null;
    }
    const { id } = parse(location.search.substring(1));
    if (!id) {
      history.push(location.pathname.replace('/product', ''));
      return null;
    }
    const product = test.product_reviews.reviews.filter(
      (review) => review.product_reviewId === parseInt(id, 10)
    );
    if (!product) {
      return <NotFound />;
    }
    return (
      <React.Fragment>
        <ComparisonTable
          previousSection={previousSection}
          test={test}
          products={product}
          singleProductPage
          accessible={accessible}
        />
        <ProductComparisonBar
          reviews={test.product_reviews.reviews}
          accessible={accessible}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SingleProduct);
