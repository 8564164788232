/**
 * This is a fragment of the "BodyBuilder" Magazine Article List block.
 */

import gql from 'graphql-tag';

const FRAGMENT_MAGAZINE_ARTICLE_LIST = gql`
  fragment MagazineArticleList on Bodybuilder_MagazineArticleList {
    title
    magazine_category {
      id
      magazineId
    }
  }
`;

export default FRAGMENT_MAGAZINE_ARTICLE_LIST;
