import React from 'react';
import PropTypes from 'prop-types';
import Bodybuilder from '@/containers/Bodybuilder/Bodybuilder';
import styled from 'styled-components';
import { asyncComponent } from '@jaredpalmer/after';

const PostContainer = styled.div`
  position: relative;

  *:target::before {
    content: '';
    display: block;
    height: 130px; /* fixed header height*/
    margin: -130px 0 0; /* negative fixed header height */
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: -130px;
`;

/**
 * SinglePost component for rendering a single post.
 *
 * @param {Object} props - Component props.
 * @param {object} props.post - The post object to be displayed.
 * @returns {JSX.Element} - Rendered SinglePost component.
 */
const SinglePost = (props) => {
  const { post } = props;
  if (!post) {
    return null;
  }

  const { accessible } = post.paywall;

  // Define async components for code splitting
  const AdvertisingBanners = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "advertisingbanners-chunk" */
        '@/components/AdvertisingBanners/AdvertisingBanners'
      ),
    chunkName: 'advertisingbanners-chunk',
    Placeholder: () => <></>
  });

  const PostContent = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "postcontent-chunk" */
        './PostContent'
      ),
    chunkName: 'PostContent-chunk',
    Placeholder: () => <></>
  });

  const Paywall = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "paywall-chunk" */
        '@/components/Paywall/Paywall'
      ),
    chunkName: 'paywall-chunk',
    Placeholder: () => <></>
  });

  const Tags = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "tags-chunk" */
        '@/components/Tags/Tags'
      ),
    chunkName: 'tags-chunk',
    Placeholder: () => <></>
  });

  const Commenting = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "commenting-chunk" */
        '@/components/Commenting/Commenting'
      ),
    chunkName: 'commenting-chunk',
    Placeholder: () => <></>
  });

  const RelatedPosts = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "relatedposts-chunk" */
        '@/components/Related/Posts'
      ),
    chunkName: 'relatedposts-chunk',
    Placeholder: () => <></>
  });

  return (
    <PostContainer>
      <Anchor id={post.slug} />
      <AdvertisingBanners />
      {!accessible && <PostContent post={post} accessible={accessible} />}
      {/* For users that have access, render full content */}
      {accessible && (
        <div>
          <PostContent post={post} accessible={accessible} />
          <Bodybuilder sections={post.acf} />
        </div>
      )}
      <Paywall {...post.paywall} />
      <Tags tags={post.tags} />
      <Commenting post={post} />
      <RelatedPosts post={post} />
    </PostContainer>
  );
};

SinglePost.propTypes = {
  post: PropTypes.object.isRequired
};

export default SinglePost;
