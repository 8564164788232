import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Slide from './CodeScannerTutorialSlide';
import QueryHandler from '../GraphQL/QueryHandler';
import QUERY_GET_EAN_SCANNER_SETTINGS from '@/graphql/wordpress/allSettings/queryGetEanScannerSettings';

const SliderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  height: 100vh;
  width: 100vw;
  background: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slide {
    width: 100vw;
  }

  .slick-track {
    display: flex;
    transform: translate3d(0, 0, 0);
    position: relative;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;

      img {
        display: block;
      }
      &.slick-loading img {
        display: none;
      }
      &.dragging img {
        pointer-events: none;
      }
    }
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-slide > div > div,
  .slick-slide > div > div:focus,
  .slick-slide > div > div:active {
    outline: none;
  }
  &:hover {
    .SliderPagination {
      opacity: 1;
    }
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    z-index: 15;

    li {
      display: inline-block;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      margin: 0 10px;
      background: white;

      button {
        opacity: 0;
      }

      &.slick-active {
        background: ${(props) => props.theme.colors.kuluttaja2021.army[60]};
      }
    }
  }
`;

/**
 * Functional component representing the CodeScannerTutorial component.
 * This component displays a tutorial using a slider with slides.
 * @param {object} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
const CodeScannerTutorial = (props) => {
  const sliderSettings = {
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    variableWidth: true,
    autoplay: false,
    centerMode: true,
    arrows: false,
    centerPadding: 0,
    useTransform: false,
    responsive: []
  };

  return (
    <QueryHandler query={QUERY_GET_EAN_SCANNER_SETTINGS}>
      {({ allSettings }) => {
        if (
          !allSettings ||
          !allSettings.eanScannerSettings ||
          !allSettings.eanScannerSettings.pages
        ) {
          return null;
        }

        const eanScannerPages = allSettings.eanScannerSettings.pages;

        const slides = eanScannerPages.map((page, index) => {
          if (
            page.smallText !== null &&
            page.largeText !== null &&
            page.image !== null
          ) {
            return (
              <Slide
                key={`tutorialSlide-${index}`}
                image={page.image}
                smallText={page.smallText}
                largeText={page.largeText}
                pageBackgroundColor={page.pageBackgroundColor}
              />
            );
          }
          return null;
        });
        return (
          <SliderContainer>
            <Slider {...sliderSettings}>{slides}</Slider>
          </SliderContainer>
        );
      }}
    </QueryHandler>
  );
};

export default CodeScannerTutorial;
