/**
 * This is a fragment of the Featured Image object model.
 */

import gql from 'graphql-tag';

const FRAGMENT_FEATURED_IMAGE_MODEL = gql`
  fragment FeaturedImageModel on MediaItem {
    id
    sourceUrl
    altText
    title
    link
    caption
    mediaDetails {
      width
      sizes {
        file
        height
        mimeType
        name
        sourceUrl
        width
      }
    }
  }
`;

export default FRAGMENT_FEATURED_IMAGE_MODEL;
