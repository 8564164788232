import styled from 'styled-components';

const StyledLevel5Nav = styled.ul`
  list-style: none;
  & > li {
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`
      margin-left: 0;
      a {
        padding-bottom: 16px;
      }
    `};
  }
`;

const Level5Nav = ({
  children,
  size,
  useColumns,
  category,
  title,
  tabletHeader,
  headerHeight,
  ...rest
}) => {
  return (
    <StyledLevel5Nav $tabletHeader={tabletHeader} {...rest}>
      {children}
    </StyledLevel5Nav>
  );
};

export default Level5Nav;
