import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Level1NavNode from './navnodes/Level1NavNode';
import Level2NavNode from './navnodes/Level2NavNode';
import Level3NavNode from './navnodes/Level3NavNode';
import Level4NavNode from './navnodes/Level4NavNode';
import Level5NavNode from './navnodes/Level5NavNode';

const Nodes = [
  Level1NavNode,
  Level2NavNode,
  Level3NavNode,
  Level4NavNode,
  Level5NavNode
];

class NavigationNodeStyler extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    depth: PropTypes.number.isRequired
  };
  render() {
    const { active, depth, size, hasChildren, tabletHeader } = this.props;
    const Node = Nodes[depth] || Nodes[0];
    return (
      <Node
        tabletHeader={tabletHeader}
        active={active}
        size={size}
        hasChildren={hasChildren}
        className={hasChildren && depth === 3 ? 'has-children' : null}
      >
        {this.props.children}
      </Node>
    );
  }
}
export default NavigationNodeStyler;
