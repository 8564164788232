import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CheckRadio from '@/components/Form/CheckRadio';
import {
  Label,
  CheckRadioWrapper,
  CheckRadioRow,
  CheckRadioCol
} from '@/components/Form/FormStyler';
import facet from './Facet';

class CheckboxFacet extends Component {
  static propTypes = {
    attribute: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    displayLabel: PropTypes.bool,
    initialValue: PropTypes.string,
    column: PropTypes.object,
    values: PropTypes.array.isRequired,
    labels: PropTypes.array
  };
  static defaultProps = {
    displayLabel: true
  };
  constructor(props) {
    super(props);
    this.state = {
      values: this.getInitialValues(props.initialValue)
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      const values = this.getInitialValues(this.props.initialValue);
      this.setState({ values });
    }
  }
  getInitialValues(initialValue) {
    const values = initialValue && initialValue.split(',');
    return values || [];
  }
  setFilter(event) {
    const { checked } = event.target;
    let values = this.getEncodedValues(Array.from(this.state.values));

    if (checked) {
      values.push(event.target.value);
    } else {
      values = values.filter((v) => v !== event.target.value);
    }

    this.setState({ values: this.getEncodedValues(values) });
    this.props.applyFilter(values.join(','), true);
  }
  getEncodedValues(values) {
    return values.map((v) => encodeURIComponent(v));
  }
  render() {
    const { attribute, displayLabel, column, values, labels } = this.props;

    return (
      <CheckRadioWrapper $values={values.length}>
        {displayLabel && <Label>{attribute}: </Label>}
        <CheckRadioRow>
          {this.getEncodedValues(values).map((value, index) => (
            <CheckRadioCol key={index}>
              <CheckRadio
                id={`checkbox-facet-${attribute}-${value}`}
                type="checkbox"
                checked={this.getEncodedValues(this.state.values).includes(
                  value
                )}
                onChange={(e) => this.setFilter(e)}
                value={value}
              />
              <Label htmlFor={`checkbox-facet-${attribute}-${value}`}>
                {labels
                  ? labels[index]
                  : `${decodeURIComponent(value)} ${column && column.suffix}`}
              </Label>
            </CheckRadioCol>
          ))}
        </CheckRadioRow>
      </CheckRadioWrapper>
    );
  }
}

const SEARCH_ROOT = { currentPage: true };
export default facet(null, SEARCH_ROOT)(CheckboxFacet);
