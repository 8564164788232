import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Label } from '@/components/Form/FormStyler';
import SearchBar from '@/containers/Search/SearchBar';
import {
  SearchFacetBox,
  MainFacets
} from '@/containers/SearchFacets/SearchFacetBox';
import DropdownFacet from '@/containers/SearchFacets/DropdownFacet';
import CheckboxFacet from '@/containers/SearchFacets/CheckboxFacet';
import SearchButtons from '@/containers/SearchFacets/SearchButtons';

const BlackListFacebox = styled(SearchFacetBox)`
  ${(props) => props.theme.media.tablet`
    margin-bottom: 30px;
  `};
  input {
    padding-top: 14px;
    padding-bottom: 13px;
  }
  .svg-inline--fa {
    margin: 11px;
  }
`;

const GridColumn = styled.div`
  grid-column: span 1;
`;

class BlackListSearch extends Component {
  static propTypes = {
    entries: PropTypes.array,
    intl: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.entries = props.entries;
    this.locationsList = this.entries
      .filter((entry) => entry.location !== null)
      .map((entry) => entry.location)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter((x, i, a) => a.indexOf(x) === i);
    this.industryList = this.entries
      .filter((entry) => entry.industry !== null)
      .map((entry) => entry.industry)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter((x, i, a) => a.indexOf(x) === i);
  }

  render() {
    const { intl } = this.props;
    const dropdownPlaceholder = intl.formatMessage({ id: 'search.tabs.all' });
    const checkboxLabel = intl.formatMessage({ id: 'blacklist.checkbox' });
    const locationLabel = intl.formatMessage({ id: 'blacklist.location' });
    const industryLabel = intl.formatMessage({ id: 'blacklist.industry' });

    return (
      <BlackListFacebox>
        <FormattedMessage id="blacklist.search" tagName="h2" />
        <MainFacets>
          <GridColumn>
            <Label>
              <FormattedMessage id="blacklist.company" />
            </Label>
            <SearchBar
              param="search"
              placeholder="blacklist.search.placeholder"
            />
          </GridColumn>
          <DropdownFacet
            values={this.locationsList}
            column={{ column: { suffix: '' } }}
            attribute="locationFilter"
            placeholder={dropdownPlaceholder}
            label={locationLabel}
          />
          <DropdownFacet
            values={this.industryList}
            column={{ column: { suffix: '' } }}
            attribute="industryFilter"
            placeholder={dropdownPlaceholder}
            label={industryLabel}
          />
          <CheckboxFacet
            attribute="multipleReviews"
            displayLabel={false}
            values={['yes']}
            labels={[checkboxLabel]}
          />
          <SearchButtons searchText="blacklist.search.facet.apply" />
        </MainFacets>
      </BlackListFacebox>
    );
  }
}

export default injectIntl(BlackListSearch);
