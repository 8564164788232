const appConfig = {
  development: {
    host: 'https://localhost:3000',
    apiHost: 'https://kuluttaja.local',
    apiProxyHost: 'https://localhost:3000/api',
    // stellateHost: 'https://kuluttaja-production.stellate.sh'
    stellateHost: 'https://stellate-staging.kuluttaja.fi'
  },
  staging: {
    host: 'https://frontend-staging.kuluttaja.fi',
    apiHost: 'https://staging.kuluttaja.fi',
    apiProxyHost: 'https://frontend-staging.kuluttaja.fi/api',
    stellateHost: 'https://stellate-staging.kuluttaja.fi'
  },
  production: {
    host: 'https://kuluttaja.fi',
    apiHost: 'https://production.kuluttaja.fi',
    apiProxyHost: 'https://kuluttaja.fi/api',
    stellateHost: 'https://kuluttaja-production.stellate.sh'
  }
}[process.env.RAZZLE_DEPLOY_ENV || 'development'];

export default {
  app: appConfig
};
