import styled from 'styled-components';

const StyledLevel1NavNode = styled.div`
  display: inline-block;
  padding: 0 1.5em 0 0;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: ${(props) => (props.$active ? 'underline' : 'none')};
  font-family: ${(props) => props.theme.fonts.secondary};
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
      display: block;
      border-color: ${(props) => props.theme.colors.white};
      border-style: solid;
      border-width: 0 1px 1px 1px;
      position: relative;
  `};
  ${(props) =>
    !props.$tabletHeader &&
    props.$size === 'small' &&
    props.theme.media.desktopLaptop`
    font-size: 0.8em;
    padding: 0 0 0 calc(1vw + 0.5em);
  `};
  ${(props) => props.theme.media.menuSmallScreen`
    font-size: 0.8em;
  `}
  a {
    color: ${(props) => props.theme.colors.darkGrey};
    display: flex;
    align-items: flex-end;
    min-height: 24px;
    vertical-align: bottom;
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`
        padding: 20px 16px;
    `};
  }
`;

const Level1NavNode = ({
  children,
  active,
  size,
  tabletHeader,
  hasChildren,
  ...rest
}) => {
  return (
    <StyledLevel1NavNode
      $active={active}
      $tabletHeader={tabletHeader}
      $size={size}
      {...rest}
    >
      {children}
    </StyledLevel1NavNode>
  );
};

export default Level1NavNode;
