import config from '../config';

export function isMediaDevicesSupported() {
  const hasMediaDevices =
    typeof navigator !== 'undefined' && 'mediaDevices' in navigator;
  const hasHttps =
    typeof window !== 'undefined' && window.location.protocol === 'https:';
  const isLocalhost =
    typeof window !== 'undefined' && window.location.hostname === 'localhost';

  const hasUserMedia =
    hasMediaDevices &&
    typeof navigator.mediaDevices.getUserMedia === 'function';
  const isHttpsOrLocal = hasHttps || isLocalhost;

  return hasUserMedia && isHttpsOrLocal;
}

export function isCheckout(location) {
  return location.pathname.match(/\/fi\/kauppa\/(?!tuote)(\w+)/) !== null;
}

export function getUrlForNode(node, host) {
  return fixURL(node.url, host);
}

export function getOptimalImageForWidth(optimalWidth, sizes) {
  // Current strategy: Image needs to be larger than given optimalWidth. If none found, largest is returned
  const int = (w) => parseInt(w, 10);

  // First find all larger images
  const largerImages = sizes.filter((size) => {
    return int(size.width) >= optimalWidth;
  });

  // If large enough pictures were found
  if (largerImages.length > 0) {
    // Sort them by the smallest width
    const sorted = largerImages.sort((a, b) => {
      if (int(a.width) < int(b.width)) {
        return -1;
      }
      if (int(a.width) > int(b.width)) {
        return 1;
      }
      return 0;
    });
    // Return the smallest of possible matches
    return sorted[0];
  }

  return null;
}

export function fixURL(url, host = config.app.host) {
  if (!url) {
    return '/';
  }
  if (!url.includes('/')) {
    return '/';
  }
  if (!host) {
    return url;
  }

  // Fix urls if using production DB in local env
  if (
    process.env.NODE_ENV === 'development' &&
    url.includes('https://kuluttaja-210111.appspot.com')
  ) {
    return url.replace('https://kuluttaja-210111.appspot.com', '');
  }
  // Fix staging urls
  if (
    process.env.NODE_ENV === 'development' &&
    url.includes('https://frontend-staging.kuluttaja.fi')
  ) {
    return url.replace('https://frontend-staging.kuluttaja.fi', '');
  }

  return url.replace(host, '');
}

export function getBackgroundForPath(settings, pathname) {
  if (!settings || !settings.customerSettings) {
    return null;
  }
  const { background_images } = settings.customerSettings;
  if (!Array.isArray(background_images) || background_images.length === 0) {
    return null;
  }
  const imageSetting = background_images.find(
    (imageSetting) => imageSetting.pathname === pathname
  );
  return imageSetting ? imageSetting.image : null;
}

/**
 * Remove all characters that are not allowed in WordPress usernames
 *
 * @param {string} str string to clean
 * @returns {string|Boolean} cleaned string
 */
export function cleanStringForWPUserName(str) {
  if (!str) return false;

  // Define the regex pattern to match allowed characters
  const pattern = /[^a-zA-Z0-9\s_@.-]/g;

  // Use the replace method with the pattern to remove unwanted characters
  const cleanedString = str.replace(pattern, '_').toLowerCase();

  return cleanedString;
}

/**
 *  Returns a number with given decimals
 *
 * @param {int|float|string} number
 * @param {int} decimals
 * @returns {float}
 */
export function toFixedNumber(number, decimals = 2) {
  return parseFloat(Number(number).toFixed(decimals));
}

/**
 * Returns a unique ID string
 *
 * @returns {string}
 */
export function getUniqueID() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}
