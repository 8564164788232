import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ArticleLift from '@/containers/Bodybuilder/components/ArticleLift';
import { StyledButton } from '@/components/Buttons/Button';
import CategoryIcon from '@/components/Images/CategoryIcon';
import NavigationNodeStyler from './NavigationNodeStyler';
import Link from '@/components/Link/Link';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import _get from 'lodash.get';
import QUERY_GET_POSTS_FOR_NAVIGATION from '@/graphql/wordpress/posts/queryGetPostsForNavigation';

const Header = styled.div`
  padding: 16px 0;
  margin-bottom: -32px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  margin: 0 auto;
  display: flex;
  h3 {
    flex: auto;
  }
  a {
    display: inline-block;
  }
`;

const DesktopContainer = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: block;
  `};
`;

const MobileContainer = styled.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
`;

/**
 * NavigationArticles component that displays articles related to a category.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title of the category.
 * @param {Object} props.category - The category information.
 * @param {boolean} props.tabletHeader - Indicates if the navigation is used in a tablet header.
 * @returns {JSX.Element} - Rendered component.
 */
const NavigationArticles = (props) => {
  const { title, category, tabletHeader } = props;
  const categoryPageLink = _get(category, 'category_page.uri');
  if (!category) {
    return null;
  }
  const isTestCat = category.slug.includes('testit');

  return (
    <>
      <DesktopContainer>
        <Header>
          <h3>
            {category?.icon && <CategoryIcon icon={category.icon} />} {title}
          </h3>
          {categoryPageLink && (
            <StyledButton
              to={categoryPageLink}
              $buttonstyle="bordered"
              $color="darkGrey"
            >
              Katso Kaikki
            </StyledButton>
          )}
        </Header>
        {!isTestCat && (
          <QueryHandler
            query={QUERY_GET_POSTS_FOR_NAVIGATION}
            variables={{ termId: category.categoryId }}
            hide_loader
          >
            {({ posts }) =>
              posts.nodes.length > 0 && (
                <ArticleLift articles={posts.nodes} unboxed />
              )
            }
          </QueryHandler>
        )}
      </DesktopContainer>
      <MobileContainer>
        {!isTestCat && (
          <QueryHandler
            query={QUERY_GET_POSTS_FOR_NAVIGATION}
            variables={{ termId: category.categoryId }}
            hide_loader
          >
            {({ posts }) =>
              posts.nodes.length > 0 &&
              posts.nodes.map((node) => {
                const label = node.title;
                return (
                  <NavigationNodeStyler
                    depth={2}
                    key={node.id}
                    tabletHeader={tabletHeader}
                  >
                    <Link to={node.uri}>{label}</Link>
                  </NavigationNodeStyler>
                );
              })
            }
          </QueryHandler>
        )}
        {categoryPageLink && (
          <NavigationNodeStyler depth={2} tabletHeader={tabletHeader}>
            <Link to={categoryPageLink}>Katso kaikki</Link>
          </NavigationNodeStyler>
        )}
      </MobileContainer>
    </>
  );
};

NavigationArticles.propTypes = {
  title: PropTypes.string,
  category: PropTypes.object,
  tabletHeader: PropTypes.bool
};

export default NavigationArticles;
