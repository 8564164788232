import { parse, stringify } from 'qs';

/**
 * Get selected IDs from the query parameters in the URL.
 *
 * @param {Object} props - The component's props containing the location.
 * @returns {number[]} An array of selected IDs.
 */
export function getSelectedIDs(props) {
  const { location } = props;
  const { selected = false } = parse(location.search.substring(1));
  return !selected
    ? []
    : selected.split(',').map((stringId) => parseInt(stringId, 10));
}

/**
 * Convert an array of IDs to a string for query parameters.
 *
 * @param {number[]} ids - An array of selected IDs.
 * @returns {string} A string representing the selected IDs.
 */
export function stringifyIds(ids) {
  return stringify({ selected: ids }, { encode: false, arrayFormat: 'comma' });
}

/**
 * Set selected IDs in the query parameters and update the URL.
 *
 * @param {number[]} ids - An array of selected IDs.
 * @param {Object} props - The component's props containing history and location.
 */
export function setSelectedIDs(ids, props) {
  const { history, location } = props;
  const urlParams = new URLSearchParams(location.search);
  urlParams.delete('selected');
  const filters = urlParams.toString() ? urlParams.toString() : '';
  if (!ids) {
    history.replace(location.pathname + '?' + filters);
  } else {
    const search = stringifyIds(ids);
    let historyString = location.pathname + '?' + search;
    if (filters) {
      historyString += '&' + filters;
    }
    history.replace(historyString);
  }
}

/**
 * Generate facets from column definitions and product reviews.
 *
 * @param {Object[]} definitions - An array of column definitions.
 * @param {Object[]} productReviews - An array of product reviews.
 * @returns {Object} Facets object with columns and their values.
 */
export function generateFacets(definitions, productReviews) {
  const facets = {};

  // Gather all filterable columns and values for those columns
  definitions.forEach((col) => {
    productReviews.forEach((pr) => {
      pr.metadata.forEach((meta) => {
        if (meta.key === col.name) {
          // Add value from product review to the value array, if similar value is not already there
          const values = facets[col.name] ? facets[col.name].values : [];
          if (!values.includes(meta.value)) {
            values.push(meta.value);
          }
          facets[col.name] = {
            column: col,
            values
          };
        }
      });
    });
  });

  return facets;
}

/**
 * Filter reviews based on specified filters.
 *
 * @param {Object[]} reviews - An array of product reviews.
 * @param {Object} filters - An object containing filter criteria.
 * @returns {Object[]} Filtered reviews based on the filters.
 */
export function filterReviews(reviews, filters) {
  return reviews.filter((review) => {
    if (Object.keys(filters).length === 0) {
      return true;
    }
    return Object.entries(filters).every(([key, value]) => {
      const reviewValue = review[key];
      const meta = review.metadata.find((m) => m.key === key);
      const reviewMetaValue = meta ? meta.value : null;

      if (value.includes(',')) {
        const values = value.split(',');
        return values.includes(reviewValue) || values.includes(reviewMetaValue);
      }

      if (value.includes('range')) {
        const values = value.slice(5, 30).split('-');
        if (values.length === 2) {
          const min = Number(values[0]);
          const max = Number(values[1]);
          const DecimalCounter = getDecimalCounter(
            min.length >= max.length ? min : max
          );
          const reviewValueNumber =
            Number.isInteger(min) && Number.isInteger(max)
              ? Math.round(Number(reviewMetaValue.replace(/,/g, '.')))
              : Math.round(
                  Number(reviewMetaValue.replace(/,/g, '.')) * DecimalCounter
                ) / DecimalCounter;

          return reviewValueNumber >= min && reviewValueNumber <= max;
        }
      }

      return reviewValue === value || reviewMetaValue === value;
    });
  });
}

/**
 * Recursively retrieve children nodes for a given node.
 *
 * @param {number} currentIndex - The current index in the data array.
 * @param {number} currentLevel - The current hierarchy level.
 * @param {Object[]} data - The data array containing nodes.
 * @returns {Object[]} An array of child nodes for the given node.
 */
function getChildrenForNode(currentIndex, currentLevel, data) {
  // First collect the remaining unprocessed nodes
  const remainingNodes = data.filter((node, index) => index > currentIndex);

  // Find the nodes that should be on this level (same level, until next lower level)
  let children = [];
  for (let idx in remainingNodes) {
    const index = parseInt(idx, 10);
    const node = remainingNodes[index];
    const level = parseInt(node.level, 10);

    if (level === currentLevel) {
      // Recursively get children for this child node
      node.children = getChildrenForNode(
        currentIndex + index + 1,
        level + 1,
        data
      );
      children.push(node);
    }

    if (isNaN(level) || level < currentLevel) {
      break;
    }
  }
  return children;
}

/**
 * Get a comparison tree structure from the provided data.
 *
 * @param {Object[]} data - An array of objects with 'level' property indicating the hierarchy level.
 * @returns {Object[]} A structured array representing the comparison tree.
 */
export function getComparisonTree(data) {
  const deepCopyObj = JSON.parse(JSON.stringify(data));
  const dataOrdered = deepCopyObj.map((node, index) => {
    const item = JSON.parse(JSON.stringify(node));
    item.orderNum = index;
    return item;
  });

  return dataOrdered.reduce((results, node, index) => {
    const level = parseInt(node.level, 10);
    // If node is on the first level in the structure, we collect them and assign them to the previous node
    if (level === 1) {
      const children = getChildrenForNode(index, level + 1, dataOrdered);
      node.children = children;
      results.push(node);
    } else if (level > 1) {
      // Do nothing
    } else {
      results.push(node);
    }

    return results;
  }, []);
}

/**
 * Get the decimal counter based on the type of decimal value.
 *
 * @param {string|number} decimalType - The decimal value.
 * @returns {number} The decimal counter for rounding.
 */
export function getDecimalCounter(decimalType) {
  const decimalCounter = decimalType.toString().split('.');

  if (decimalCounter.length >= 2) {
    return Math.pow(10, decimalCounter[1].length);
  }
  return 100;
}
