import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import NavigationStyler from './NavigationStyler';
import NavigationNode from './NavigationNode';

/**
 * Navigation component that displays a navigation menu.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.nodes - An array of navigation nodes to display.
 * @param {number} [props.depth] - The depth of the navigation menu.
 * @param {string} [props.size] - The size of the navigation menu.
 * @param {boolean} [props.tabletHeader] - Indicates if the navigation is used in a tablet header.
 * @param {object} [props.category] - The category of the navigation.
 * @param {string} [props.title] - The title of the navigation.
 * @param {boolean} [props.resetActiveNode] - Indicates if active node should be reset.
 * @returns {JSX.Element} - Rendered component.
 */
const Navigation = (props) => {
  const {
    nodes,
    depth = 0,
    size,
    tabletHeader,
    category,
    title,
    resetActiveNode
  } = props;
  const [activeNode, setActiveNode] = useState(null);

  if ((!Array.isArray(nodes) || nodes.length === 0) && !category) {
    return null;
  }

  // dropdown opened - reset selected node
  useEffect(() => {
    if (resetActiveNode) {
      setActiveNode(null);
    }
  }, [resetActiveNode]);

  const onNavigationNodeClick = (event, nodeId) => {
    event.preventDefault();
    setActiveNode(nodeId);
  };

  return (
    <NavigationStyler
      className={depth === 1 ? 'submenu-dropdown' : ''}
      depth={depth}
      size={size}
      useColumns={depth === 2}
      tabletHeader={tabletHeader}
      category={depth === 2 && category}
      title={depth === 2 && title ? title : undefined}
    >
      {props.children}
      {nodes.map((node, index) => (
        <NavigationNode
          key={node.id}
          onClick={(e) => onNavigationNodeClick(e, node.id)}
          nodeId={node.id}
          activeNodeId={depth === 1 && !activeNode ? nodes[0].id : activeNode}
          node={node}
          index={index}
          depth={depth}
          size={size}
          tabletHeader={tabletHeader}
        />
      ))}
    </NavigationStyler>
  );
};

Navigation.propTypes = {
  nodes: PropTypes.array.isRequired,
  depth: PropTypes.number,
  size: PropTypes.string,
  tabletHeader: PropTypes.bool,
  category: PropTypes.object,
  title: PropTypes.string,
  resetActiveNode: PropTypes.bool
};

export default Navigation;
