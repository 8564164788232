import styled from 'styled-components';

const StyledLevel3NavNode = styled.div`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 600;
  break-inside: avoid;
  display: inline-block;
  padding: 10px 0;
  text-transform: none;
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
      padding: 0;
  `};
  & > a {
    margin-bottom: 15px;
    display: block;
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`
        margin-bottom: 0;
    `};
  }
`;

const Level3NavNode = ({
  children,
  active,
  size,
  tabletHeader,
  hasChildren,
  ...rest
}) => {
  return (
    <StyledLevel3NavNode $tabletHeader={tabletHeader} {...rest}>
      {children}
    </StyledLevel3NavNode>
  );
};

export default Level3NavNode;
