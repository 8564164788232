import React, { Component } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import Link from '../Link/Link';

const DropdownContainer = styled.div`
  height: 52px;
  z-index: 999;
  border-top: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
`;

const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  overflow: hidden;
  transition: all 250ms;
  max-height: ${(props) => (!props.$active ? '48px' : '600px')};
  border: 1px solid
    ${(props) =>
      props.$active
        ? props.theme.colors.kuluttaja2021.tummaPetroli.full
        : '#fff'};
  box-shadow: 0 -7px 12px 0 ${(props) => (props.$active ? 'rgba(0, 0, 0, 0.16)' : 'rgba(0, 0, 0, 0)')};
  z-index: 1;
  &:after,
  &:before {
    content: ' ';
    display: block;
    height: 2px;
    width: 10px;
    position: absolute;
    top: 24px;
    background-color: ${(props) => props.theme.colors.grey};
    transition: all 200ms;
  }
  &:after {
    right: 16px;
    transform: rotate(${(props) => (!props.$active ? '-45deg' : '45deg')});
  }
  &:before {
    right: 22px;
    transform: rotate(${(props) => (!props.$active ? '45deg' : '-45deg')});
  }
`;

const StyledDropdownItem = styled.li`
  display: block;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.secondary};
  cursor: pointer;
  background-color: #fff;
  transition: all 150ms;
  order: ${(props) => (props.$active ? '1' : props.$order)};
  transition: all 200ms;
  border-bottom: ${(props) =>
    props.$active
      ? '1px solid ' + props.theme.colors.kuluttaja2021.tummaPetroli.full
      : 'none'};
  a,
  > span {
    font-weight: ${(props) => (props.$active ? 'bold' : 'normal')};
    padding: 1.2em 2em;
    color: ${(props) =>
      props.$active ? props.theme.colors.grey : props.theme.colors.primary};
    display: block;
  }
`;

const DropdownItem = ({
  label,
  to,
  exact,
  children,
  order,
  active,
  onClick
}) => (
  <StyledDropdownItem
    $active={active}
    $order={order ? order.toString() : 2}
    onClick={onClick}
  >
    {active ? <span>{children}</span> : <Link to={to}>{children}</Link>}
  </StyledDropdownItem>
);

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }
  toggleActive() {
    this.setState({ active: !this.state.active });
  }
  render() {
    const { pages, parentTo, parentTitle, ...rest } = this.props;
    return (
      <DropdownContainer {...rest}>
        <DropdownList $active={this.state.active}>
          <Route
            path={parentTo}
            exact={true}
            children={({ match }) => (
              <DropdownItem
                exact={true}
                to={parentTo}
                active={match}
                onClick={(e) => this.toggleActive(e)}
              >
                {parentTitle}
              </DropdownItem>
            )}
          />
          {Array.isArray(pages) &&
            pages.map((node, index) => (
              <Route
                key={node.id}
                path={`${parentTo}/${node.slug}`}
                exact={false}
                children={({ match }) => (
                  <DropdownItem
                    order={2 + index}
                    to={`${parentTo}/${node.slug}`}
                    active={match}
                    onClick={() => this.toggleActive()}
                  >
                    {node.title}
                  </DropdownItem>
                )}
              />
            ))}
        </DropdownList>
      </DropdownContainer>
    );
  }
}

export {
  Dropdown,
  DropdownItem,
  DropdownList,
  StyledDropdownItem,
  DropdownContainer
};
