import fetch from 'node-fetch';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import config from '@/config';
import authMiddleware from './authMiddleware';
import loggingMiddleware from './loggingMiddleware';
import possibleTypes from './possibleTypes.json';

export default function createApolloClient(initialToken) {
  const memoryCache = new InMemoryCache({
    typePolicies: {
      PaywallProperties: {
        merge: true
      },
      Settings: {
        merge: true
      }
    },
    possibleTypes
  });

  const ENABLE_STELLATE =
    /^true$/i.test(process.env.RAZZLE_ENABLE_STELLATE) || false;

  // use stellate.sh for graphql caching or local wp/graphql endpoint
  const httpLinkUri = ENABLE_STELLATE
    ? `${config.app.apiProxyHost}/graphql`
    : `${config.app.apiHost}/wp/graphql`;

  const link = ApolloLink.from([
    authMiddleware(initialToken),
    loggingMiddleware,
    new HttpLink({
      uri: httpLinkUri,
      credentials: 'same-origin',
      fetch
    })
  ]);

  return new ApolloClient({
    ssrMode: true,
    link,
    cache:
      typeof window === 'undefined'
        ? memoryCache
        : memoryCache.restore(window.__APOLLO_STATE__),
    ssrForceFetchDelay: 100
  });
}
