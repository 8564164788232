import React from 'react';
import PropTypes from 'prop-types';
import ArticleLift from './components/ArticleLift';
import ArticleList from './components/ArticleList';
import ArticleHeadLift from './components/ArticleHeadLift';
import BlackList from './components/BlackList';
import CategoryList from './components/CategoryList';
import HeroUnit from './components/HeroUnit';
import HeroContent from './components/HeroContent';
import ImageSet from './components/ImageSet';
import KuluttajaInfo from './components/KuluttajaInfo';
import MagazineLift from './components/MagazineLift';
import MagazineList from './components/MagazineList';
import MagazineArticleList from './components/MagazineArticleList';
import MemberPromo from './components/MemberPromo';
import PageList from './components/PageList';
import Substory from './components/Substory';
import TestArticle from './components/TestArticle';
import TestRelation from './components/TestRelation';
import Wysiwyg from './components/Wysiwyg';

/**
 * Mapping of Bodybuilder module data to components.
 *
 * @type {Object.<string, React.ComponentType>}
 */
const SECTIONS = {
  Bodybuilder_ArticleLift: ArticleLift,
  Bodybuilder_ArticleList: ArticleList,
  Bodybuilder_ArticleHeadLift: ArticleHeadLift,
  Bodybuilder_BlackList: BlackList,
  Bodybuilder_CategoryList: CategoryList,
  Bodybuilder_HeroUnit: HeroUnit,
  Bodybuilder_HeroContent: HeroContent,
  Bodybuilder_ImageSet: ImageSet,
  Bodybuilder_KuluttajaInfo: KuluttajaInfo,
  Bodybuilder_MagazineLift: MagazineLift,
  Bodybuilder_MagazineList: MagazineList,
  Bodybuilder_MagazineArticleList: MagazineArticleList,
  Bodybuilder_MemberPromo: MemberPromo,
  Bodybuilder_PageList: PageList,
  Bodybuilder_Substory: Substory,
  Bodybuilder_TestArticle: TestArticle,
  Bodybuilder_TestRelation: TestRelation,
  Bodybuilder_Wysiwyg: Wysiwyg
};

/**
 * Bodybuilder component for rendering sections based on module data.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.sections - Array of Bodybuilder module data.
 * @param {Object} [props.paywall] - Paywall data.
 * @param {Object} [props.match] - React Router match object.
 * @param {Object} [props.location] - React Router location object.
 * @returns {JSX.Element} React component.
 */
const Bodybuilder = ({ sections, paywall, match, location }) => {
  // If sections array is empty or not an array, return null.
  if (!Array.isArray(sections) || sections.length === 0) {
    return null;
  }

  // Initialize previousSection variable.
  let previousSection = null;

  // Map through sections array and render corresponding components.
  return sections.map((section, index) => {
    // If section is null, skip rendering.
    if (!section) {
      return null;
    }

    // Destructure section to get Bodybuilder type (__typename) and rest of the properties.
    const { __typename, ...rest } = section;

    // Get the corresponding Bodybuilder component based on __typename.
    const SectionComponent = SECTIONS[__typename];

    // If SectionComponent is defined, render the component with props.
    if (typeof SectionComponent !== 'undefined' && SectionComponent !== null) {
      // Create a JSX element for the current section.
      const currentSection = (
        <section key={index}>
          <SectionComponent
            {...rest}
            previousSection={previousSection}
            paywall={paywall}
            match={match}
            location={location}
          />
        </section>
      );

      // Update previousSection for the next iteration.
      previousSection = __typename;

      // Return the JSX element for the current section.
      return currentSection;
    }

    // If SectionComponent is undefined or null, skip rendering.
    return null;
  });
};

// Prop type validation for Bodybuilder component.
Bodybuilder.propTypes = {
  sections: PropTypes.array.isRequired,
  paywall: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};

export default Bodybuilder;
