import styled from 'styled-components';

const StyledLevel4NavNode = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  text-decoration: none;
  text-transform: none;
  &.has-children {
    > a {
      font-weight: 600;
    }
    margin-bottom: 22px;
  }
`;

const Level4NavNode = ({
  children,
  active,
  size,
  tabletHeader,
  hasChildren,
  ...rest
}) => {
  return <StyledLevel4NavNode {...rest}>{children}</StyledLevel4NavNode>;
};

export default Level4NavNode;
