import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import BlockLoader from '@/components/Loader/BlockLoader';
import CartItem from './CartItem';
import CartAPI from '@/utils/cartApi';
import {
  gaCreateEvent,
  gaGetECommerce,
  gaGetTotalValue
} from '@/utils/gaUtils';

const Row = styled.div`
  margin-top: 29px;
  margin-bottom: 10px;
  padding-left: 16px;

  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: ${(props) => props.theme.colors.darkGrey};

  display: grid;
  grid-template-columns: auto 20% 20% 5%;
  line-height: 1.5em;
  ${(props) => props.theme.media.tablet`
    display: none;
  `};
`;

export default class CartItems extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    updateEcommerce: PropTypes.func
  };

  static defaultProps = {
    items: []
  };

  state = {
    loadingProduct: false,
    error: null
  };

  async componentDidMount() {
    const { items, updateEcommerce } = this.props;

    // WooCommerce is funny part 2
    if (items.length > 0) {
      const promises = [];
      const productQuantities = [];
      Object.keys(items).forEach((key) => {
        const product = CartAPI.getProductById(items[key].product_id);
        promises.push(Promise.resolve(product));
        productQuantities.push(items[key].quantity || 1);
      });
      const errorMsg = 'Cart is not able to fetch products';
      const handleError = () => {
        console.error(errorMsg);
        this.setState({ productFetchErrors: true });
        this.setState({ loadingProduct: false });
      };

      await Promise.all(promises).then((promisedProducts) => {
        const products = promisedProducts.map((o, index) => {
          return o.product
            ? { ...o.product, quantity: productQuantities[index] }
            : handleError();
        });

        const ecommerce = gaGetECommerce({
          currency: 'EUR',
          value: gaGetTotalValue(products),
          items: products
        });

        // Add analytics event
        gaCreateEvent(
          {
            event: 'view_cart',
            ecommerce: ecommerce
          },
          true
        );

        // send to parent
        if (updateEcommerce) {
          updateEcommerce(ecommerce);
        }
      });
    }
  }

  async apiCall(method) {
    this.setState({ loadingProduct: true });

    try {
      await method();
      this.setState({ loadingProduct: false });
    } catch (err) {
      this.setState({ loadingProduct: false, error: err });
    }

    this.props.onUpdate();
  }

  async updateQuantity(item, quantity) {
    return await this.apiCall(() => CartAPI.setQuantity(item, quantity));
  }

  async removeItem(item) {
    return await this.apiCall(() => CartAPI.removeItem(item)).then(() => {
      const quantity = item?.quantity || 1;
      const product = { ...item?.product?.product, quantity: item?.quantity };
      const gaOptions = {
        event: 'remove_from_cart',
        ecommerce: gaGetECommerce({
          currency: 'EUR',
          value: gaGetTotalValue([product]),
          items: [product]
        })
      };
      gaCreateEvent(gaOptions);
    });
  }

  render() {
    const { items, plain, loading } = this.props;
    const isLoading = this.state.loadingProduct || loading;
    if (items.length === 0 && !isLoading) {
      return <FormattedMessage id="order.cart.empty" />;
    }
    return (
      <BlockLoader visible={isLoading}>
        {!plain && (
          <Row>
            <FormattedMessage id="order.label.items" />
            <FormattedMessage id="order.label.quantity" />
            <FormattedMessage id="order.label.price" />
          </Row>
        )}
        {this.state.error && (
          <pre>{JSON.stringify(this.state.error, null, 2)}</pre>
        )}
        {items.map((item) => (
          <CartItem
            plain={plain}
            key={item.key}
            item={item}
            onUpdate={(item, q) => this.updateQuantity(item, q)}
            onRemove={(item) => this.removeItem(item)}
          />
        ))}
      </BlockLoader>
    );
  }
}
