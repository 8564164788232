import React from 'react';

const FormattedDate = (props) => {
  if (!props) {
    return null;
  }
  const arr = props.date.split(/[- T:]/);
  const fixedDate = new Date(
    arr[0],
    arr[1] - 1,
    arr[2],
    arr[3],
    arr[4],
    arr[5]
  );
  const date = fixedDate.toLocaleDateString('fi-FI');
  return <span {...props}>{date}</span>;
};
export default FormattedDate;
