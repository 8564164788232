import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import Link from '../Link/Link';

const Tabs = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const StyledTab = styled.li`
  display: inline-block;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) =>
    props.$active
      ? props.$bgcolor || props.theme.colors.kuluttaja2021.tummaPetroli.full
      : '#FFF'};
  transition: all 150ms;
  text-align: center;
  color: ${(props) => (props.$active ? '#FFF' : props.theme.colors.primary)};
  > * {
    display: inline-block;
    padding: 1.2em 2em;
    color: ${(props) => (props.$active ? '#FFF' : props.theme.colors.primary)};
  }
  &:hover {
    background-color: ${(props) =>
      props.$bgcolor || props.theme.colors.kuluttaja2021.tummaPetroli.full};
    * {
      color: #fff;
    }
  }
  ${(props) =>
    props.$active
      ? `border-bottom: solid 5px ${props.theme.mediumBlue}`
      : 'border-bottom: none;'};
`;

const Tab = ({ label, to, exact, children, bgcolor }) => (
  <Route
    path={`${to}`}
    exact={exact}
    children={({ match }) => (
      <StyledTab $active={match} $bgcolor={bgcolor}>
        <Link to={to}>{children}</Link>
      </StyledTab>
    )}
  />
);
export { Tabs, Tab, StyledTab };
