import fetch from 'node-fetch';
import config from '../config';

const BASE_URL = `${config.app.apiProxyHost}/woocommerce/wc/v3/memberships/members`;

export async function getUserMembershipPlans(userId) {
  const response = await fetch(`${BASE_URL}?customer=${userId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then((res) => {
    return res.json();
  });
  return await response;
}

export default {
  getUserMembershipPlans
};
