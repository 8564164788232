/**
 * This is the query to get the background images from the WordPress ACF Options page.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const QUERY_GET_BACKGROUND_IMAGES = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  query getBackgroundImages {
    allSettings {
      customerSettings {
        background_images {
          pathname
          image {
            ...FeaturedImageModel
          }
        }
      }
    }
  }
`;

export default QUERY_GET_BACKGROUND_IMAGES;
