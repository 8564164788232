import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setData } from '@/store/reducers/prisjakt';
import SingleTest from '@/containers/Test/SingleTest';
import Comparison from '@/containers/Test/Comparison';
import SingleProduct from '@/containers/Test/SingleProduct';
import { getOffersForIDs } from '@/components/Prisjakt/PrisjaktUtil';
import PaywallSmall from '@/components/Paywall/PaywallSmall';

/**
 * React component representing the test relation page.
 *
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} React component.
 */
const TestRelation = (props) => {
  const { match, test, location, setData, previousSection, paywall } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (test) {
        const prisjaktIds = test.product_reviews.reviews.map(
          (review) => review.hintaopas_id
        );

        const data = await getOffersForIDs(prisjaktIds);
        setData(data);
      }
    };

    fetchData();
  }, [test, setData]);

  useEffect(() => {
    const { pathname, search } = location;
    // Save page to localstorage for login redirect
    localStorage.setItem('lastPageBeforeLogin', pathname + search);
  }, [location]);

  const { accessible } = test.paywall;
  const usersPlanOnly =
    test.column_definitions.some((c) => c.protected === 'public') &&
    !test.column_definitions.some((c) => c.protected === 'maksava');

  return (
    <React.Fragment>
      <PaywallSmall
        accessible={accessible}
        products={test.paywall.products}
        usersPlanOnly={usersPlanOnly}
      />
      <Switch>
        <Route
          path={`${match.url}/compare`}
          render={(props) => (
            <Comparison
              test={test}
              previousSection={previousSection}
              accessible={accessible}
            />
          )}
        />
        <Route
          path={`${match.url}/product`}
          render={(props) => (
            <SingleProduct
              test={test}
              previousSection={previousSection}
              accessible={accessible}
            />
          )}
        />
        <Route
          render={() => <SingleTest test={test} accessible={accessible} />}
        />
      </Switch>
    </React.Fragment>
  );
};

TestRelation.propTypes = {
  match: PropTypes.object.isRequired,
  test: PropTypes.object,
  location: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  previousSection: PropTypes.any, // You might want to replace 'any' with the appropriate type
  paywall: PropTypes.any // You might want to replace 'any' with the appropriate type
};

export default withRouter(connect(null, { setData })(TestRelation));
