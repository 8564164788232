import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import _get from 'lodash.get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextFacet from '@/containers/SearchFacets/TextFacet';
import CodeScanner from '@/components/CodeScanner/CodeScanner';
import SearchButton from '@/containers/SearchFacets/SearchButton';
import { fixURL } from '@/utils/helpers';
import MiniCart from '@/containers/Cart/MiniCart';

const SearchFormWrapper = styled.div`
  position: relative;
  width: 100%;

  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    width: 60px;
    min-height: 58px;
  `};
`;

const SearchForm = styled.div`
  width: 100%;
  border: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[40]};
  ${(props) => props.theme.media.tablet`
    border: none;
    justify-content: right;
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    background-color: #fff;
  `};
  ${(props) =>
    props.$beforeSticky &&
    props.theme.media.desktopLaptop`
    width: 58px;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    justify-content: right;
    position: absolute;
    top: 0;
    right: 0;
    width: ${(props) => (props.$searchOpen ? '250px' : '58px')};
    background-color: #fff;
    overflow: hidden;
    flex-direction: row;
    transition: width 100ms;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
    top: 8px;
    right: 0;
  `};

  input {
    border: none;
    padding: 15px 10px;
    font-size: 18px;
    width: 100%;
    &:focus {
      border: 0;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[40]};
    }
  }
`;

const SearchFormInner = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  width: 100%;
  padding: 3px;
  input {
    height: 100% !important;
    font-family: ${(props) => props.theme.fonts.secondary};
  }
  ${(props) => props.theme.media.tablet`
    transition: all 150ms;
    over-flow: hidden;
    width: ${(props) => (props.$searchOpen ? '150px' : '0')};
  `};
`;

const SearchSubmit = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
`;

const SearchOpen = styled(SearchSubmit)`
  min-height: 60px;
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
    `};
`;

const SearchIcon = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: inline-block;
    `};
`;

const CodeScannerContainer = styled.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
    `};
`;

const DesktopSearchButton = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: block;
    `};
`;

const MobileMiniCartWrapper = styled.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
`;

const faIconStyle = {
  color: '#1c1c1c',
  fontSize: '24px',
  margin: '16px'
};

class SearchBar extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  state = {
    searchOpen: false
  };

  toggleState() {
    const { searchOpen } = this.state;
    this.setState({
      searchOpen: !searchOpen
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        searchOpen: false
      });
    }
  }

  handleCodeScanned = (code, searchResults) => {
    const { pages, product_reviews } = searchResults;

    try {
      if (!Array.isArray(pages) || pages.length === 0) {
        // Redirect to search if no matches were found
        const url = `/fi/search?text=${code}&search_type=ean`;
        this.props.history.push(url);
      } else {
        // Redirect to the first product review page when there's results
        let url = fixURL(pages[0].uri);
        const reviewId = _get(product_reviews, '[0].id', null);
        if (reviewId) {
          url = `/fi${url}/compare?selected[]=${reviewId}`;
        }
        this.props.history.push(url);
      }
    } catch (err) {
      console.error('Error on handling code', err);
    }
  };

  render() {
    const { isSticky, beforeSticky, ...rest } = this.props;
    return (
      <SearchFormWrapper $isSticky={isSticky}>
        <SearchForm
          $searchOpen={this.state.searchOpen}
          $isSticky={isSticky}
          $beforeSticky={beforeSticky}
        >
          <SearchFormInner>
            <SearchIcon>
              <FontAwesomeIcon
                icon={['fal', 'search']}
                style={faIconStyle}
                onClick={() => this.toggleState()}
              />
            </SearchIcon>

            <CodeScannerContainer>
              <CodeScanner onCodeScanned={this.handleCodeScanned} />
            </CodeScannerContainer>
            <MobileMiniCartWrapper>
              <MiniCart />
            </MobileMiniCartWrapper>

            <SearchInput $searchOpen={this.state.searchOpen}>
              <TextFacet searchPage="/search" {...rest} />
            </SearchInput>

            <SearchOpen onClick={() => this.toggleState()}>
              <FontAwesomeIcon icon={['fal', 'search']} style={faIconStyle} />
            </SearchOpen>

            <DesktopSearchButton>
              <SearchButton icon="arrow-right" searchPage="/search" />
            </DesktopSearchButton>
          </SearchFormInner>
        </SearchForm>
      </SearchFormWrapper>
    );
  }
}

export default withRouter(SearchBar);
