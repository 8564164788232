import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DIALOG_WIDTH = 320;

const TipDialog = styled.div`
  box-shadow: 0 0 25px 0 rgba(62, 62, 62, 0.5);
  background-color: #ffffff;
  width: max-content;
  max-width: ${DIALOG_WIDTH}px;
  min-width: 180px;
  text-align: left;
  text-transform: none;
  padding: 46px 16px 24px;
  position: absolute;
  z-index: 10;
  transform: ${(props) =>
    props.$offset < 0
      ? `translateX(calc(-50% + ${Math.abs(0 - props.$offset) + 'px'}))`
      : `translateX(calc(-50% - ${props.$offset + 'px'}))`};
  left: 0;
  bottom: 10px;
  border: 4px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  font-size: 14px;
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.primary};
  line-height: 1.4;
  letter-spacing: 0;
  &:after {
    display: block;
    content: ' ';
    height: 24px;
    width: 24px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
    border-width: 0 4px 4px 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -17px;
    background-color: #fff;
    transform: ${(props) =>
        props.$offset < 0
          ? `translateX(${props.$offset + 'px'})`
          : `translateX(${props.$offset + 'px'})`}
      rotate(45deg);
  }
  .info-icon {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: default;
  }
  .info-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
`;

/**
 * Represents a tooltip box component.
 * @param {Object} props - Component properties.
 * @param {ReactNode} props.children - The content of the tooltip box.
 * @returns {JSX.Element} - The rendered component.
 */
const TooltipBox = ({ children }) => {
  const dialogRef = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (dialogRef.current) {
      // Calculate offset values if the dialog box goes over the viewport
      const toolTipRect = dialogRef.current.getBoundingClientRect();
      const { x, width } = toolTipRect;
      const leftOffset = x < 0 ? Math.round(x) : 0;
      setOffset(leftOffset);

      // Offset on the right side needs to be calculated separately using document clientWidth
      if (
        typeof window !== 'undefined' &&
        document?.documentElement !== 'undefined' &&
        leftOffset === 0
      ) {
        const { clientWidth } = document.documentElement;
        const rightOffsetDiff = Math.round(x) + width - clientWidth;
        const rightOffset = rightOffsetDiff > 0 ? rightOffsetDiff : 0;
        setOffset(rightOffset);
      }
    }
  }, []);

  if (!children) {
    return null;
  }

  return (
    <TipDialog $offset={offset} ref={dialogRef}>
      {children}
    </TipDialog>
  );
};

TooltipBox.propTypes = {
  children: PropTypes.node
};

export default TooltipBox;
