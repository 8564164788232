/**
 * This is the query to get page "BodyBuilder" ACF blocks.
 */

import gql from 'graphql-tag';

import FRAGMENT_SEO from '@/graphql/wordpress/_fragments/fragmentSeoModel';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';
import FRAGMENT_ARTICLE_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleLift';
import FRAGMENT_ARTICLE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleList';
import FRAGMENT_ARTICLE_HEAD_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentArticleHeadLift';
import FRAGMENT_BLACK_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentBlackList';
import FRAGMENT_CATEGORY_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentCategoryList';
import FRAGMENT_HERO_UNIT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentHeroUnit';
import FRAGMENT_HERO_CONTENT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentHeroContent';
import FRAGMENT_IMAGE_SET from '@/graphql/wordpress/_fragments/bodybuilder/fragmentImageSet';
import FRAGMENT_KULUTTAJA_INFO from '@/graphql/wordpress/_fragments/bodybuilder/fragmentKuluttajaInfo';
import FRAGMENT_MAGAZINE_LIFT from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMagazineLift';
import FRAGMENT_MAGAZINE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMagazineList';
import FRAGMENT_MAGAZINE_ARTICLE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMagazineArticleList';
import FRAGMENT_MEMBER_PROMO from '@/graphql/wordpress/_fragments/bodybuilder/fragmentMemberPromo';
import FRAGMENT_PAGE_LIST from '@/graphql/wordpress/_fragments/bodybuilder/fragmentPageList';
import FRAGMENT_SUBSTORY from '@/graphql/wordpress/_fragments/bodybuilder/fragmentSubstory';
import FRAGMENT_TEST_ARTICLE from '@/graphql/wordpress/_fragments/bodybuilder/fragmentTestArticle';
import FRAGMENT_TEST_RELATION from '@/graphql/wordpress/_fragments/bodybuilder/fragmentTestRelation';
import FRAGMENT_WYSIWYG from '@/graphql/wordpress/_fragments/bodybuilder/fragmentWysiwyg';

const QUERY_GET_PAGE_BODYBUILDER = gql`
  ${FRAGMENT_SEO}
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}
  ${FRAGMENT_ARTICLE_LIFT}
  ${FRAGMENT_ARTICLE_LIST}
  ${FRAGMENT_ARTICLE_HEAD_LIFT}
  ${FRAGMENT_BLACK_LIST}
  ${FRAGMENT_CATEGORY_LIST}
  ${FRAGMENT_HERO_UNIT}
  ${FRAGMENT_HERO_CONTENT}
  ${FRAGMENT_IMAGE_SET}
  ${FRAGMENT_KULUTTAJA_INFO}
  ${FRAGMENT_MAGAZINE_LIFT}
  ${FRAGMENT_MAGAZINE_LIST}
  ${FRAGMENT_MAGAZINE_ARTICLE_LIST}
  ${FRAGMENT_MEMBER_PROMO}
  ${FRAGMENT_PAGE_LIST}
  ${FRAGMENT_SUBSTORY}
  ${FRAGMENT_TEST_ARTICLE}
  ${FRAGMENT_TEST_RELATION}
  ${FRAGMENT_WYSIWYG}

  query getPageBodybuilder($slug: ID!) {
    page(id: $slug, idType: URI) {
      ...SeoModel
      ...ContentNodeBasicModel
      title
      excerpt
      paywall {
        restricted
        accessible
        products {
          title
          description
          price
          price_unit
          marketing_text
          excerpt
          link
          uri
          featuredImage {
            node {
              ...FeaturedImageModel
            }
          }
        }
      }
      categories {
        nodes {
          slug
        }
      }
      children {
        nodes {
          ... on Page {
            id
            title
            slug
            uri
            menuOrder
          }
        }
      }
      acf {
        __typename
        ...ArticleLift
        ...ArticleList
        ...ArticleHeadLift
        ...BlackList
        ...CategoryList
        ...HeroUnit
        ...HeroContent
        ...ImageSet
        ...KuluttajaInfo
        ...MagazineLift
        ...MagazineList
        ...MagazineArticleList
        ...MemberPromo
        ...PageList
        ...Substory
        ...TestArticle
        ...TestRelation
        ...Wysiwyg
      }
    }
  }
`;

export default QUERY_GET_PAGE_BODYBUILDER;
