import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getSelectedIDs } from '../test-utils';

const ComparisonButton = styled.div`
  padding: 16px 32px;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  letter-spacing: 1.5px;
  background-color: ${(props) =>
    props.$checked
      ? props.theme.colors.kuluttaja2021.tummaPetroli.full
      : props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  color: ${(props) =>
    props.$checked
      ? props.theme.colors.white
      : props.theme.colors.kuluttaja2021.tummaPetroli.full};
  text-align: center;
`;

export default class ComparisonButtonMobile extends React.Component {
  handleClick = () => {
    if (getSelectedIDs(this.props).length < 5 || this.props.checked) {
      this.props.onChange(!this.props.checked);
    }
  };
  render() {
    const { checked } = this.props;
    return (
      <ComparisonButton $checked={checked} onClick={this.handleClick}>
        {!checked ? (
          <FormattedMessage id="productReview.text.addComparison" />
        ) : (
          <FormattedMessage id="productReview.text.removeComparison" />
        )}
      </ComparisonButton>
    );
  }
}
