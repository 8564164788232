import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { OutsideClickHandler } from '@/redandblue/components';
import NavigationNodeStyler from './NavigationNodeStyler';
import Navigation from './Navigation';
import DropdownOpen from '@/components/Buttons/DropdownOpen';
import Link from '@/components/Link/Link';
import CategoryIcon from '@/components/Images/CategoryIcon';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash.get';
import { isMobileSmall } from '@/utils/mediaquery';

const OpenSub = styled(DropdownOpen)`
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${props.theme.colors.kuluttaja2021.tummaPetroli[5]};
    border: none;
    color: ${props.theme.colors.kuluttaja2021.harmaa.full};
    width: 59px;
    height: 59px;
    font-size: 38px;
    &:before, &:after{
      display: none;
    }
    &:hover {
      background-color: ${props.theme.colors.kuluttaja2021.tummaPetroli[40]};
    }
  `};
`;

const BackToMain = styled.div`
  display: none;
  font-size: 32px;
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px !important;
  padding: 10px;
  transform: rotate(180deg);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.mobileSmall`
      display: flex;
  `};
`;

const MenuIcon = styled.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: inline-block;
    `};
`;

const SubNavigationWrapper = styled.div`
  display: ${(props) => (props.$display ? 'block' : 'none')};
`;

/**
 * Represents a node in the navigation menu.
 * @param {Object} props - Component props
 * @param {number} props.depth - The depth of the node in the navigation hierarchy
 * @param {number} props.index - The index of the node in the list of siblings
 * @param {Object} props.node - The data representing the node
 * @param {Object} props.location - The current location object from react-router
 * @param {string} [props.size] - The size of the node
 * @param {boolean} [props.tabletHeader] - Flag indicating if the node is in tablet header
 * @returns {JSX.Element|null} - Rendered component or null if node is not available
 */
const NavigationNode = ({
  depth,
  index,
  node,
  location,
  size,
  tabletHeader,
  activeNodeId,
  nodeId,
  onClick
}) => {
  const [active, setActive] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [prevActiveNodeId, setPrevActiveNodeId] = useState(activeNodeId);
  const [prevLocation, setPrevLocation] = useState(location);

  /**
   * Toggles the active state of the node.
   * @param {Event} event - The click event
   */
  const toggleActive = (event) => {
    event.preventDefault();

    setActiveState(!active);

    if (window.scrollY < 181) {
      window.scroll({
        top: 0,
        left: 0
      });
    }

    depth === 0 && setSubMenuOpen(!subMenuOpen);
  };

  /**
   * Sets the active state of the node.
   * @param {boolean} active - The desired active state
   */
  const setActiveState = (active) => {
    if (isMobileSmall() || depth < 1) {
      setActive(active);
    }
  };

  /**
   * Checks if the node has children.
   * @returns {boolean} - True if the node has children, false otherwise
   */
  const hasChildren = () =>
    (node.childItems &&
      Array.isArray(node.childItems.nodes) &&
      node.childItems.nodes.length > 0) ||
    _get(node, 'connectedObject.categories.nodes[0]')
      ? true
      : false;

  /**
   * add / remove class to body to prevent scrolling when subnav is open
   */
  useEffect(() => {
    if (!subMenuOpen) {
      document.body.classList.remove('subnav-open');
    } else {
      document.body.classList.add('subnav-open');
    }
  }, [subMenuOpen]);

  /**
   * Lifecycle effect to handle updates and cleanup on unmount.
   */
  useEffect(() => {
    // set all nodes inactive if location key changes
    if (location.key !== prevLocation.key) {
      setActive(false);
      setSubMenuOpen(false);
      setPrevLocation(location);
    }

    setActive(activeNodeId === nodeId);

    // Cleanup function on component unmount
    return () => {
      depth === 0 && document.body.classList.remove('subnav-open');
    };
  }, [location.key, activeNodeId, nodeId]);

  // Return null if node is not available
  if (!node) {
    return null;
  }

  // Data for Google Analytics tracking
  const ga_data = {
    event: 'nav_link',
    prefix: 'nav',
    nav_type: 'mainmenu'
  };

  const onLinkClick =
    hasChildren() && depth <= 1
      ? depth === 0 || isMobileSmall()
        ? toggleActive
        : onClick
      : null;

  const category = _get(node, 'connectedObject.categories.nodes[0]');
  const label = node.label;

  return (
    <OutsideClickHandler as="li" onOutsideClick={() => setActiveState(false)}>
      <NavigationNodeStyler
        key={node.id}
        depth={depth}
        active={active}
        size={size}
        hasChildren={hasChildren()}
        tabletHeader={tabletHeader}
      >
        {hasChildren() && depth === 0 ? (
          <Link to={node.uri} ga_data={ga_data}>
            {label}
            <OpenSub
              style={{ marginLeft: '10px' }}
              onClick={onLinkClick}
              $active={active}
              $tabletHeader={tabletHeader}
            >
              {!tabletHeader && (
                <MenuIcon>
                  <FontAwesomeIcon icon={['fal', 'angle-right']} />
                </MenuIcon>
              )}
            </OpenSub>
          </Link>
        ) : (
          <Link to={node.uri} onClick={onLinkClick} ga_data={ga_data}>
            {category && <CategoryIcon icon={category.icon} />}
            {<span>{label}</span>}
          </Link>
        )}

        {hasChildren() && (
          <SubNavigationWrapper $display={(depth <= 1 && active) || depth > 1}>
            <Navigation
              resetActiveNode={depth === 0 && subMenuOpen}
              title={label}
              category={category}
              nodes={node.childItems.nodes}
              depth={depth + 1}
              tabletHeader={tabletHeader}
            >
              {depth === 0 && (
                <BackToMain
                  onClick={() => setActiveState(false)}
                  $tabletHeader={tabletHeader}
                >
                  <FontAwesomeIcon icon={['fal', 'angle-right']} />
                </BackToMain>
              )}
            </Navigation>
          </SubNavigationWrapper>
        )}
      </NavigationNodeStyler>
    </OutsideClickHandler>
  );
};

NavigationNode.propTypes = {
  depth: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  node: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  size: PropTypes.string,
  tabletHeader: PropTypes.bool
};

export default withRouter(NavigationNode);
