import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transition from 'styled-transition-group';
import { OutsideClickHandler } from '@/redandblue/components';
import CloseButton from '@/components/Buttons/CloseButton';
import TooltipBox from './TooltipBox';

const InfoButton = styled.div`
  display: inline-block;
  position: relative;
  bottom: 2px;
  margin-left: 6px;
  border: 0 none;
  background: none;
  border-radius: 0;
  box-shadow: 0 none;
  &:hover {
    .tooltip-transition {
      display: block;
    }
  }
`;

const infoIconStyles = `
padding: 0;
height: 16px;
text-align: center;
width: 16px;
border: 0;
margin: 0;
border-radius: 50%;
color: #fff;
font-family: monospace;
padding-top: 1px;
font-size: 11px;
cursor: pointer;
letter-spacing: 0;
font-weight: 800;
text-transform: none;
position: relative;
z-index: 0;
appearance: none;
&:after {
  content: 'i';
}`;

const InfoIconButton = styled.button`
  ${infoIconStyles}
  background-color: ${(props) => props.theme.colors.kuluttaja2021.lila[60]};
`;

const InfoIcon = styled.div`
  ${infoIconStyles}
  background-color: ${(props) => props.theme.colors.kuluttaja2021.lila[60]};
`;

const TooltipTransition = transition.div.attrs({
  unmountOnExit: true,
  timeout: 150
})`
  height: 0;
  width: 0;
  position: absolute;
  z-index: 10;
  top: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;

  &:enter {
    opacity: 0.01;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
  }
`;

/**
 * Represents a tooltip component.
 * @param {Object} props - Component properties.
 * @param {string} props.content - The content of the tooltip.
 * @returns {JSX.Element} - The rendered component.
 */
const Tooltip = ({ content, ...rest }) => {
  const [active, setActive] = useState(false);

  /**
   * Toggles the active state of the tooltip.
   */
  const toggleActive = (e) => {
    e.preventDefault();
    setActive(!active);
  };

  if (!content) {
    return null;
  }

  return (
    <OutsideClickHandler as="span" onOutsideClick={() => setActive(false)}>
      <InfoButton {...rest}>
        <InfoIconButton
          onClick={toggleActive}
          className="infoIcon"
          type="button"
        />
        <TooltipTransition className="tooltip-transition" in={active}>
          <TooltipBox>
            {content}
            <InfoIcon className="info-icon" />
            <CloseButton
              className="info-close"
              type="button"
              onClick={() => setActive(false)}
            />
          </TooltipBox>
        </TooltipTransition>
      </InfoButton>
    </OutsideClickHandler>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string
};

export default Tooltip;
