import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Styled component for styling the excerpt.
 */
const ExcerptStyler = styled.div`
  > div {
    > * {
      display: none;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      color: inherit;
    }

    .ingress {
      display: inline;
    }
  }
`;

/**
 * Excerpt component for displaying HTML content.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.html - HTML content to be displayed.
 * @param {string} props.className - Additional class name for styling.
 * @param {string} props.as - HTML tag to use for rendering (default: 'div').
 * @param {boolean} props.accessible - Accessibility flag (default: true).
 * @returns {JSX.Element} - React component.
 */
const Excerpt = (props) => {
  if (!props.html || props.html === 'false') {
    return null;
  }

  const { html, className } = props;
  const Element = props.as;

  return (
    <ExcerptStyler className={className}>
      <Element dangerouslySetInnerHTML={{ __html: html }} />
    </ExcerptStyler>
  );
};

Excerpt.propTypes = {
  as: PropTypes.string,
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
  accessible: PropTypes.bool
};

Excerpt.defaultProps = {
  as: 'div',
  accessible: true
};

export default Excerpt;
