import cookies from '../../utils/cookies';

export const STORE_CREDENTIALS = 'auth/STORE_CREDENTIALS';
export const RESET_AUTH = 'auth/RESET_AUTH';

const getDateTomorrow = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  return now;
};

const getDateNextYear = () => {
  const now = new Date();

  now.setFullYear(now.getFullYear() + 1);
  // play it safe and remove 2 days
  now.setDate(now.getDate() - 2);
  return now;
};

const initialState = {
  token: null,
  user: null,
  stayLoggedIn: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_CREDENTIALS:
      const token = action.payload.token || state.token;
      const stayLoggedIn = action.payload.stayLoggedIn || false;
      const cookieTime = stayLoggedIn ? getDateNextYear() : getDateTomorrow();
      // TODO We should consider changing to HttpOnly cookie instead
      if (token) {
        cookies.setItem('authToken', token, cookieTime);
      }

      return {
        token,
        user: action.payload.user,
        stayLoggedIn
      };
    case RESET_AUTH:
      cookies.removeItem('authToken');
      return {
        token: null,
        user: null,
        stayLoggedIn: false
      };
    default:
      return state;
  }
};

export default reducer;

export const store = ({ refreshToken, user, stayLoggedIn }) => ({
  type: STORE_CREDENTIALS,
  payload: { token: refreshToken, user, stayLoggedIn }
});

export const reset = () => ({
  type: RESET_AUTH
});
