/**
 * This is a fragment of the "BodyBuilder" Article Lift block.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_CATEGORY_CHILDREN_MODEL from '@/graphql/wordpress/_fragments/fragmentCategoryChildrenModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_ARTICLE_LIFT = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_CATEGORY_CHILDREN_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment ArticleLift on Bodybuilder_ArticleLift {
    title
    articles {
      ... on Post {
        ...ContentNodeBasicModel
        title
        excerpt
        paywall {
          premium
        }
        featuredImage {
          node {
            ...FeaturedImageModel
          }
        }
        categories {
          nodes {
            ...CategoryChildrenModel
          }
        }
      }
      ... on Page {
        ...ContentNodeBasicModel
        paywall {
          premium
        }
        title
        excerpt
        featuredImage {
          node {
            ...FeaturedImageModel
          }
        }
        categories {
          nodes {
            ...CategoryChildrenModel
          }
        }
      }
    }
  }
`;

export default FRAGMENT_ARTICLE_LIFT;
