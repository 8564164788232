import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import WpImage from '@/components/Images/WpImage';
import PriceBall from '@/components/Price/PriceBall';
import { StyledButton } from '@/components/Buttons/Button';
import KStar from '@/icons/KStar.svg';
import MembershipRestrictedContent from '@/components/Membership/MembershipRestrictedContent';
import { MEMBER_SUBSCRIBER } from '@/configs/memberships';
import { FormattedMessage } from 'react-intl';
import { Arrow } from '@/components/Buttons/BackForwardButtons';
import theme from '@/theme';

const MemberOption = styled.div`
  --bg-color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--bg-color);

  ${(props) => props.theme.media.mobileSmall`
    margin-bottom: 22px;

    &:nth-child(1) {
       --bg-color: ${(props) => props.theme.colors.kuluttaja2021.lila[10]};
    }
    &:nth-child(2) {
      --bg-color: ${(props) => props.theme.colors.lightBlueGrey};
    }
  `};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    display: none;
    background-image: linear-gradient(180deg, transparent, var(--bg-color) 75%);
    transform: scaleY(${(props) => (props.$isOpen ? '0' : '1')});
    transition: transform 0.3s;
    transform-origin: bottom center;

    ${(props) => props.theme.media.mobileSmall`
      display: block;
    `};
  }
`;

const OptionHead = styled.div`
  padding: 56px 46px 36px;
  text-align: center;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${(props) => props.theme.media.mobileSmall`
    border-bottom: 0 none;
    min-height: 0;
  `};
  img {
    margin-bottom: 22px;
  }
  h3 {
    margin-bottom: 8px;
  }
`;
const Price = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 17px;
  color: ${(props) => props.theme.colors.grey};
  text-transform: uppercase;
  font-weight: bold;
  ${(props) => props.theme.media.mobileSmall`
    display: ${(props) => (props.$isFree ? 'block' : 'none')};
  `};
`;
const FeaturePriceBall = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: none;
  ${(props) => props.theme.media.mobileSmall`
    display: flex;
  `};
`;
const FeaturesWrapper = styled.div`
  text-align: center;
  font-size: 17px;
  padding: 42px 22px 16px;
  border-top: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
`;

const Features = styled.div`
  ${(props) => props.theme.media.mobileSmall`
    transition: max-height 0.3s;
    max-height: ${(props) =>
      props.$maxHeight ? `${props.$maxHeight}px` : '132px'};
    overflow: hidden;
  `};
`;

const Feature = styled.div`
  margin-bottom: 26px;
  color: ${(props) =>
    props.$isEnabled ? props.theme.colors.darkGrey : props.theme.colors.grey};
  font-weight: ${(props) => (props.$isEnabled ? 'bold' : 'normal')};

  ${(props) => props.theme.media.mobileSmall`
    margin-bottom: 1rem;
    display: ${(props) => (props.$isEnabled ? 'block' : 'none')};
  `};
`;

const FeaturesCTA = styled.div`
  display: none;
  position: relative;
  z-index: 2;
  text-align: center;

  ${(props) => props.theme.media.mobileSmall`
      display: block;
    `};
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  appearance: none;
  padding: 0;
  margin: 2rem 0 3rem;
  font-size: 14px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.secondary};
  letter-spacing: 1.5px;
  color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
  text-transform: uppercase;
`;

const ToggleArrow = styled.div`
  display: inline-block;
  margin-left: 0.5rem;
  transition: transform 0.3s;
  transform: rotate(${(props) => (props.$isOpen ? '90deg' : '270deg')});
`;

const AsteriskContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Asterisk = styled.div`
  background-image: url(${KStar});
  background-size: 60% 60%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.lightBlue};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: inline-block;
`;

const OrderButton = styled(StyledButton)`
  margin-top: 1rem;
`;

/**
 * React component for displaying a single option column in the member options section.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.data - The data for the option column.
 * @param {string} props.bullets - The bullet points for the option column.
 * @param {Array} props.featurekey - The key for the feature. Not used?
 * @returns {JSX.Element} React component.
 */
const OptionColumn = ({ data, bullets, featurekey }) => {
  const priceUnit =
    data.price_unit && data.price_unit !== '' ? data.price_unit : 'kk';

  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [featuresMaxHeight, setFeaturesMaxHeight] = useState(null);
  const featuresRef = useRef(null);

  /**
   * Toggle the visibility of the features list
   */
  const toggleFeatures = () => {
    setIsFeaturesOpen(!isFeaturesOpen);
  };

  /**
   * Set the max height of the features list when it is opened
   */
  useEffect(() => {
    if (isFeaturesOpen) {
      setFeaturesMaxHeight(featuresRef.current.clientHeight);
    } else {
      setFeaturesMaxHeight(null);
    }
  }, [isFeaturesOpen]);

  return (
    <MemberOption $isOpen={isFeaturesOpen}>
      <OptionHead>
        {data.featuredImage ? (
          <WpImage image={data.featuredImage} optimalWidth={288} />
        ) : (
          <AsteriskContainer>
            <Asterisk />
          </AsteriskContainer>
        )}
        <h3>{data.title}</h3>
        <Price $isFree={data.price ? false : true}>
          {data.price ? `${data.price}€/${priceUnit}` : 'Ilmainen'}
        </Price>
        <FeaturePriceBall>
          <PriceBall price={data.price} priceUnit={data.price_unit} small />
        </FeaturePriceBall>
        <MembershipRestrictedContent excludedMemberships={[MEMBER_SUBSCRIBER]}>
          {data.uri && (
            <OrderButton to={data.uri}>
              {data.buttonText ? data.buttonText : 'Tilaa kuluttaja'}
            </OrderButton>
          )}
        </MembershipRestrictedContent>
      </OptionHead>
      {bullets.length > 0 && (
        <>
          <FeaturesWrapper>
            <Features $isOpen={isFeaturesOpen} $maxHeight={featuresMaxHeight}>
              <div ref={featuresRef}>
                {bullets.map((bullet, index) => (
                  <Feature
                    key={`feature-${index}`}
                    $isEnabled={bullet.contains}
                  >
                    {bullet.content}
                  </Feature>
                ))}
              </div>
            </Features>
          </FeaturesWrapper>
          <FeaturesCTA>
            <FormattedMessage
              id={
                isFeaturesOpen
                  ? 'member.features.showLess'
                  : 'member.features.showAll'
              }
            >
              {(text) => (
                <ToggleButton onClick={toggleFeatures} type="button">
                  {text}{' '}
                  <ToggleArrow $isOpen={isFeaturesOpen}>
                    <Arrow
                      left
                      color={theme.colors.kuluttaja2021.tummaPetroli.full}
                    />
                  </ToggleArrow>
                </ToggleButton>
              )}
            </FormattedMessage>
          </FeaturesCTA>
        </>
      )}
    </MemberOption>
  );
};

OptionColumn.propTypes = {
  data: PropTypes.object,
  bullets: PropTypes.array
};
OptionColumn.defaultProps = {
  bullets: []
};

export default OptionColumn;
