import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { setDetails } from '@/store/reducers/cart';
import CartTotals from '@/components/Shop/CartTotals';
import RestQueryHandler from '@/components/GraphQL/RestQueryHandler';
import CartItems from '@/components/Shop/CartItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledButton } from '@/components/Buttons/Button';
import getCartUrlParams from '@/utils/cartUrlParams';

const Controls = styled.div`
  order: 9;
`;

const CartContainer = styled.div`
  position: absolute;
  max-width: 80vw;
  width: 560px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[20]};
  padding: 24px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  ${(props) => props.theme.media.tablet`
    box-shadow: none;
    top: 60px;
  `};
`;

const CartHeader = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  > h2 {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 1rem;
  vertical-align: baseline;
`;

const SideCartItems = styled.div`
  padding: 38px 0;
  border-color: ${(props) => props.theme.colors.darkGrey};
  border-style: solid;
  border-width: 1px 0;
  margin-bottom: 32px;
  min-height: 124px;

  ${(props) => props.theme.media.tablet`
    padding: 1rem 0;
    min-height: 0;
  `};
`;

const Totals = styled.div`
  margin-bottom: 32px;
  .total {
    &-row {
      text-align: right;
      grid-template-columns: auto min-content;
      column-gap: 2%;
      margin-bottom: 8px;
      &-final {
        font-size: 17px;
        strong {
          font-size: 24px;
        }
      }
    }
    &-taxes {
      text-align: right;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 1.7px;
      color: ${(props) => props.theme.colors.grey};
      text-transform: uppercase;
    }
  }
`;

const ProceedButton = styled(StyledButton)`
  float: right;
`;

class SideCart extends Component {
  static propTypes = {
    setDetails: PropTypes.func.isRequired
  };
  state = {
    ecommerce: {} // Used for GA events
  };

  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active,
      cartUrlParams: getCartUrlParams()
    };
  }

  hideCart() {
    this.setState({ active: false });
  }

  canProceed(cart) {
    return cart.items.length > 0;
  }

  render() {
    const ga_data = {
      event: 'begin_checkout',
      ecommerce: this.state.ecommerce
    };

    return (
      <CartContainer>
        <CartHeader>
          <Controls>{this.props.children}</Controls>
          <h2>
            <IconWrapper>
              <FontAwesomeIcon icon={['fal', 'shopping-basket']} />
            </IconWrapper>
            <FormattedMessage id="shop.cart.title" />
          </h2>
        </CartHeader>
        <RestQueryHandler
          path={`/woocommerce/v2/full-cart${this.state.cartUrlParams}`}
        >
          {(cart, { refetch }) => (
            <div>
              <SideCartItems>
                <CartItems
                  plain
                  items={cart ? Object.values(cart.items) : []}
                  onUpdate={() => refetch()}
                  updateEcommerce={(ecommerce) =>
                    this.setState({ ecommerce: ecommerce })
                  }
                />
              </SideCartItems>
              <Totals>
                {cart && <CartTotals cart={cart} plain hideTax />}
              </Totals>
            </div>
          )}
        </RestQueryHandler>
        <ProceedButton
          $color="kuluttaja2021.tyrni.full"
          $txtcolor="kuluttaja2021.tummaPetroli.full"
          to="/kauppa/ostoskori"
          ga_data={ga_data}
        >
          Osta
        </ProceedButton>
      </CartContainer>
    );
  }
}

export default connect(null, { setDetails })(SideCart);
