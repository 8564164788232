import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fetch from 'node-fetch';
import { asyncComponent } from '@jaredpalmer/after';
import { FormattedMessage } from 'react-intl';
import Loader from '@/components/Loader/Loader';
import CodeScannerIcon from './CodeScannerIcon.svg';
import { isMediaDevicesSupported } from '@/utils/helpers';
import config from '@/config';
import CodeScannerTutorial from './CodeScannerTutorial';
import CodeScannerTutorialButton from './CodeScannerTutoriaButton';
import CodeScannerGetTutorialButton from './CodeScannerGetTutoriaButton';
import CodeScannerTopBar from './CodeScannerTopBar';

const Container = styled.div`
  display: flex;
  position: static;
  z-index: 13;
`;

const ScanView = styled.div.attrs({
  className: 'kuluttaja-ean-scan' // For SEO purposes
})`
  width: 100vw;
  height: 100vh;
  z-index: 42;

  background-color: rgb(211, 211, 211);
  text-align: center;
`;

const Button = styled.button`
  font-size: 1em;
  border: none;
  background: none;
  padding: 16px;
  margin: 0;
  width: 62px;
  img {
    height: 28px;
  }
`;

const MatchFound = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 2em;
`;

export default class CodeScanner extends React.Component {
  static propTypes = {
    onCodeScanned: PropTypes.func.isRequired
  };

  state = {
    preview: false,
    matchFound: false,
    error: null,
    eanTutorial: false
  };

  onCodeScanned = async (code) => {
    this.setState({ matchFound: true });

    try {
      const BASE_URL = `${config.app.apiProxyHost}/woocommerce`;
      const response = await fetch(
        `${BASE_URL}/kuluttaja/v1/find_pages_by_ean?ean=${code}`
      );
      const data = await response.json();
      this.props.onCodeScanned(code, data);
      this.setState({
        preview: false,
        matchFound: false
      });
    } catch (err) {
      this.setState({
        error: err,
        matchFound: false
      });
    }
  };

  toggleState() {
    const { preview } = this.state;
    const eanTutorial =
      localStorage.getItem('eanTutorial') !== 'false' && !preview
        ? true
        : false;

    this.setState({
      preview: !preview,
      error: null,
      eanTutorial: eanTutorial
    });
  }

  clickPrevious() {
    this.setState({
      preview: false,
      error: null,
      eanTutorial: false
    });
  }
  goToScan() {
    localStorage.setItem('eanTutorial', 'false');
    this.setState({
      preview: true,
      error: null,
      eanTutorial: false
    });
  }
  goToTutorial() {
    localStorage.setItem('eanTutorial', 'true');
    this.setState({
      preview: true,
      error: null,
      eanTutorial: true
    });
  }

  render() {
    if (!isMediaDevicesSupported()) {
      return null;
    }

    const Scan = asyncComponent({
      loader: () => import(/* webpackChunkName: "CodeScanner" */ './Scan'),
      chunkName: 'CodeScanner',
      Placeholder: () => <Loader visible={true} />
    });

    const { preview, matchFound, eanTutorial } = this.state;
    return (
      <Container>
        <Button onClick={() => this.toggleState()}>
          <img
            src={CodeScannerIcon}
            alt="Skannaa koodi"
            aria-label="Skannaa koodi"
          />
        </Button>
        {eanTutorial && (
          <ScanView>
            <CodeScannerTopBar clickPrevious={() => this.clickPrevious()} />
            <CodeScannerTutorial />
            <CodeScannerTutorialButton clickScan={() => this.goToScan()} />
          </ScanView>
        )}
        {preview && !eanTutorial && (
          <ScanView>
            <CodeScannerTopBar clickPrevious={() => this.clickPrevious()} />
            {this.state.error && (
              <div>
                <FormattedMessage id="errors.mediaDevices.genericError" />
              </div>
            )}
            {matchFound && (
              <MatchFound>
                <Loader small={true} visible={true} />
                <FormattedMessage id="codescanner.text.codeFound" />
              </MatchFound>
            )}
            {!matchFound && (
              <Scan
                onCodeScanned={this.onCodeScanned}
                onError={(error) => this.setState({ error })}
              />
            )}
            <CodeScannerGetTutorialButton
              clickTutorial={() => this.goToTutorial()}
            />
          </ScanView>
        )}
      </Container>
    );
  }
}
