import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextFacet from '@/containers/SearchFacets/TextFacet';
import SearchButton from '@/containers/SearchFacets/SearchButton';

const SearchFormInner = styled.div`
  display: flex;
  align-items: center;
  grid-column: span 6;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  ${(props) => props.theme.media.mobile`
    display: grid;
    grid-template-columns: 51px auto;
    grid-template-areas: "a b" "c c";
    button{
      width: 100%;
      grid-area: c;
    }
  `};
  input {
    border: none;
    padding: 15px 10px;
    font-size: 18px;
    width: 100%;
    height: 100%;
    &::placeholder {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  svg {
    color: #a0aeb7;
  }
  button {
    height: 60px;
    display: block;
  }
`;

const SearchInput = styled.div`
  width: 100%;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: 18px;
`;

/**
 * The SearchBar component provides a search input field with optional search button.
 * @param {Object} props - The component's props.
 * @param {boolean} [props.displayButton=false] - Indicates whether to display a search button.
 */
const SearchBar = ({ displayButton, ...rest }) => (
  <SearchFormInner>
    <SearchIcon icon={['fal', 'search']} />
    <SearchInput>
      <TextFacet {...rest} />
    </SearchInput>
    {displayButton && <SearchButton {...rest} />}
  </SearchFormInner>
);

SearchBar.propTypes = {
  displayButton: PropTypes.bool
};

SearchBar.defaultProps = {
  displayButton: false
};

export default SearchBar;
