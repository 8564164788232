/**
 * This is the query to get the EAN Scanner settings (instructions) from the WordPress ACF Options page.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const QUERY_GET_EAN_SCANNER_SETTINGS = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  query getEanScannerSettings {
    allSettings {
      eanScannerSettings {
        pages {
          smallText
          largeText
          image {
            ...FeaturedImageModel
          }
        }
      }
    }
  }
`;

export default QUERY_GET_EAN_SCANNER_SETTINGS;
