/**
 * This is a fragment of the "BodyBuilder" Member Promo block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_MEMBER_PROMO = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment Product on Product {
    title
    link
    uri
    price
    price_unit
    featuredImage {
      node {
        ...FeaturedImageModel
      }
    }
  }

  fragment MemberPromo on Bodybuilder_MemberPromo {
    title
    excerpt
    bullets
    products_title
    digital_subscription_product {
      ...Product
    }
    digital_product_bullets {
      content
      contains
    }
    subscription_product {
      ...Product
    }
    subscription_product_bullets {
      content
      contains
    }
    free_product_bullets {
      content
      contains
    }
  }
`;

export default FRAGMENT_MEMBER_PROMO;
