import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

/**
 * Styled container for the Loader component.
 */
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 180px;
  ${(props) =>
    props.$small &&
    `
    height: 100%;
  `};
`;

/**
 * Styled text for the Loader component.
 */
const Text = styled.div`
  margin-left: 15px;
  color: ${(props) =>
    props.$whiteLoader
      ? '#ffffff'
      : props.theme.colors.kuluttaja2021.harmaa.full};
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
`;

/**
 * Loader component that displays a loading animation.
 * @param {Object} props - The component's props.
 * @param {boolean} props.small - Set to true for a smaller loader.
 * @param {boolean} props.whiteLoader - Set to true to use a white loader.
 * @param {boolean} props.visible - Set to true to make the loader visible.
 */
const Loader = ({ small, whiteLoader, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <LoadingContainer $small={small}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="38"
        viewBox="0 0 37 38"
      >
        <path
          fill={whiteLoader ? '#ffffff' : '#9AAFBC'}
          fillRule="evenodd"
          d="M37 23.915l-4.738 8.122-10.942-8.348 1.805 13.537h-9.25l1.805-13.537-10.942 8.348L0 23.915l12.634-5.302L0 13.31l4.738-8.122 10.942 8.348L13.875 0h9.25L21.32 13.537l10.942-8.348L37 13.311l-12.634 5.302z"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 18.5 19"
            to="360 18.5 19"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      {!small && (
        <Text $whiteLoader={whiteLoader}>
          <FormattedMessage id="label.loading" />
        </Text>
      )}
    </LoadingContainer>
  );
};

/**
 * Prop types for the Loader component.
 */
Loader.propTypes = {
  small: PropTypes.bool,
  visible: PropTypes.bool
};

/**
 * Default props for the Loader component.
 */
Loader.defaultProps = {
  small: false,
  visible: false
};

export default Loader;
