/**
 * This is the query to get the epaper link images from the WordPress ACF Options page.
 */

import gql from 'graphql-tag';

const QUERY_GET_EPAPER_LINK = gql`
  query getEPaperLink($issueId: Int, $magazineId: Int) {
    epaperLink(issueId: $issueId, magazineId: $magazineId)
  }
`;

export default QUERY_GET_EPAPER_LINK;
