import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import Loader from './components/Loader/Loader';
import Home from './pages/Home';

export default [
  {
    path: '/:locale/artikkeli/:slug',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackMode: "lazy" */
          /* webpackChunkName: "post-chunk" */
          './pages/Post'
        ),
      chunkName: 'post-chunk',
      Placeholder: () => <Loader visible={true} />
    })
  },
  {
    path: '/:locale/search',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackMode: "lazy" */
          /* webpackChunkName: "search-chunk" */
          './pages/Search'
        ),
      chunkName: 'search-chunk',
      Placeholder: () => <Loader visible={true} />
    })
  },
  {
    path: '/:locale/käyttäjä',
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackMode: "lazy" */
          /* webpackChunkName: "user-chunk" */
          './pages/User'
        ),
      chunkName: 'user-chunk',
      Placeholder: () => <Loader visible={true} />
    })
  },
  {
    path: '/:locale/kauppa',
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackMode: "lazy" */
          /* webpackChunkName: "shop-chunk" */
          './pages/Shop'
        ),
      chunkName: 'shop-chunk',
      Placeholder: () => <Loader visible={true} />
    })
  },
  {
    path: '/:locale/:slug?',
    component: Home
  }
];
