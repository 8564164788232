import React from 'react';
import { FormattedNumber } from 'react-intl';

/**
 * A component for formatting money values using the react-intl library.
 *
 * @param {Object} props - The component props.
 * @param {number|string} props.value - The value to format as money.
 * @param {string} [props.style='currency'] - The formatting style, default is 'currency'.
 * @param {number} [props.digits=2] - The number of digits to display after the decimal point, default is 2.
 * @param {...any} [props.rest] - Additional props to pass to FormattedNumber.
 * @returns {JSX.Element} - The formatted money value as a React component.
 */
const Money = ({ value, style = 'currency', digits = 2, ...rest }) => {
  // Handle cases where the value contains commas as decimal separators
  const formattedValue =
    isNaN(value) && value?.includes(',') ? value.replace(/,/g, '.') : value;

  return (
    <FormattedNumber
      style={style}
      value={formattedValue}
      minimumFractionDigits={digits}
      maximumFractionDigits={digits}
      currency="EUR"
      {...rest}
    />
  );
};

export default Money;
