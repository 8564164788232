/**
 * This is a fragment of the Menu Item basic fields.
 */

import gql from 'graphql-tag';

const FRAGMENT_MENU_ITEM_MODEL = gql`
  fragment MenuItemModel on MenuItem {
    id
    label
    title
    url
    uri
  }
`;

export default FRAGMENT_MENU_ITEM_MODEL;
