import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LiftGrid from '@/components/LiftCard/LiftGrid';
import Container from '@/browser/Container';
import { asyncComponent } from '@jaredpalmer/after';
import Loader from '@/components/Loader/Loader';

const Section = styled.div`
  padding: 64px 0;
  background-color: #fff;
`;

const CatListGrid = styled(LiftGrid)`
  ${(props) => props.theme.media.mobileTabletOnly`
    grid-template-areas: 'first second' 'blacklist blacklist';
  `};
`;

/**
 * CategoryList component for displaying a list of categories and posts.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.display_blacklist - Whether to display a blacklist section.
 * @param {number} props.post_count - Number of posts to display.
 * @param {Array} props.categories - List of categories.
 * @param {Array} props.magazine_categories - List of magazine categories.
 * @param {string} props.previousSection - The ID of the previous section.
 * @param {Object} props.blacklist_image - The image to display in the blacklist section.
 * @returns {JSX.Element} - Rendered CategoryList component.
 */
const CategoryList = ({
  categories = [],
  magazine_categories = [],
  display_blacklist,
  blacklist_image,
  post_count,
  previousSection
}) => {
  let combinedCategories = [];

  if (
    (!categories || categories.length === 0) &&
    (!magazine_categories || magazine_categories.length === 0)
  ) {
    return null;
  }

  if (Array.isArray(categories) && categories.length > 0) {
    combinedCategories = categories;
  }

  if (Array.isArray(magazine_categories) && magazine_categories.length > 0) {
    combinedCategories = combinedCategories.concat(magazine_categories);
  }

  // remove null values
  const filteredCategories = combinedCategories.filter(
    (value) => typeof value === 'object' && value !== null
  );

  const CategoryListPosts = asyncComponent({
    loader: () =>
      import(/* webpackChunkName: "CategoryListPosts" */ './CategoryListPosts'),
    chunkName: 'CategoryListPosts',
    Placeholder: () => <Loader visible={true} />
  });
  const CategoryListBlackList = asyncComponent({
    loader: () =>
      import(
        /* webpackChunkName: "CategoryListBlackList" */ './CategoryListBlackList'
      ),
    chunkName: 'CategoryListBlackList',
    Placeholder: () => <Loader visible={true} />
  });

  return (
    <Section $afterHero={previousSection === 'Bodybuilder_HeroUnit'}>
      <Container>
        <CatListGrid>
          {filteredCategories.map((cat) => (
            <CategoryListPosts
              key={cat.id}
              category={cat}
              post_count={post_count}
            />
          ))}
          {display_blacklist && (
            <CategoryListBlackList image={blacklist_image} />
          )}
        </CatListGrid>
      </Container>
    </Section>
  );
};

CategoryList.propTypes = {
  display_blacklist: PropTypes.bool,
  post_count: PropTypes.number,
  categories: PropTypes.array,
  magazine_categories: PropTypes.array,
  previousSection: PropTypes.string,
  blacklist_image: PropTypes.object
};

CategoryList.defaultProps = {
  display_blacklist: false,
  post_count: 3,
  categories: [],
  magazine_categories: [],
  previousSection: ''
};

export default CategoryList;
