/**
 * This is a fragment of the "BodyBuilder" Article List block.
 */

import gql from 'graphql-tag';

const FRAGMENT_ARTICLE_LIST = gql`
  fragment ArticleList on Bodybuilder_ArticleList {
    title
    category {
      id
      categoryId
    }
  }
`;

export default FRAGMENT_ARTICLE_LIST;
