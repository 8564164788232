import { combineReducers } from 'redux';

// Import individual reducers
import auth from './auth';
import cart from './cart';
import prisjakt from './prisjakt';
import membership from './membership';
import reducer from '../../redandblue/components/Facet/reducer';

// Combine the imported reducers into the root reducer
const rootReducer = combineReducers({
  auth, // Reducer for authentication state
  cart, // Reducer for shopping cart state
  facets: reducer, // Reducer for facets state
  prisjakt, // Reducer for Prisjakt state
  membership // Reducer for membership state
});

export default rootReducer;
