/**
 * This is a fragment of the "BodyBuilder" Wysiwyg block.
 */

import gql from 'graphql-tag';

const FRAGMENT_WYSIWYG = gql`
  fragment Wysiwyg on Bodybuilder_Wysiwyg {
    content
  }
`;

export default FRAGMENT_WYSIWYG;
