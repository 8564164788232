import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@/components/Tooltip/Tooltip';

/**
 * Represents a reference component.
 * @param {Object} props - Component properties.
 * @param {Object} props.column - The column object.
 * @param {Array} props.descriptions - Array of descriptions.
 * @param {Object} props.product - The product object.
 * @param {Array} props.references - Array of references.
 * @returns {JSX.Element|null} - The rendered component or null if no references are found.
 */
const Reference = ({ column, descriptions, product, references }) => {
  // Check if descriptions is an array and not empty
  if (!Array.isArray(descriptions) || descriptions.length === 0) {
    return null;
  }

  // Determine the references based on the props
  const refs = product ? product.references : references;

  // Return null if no references are available
  if (!refs) {
    return null;
  }

  // Find the reference with the matching key
  const ref = refs.find((r) => r.key === column.name);

  // Render the Tooltip component with the reference text
  if (ref) {
    const refIndex = parseInt(ref.value, 10) - 1;
    const refText = descriptions[refIndex];
    return <Tooltip content={refText} />;
  }

  return null;
};

Reference.propTypes = {
  column: PropTypes.object.isRequired,
  descriptions: PropTypes.array,
  product: PropTypes.object,
  references: PropTypes.array
};

export default Reference;
