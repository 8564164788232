import React from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';

const RangeInputStyles = styled.div`
  padding: 14px 13px 20px 13px;
  .input-range__slider {
    appearance: none;
    background: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
    border: 1px solid
      ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
    border-radius: 100%;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0);
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition:
      transform 50ms ease-out,
      box-shadow 0.3s ease-out;
    width: 1rem;
    &:hover {
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.4);
      background-color: ${(props) =>
        props.theme.colors.kuluttaja2021.tummaPetroli[10]};
    }
  }
  .input-range__slider:active {
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: 'Helvetica Neue', san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
`;

const Range = (props) => {
  return (
    <RangeInputStyles>
      <InputRange {...props} />
    </RangeInputStyles>
  );
};

export default Range;
