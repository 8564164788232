/**
 * This is a fragment of the User Login model.
 */

import gql from 'graphql-tag';

const FRAGMENT_USER_LOGIN_MODEL = gql`
  fragment UserLoginModel on User {
    id
    commentingName
    userId
    sfmcId
    name
    email
    firstName
    lastName
    phoneNumber
    address
    postCode
    city
    shippingFirstName
    shippingLastName
    shippingPhoneNumber
    shippingAddress
    shippingAddress2
    shippingPostCode
    shippingCity
    shippingCountry
    customerType
    company
    businessId
    billingType
    billingCountry
    eInvoiceAddress
    eInvoiceOperator
    invoicingEmailAddress
    customerNumber
    emailOptIn
    roles {
      nodes {
        name
      }
    }
  }
`;

export default FRAGMENT_USER_LOGIN_MODEL;
