import fetch from 'node-fetch';
import config from '@/config';
import cookies from './cookies';
import getCartUrlParams from './cartUrlParams';
import { COOKIE_COCART_API_KEY } from '@/configs/cocart';

const BASE_URL = `${config.app.apiProxyHost}/woocommerce/v2/cart`;

/**
 * Headers for the requests.
 *
 * @returns {object} - Headers for the request.
 */
const getHeaders = () => {
  const basicHeaders = {
    'Content-Type': 'application/json'
  };

  return basicHeaders;
};

/**
 * Get date on now + 6 days.
 *
 * @returns {Date} - date timestamp.
 */
const getDateNextWeek = () => {
  const now = new Date();
  // make the cookie expire in current + 6 day (cocart key expires in 7 days by default)
  now.setDate(now.getDate() + 6);
  return now;
};

/**
 * Get cart url with cart params.
 *
 * @param {string} url url to be appended with cart params
 * @returns {string} - url with cart params.
 */
const getCartUrl = (url) => {
  const cocartParams = getCartUrlParams();

  return `${BASE_URL}/${url}${cocartParams}`;
};

export async function addToCart(productId, quantity) {
  const qty = parseInt(quantity, 10);
  if (isNaN(qty) || qty <= 0) {
    throw new Error('Invalid quantity');
  }

  const url = getCartUrl('add-item');

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      product_id: productId.toString(),
      quantity: qty
    }),
    headers: getHeaders()
  });

  const cocartApiKey = response.headers.get('X-CoCart-API') || null;
  // Set the cart key to the cookies if it's not set yet.
  if (
    !cookies.getItem(COOKIE_COCART_API_KEY) ||
    cookies.getItem(COOKIE_COCART_API_KEY) !== cocartApiKey
  ) {
    if (cocartApiKey) {
      cookies.setItem(COOKIE_COCART_API_KEY, cocartApiKey, getDateNextWeek());
    }
  }

  return await response.text();
}

export async function getCount() {
  const url = getCartUrl('count-items');

  const response = await fetch(url, {
    headers: getHeaders()
  });

  const count = await response.text();

  if (response.status !== 200 || response.ok === false) {
    const allCookies = document?.cookie;
    console.error(
      `[Cart error - cartApi, getCount()] message: ${count}, cookies: ${allCookies}`
    );
  }

  if (parseInt(count) === 0) {
    // If the response is 0, we can remove the cocart api key from the cookies.
    cookies.removeItem(COOKIE_COCART_API_KEY);
  }

  return count;
}

export async function clearCart() {
  const url = getCartUrl('clear');

  const response = await fetch(url, {
    method: 'POST',
    headers: getHeaders()
  });

  if (response.ok) {
    // If the response is ok, we can remove the cocart api key from the cookies.
    cookies.removeItem(COOKIE_COCART_API_KEY);
  }
}

export async function setQuantity(item, quantity) {
  const qty = parseInt(quantity, 10);
  if (isNaN(qty) || qty <= 0) {
    throw new Error('Invalid quantity');
  }

  const url = getCartUrl('item');
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      cart_item_key: item.key,
      quantity
    }),
    headers: getHeaders()
  });
  return await response.text();
}

export async function getShippingMethods() {
  // Set country to Finland by default before fething shipping methods.
  // @TODO: Perhaps use users actual location in the furure?
  const url = getCartUrl('calculate/shipping');
  await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      country: 'FI'
    }),
    headers: getHeaders()
  });

  const url2 = getCartUrl('shipping-methods');
  const response = await fetch(url2);
  return await response.json();
}

export async function setShippingMethod(key) {
  const url = getCartUrl('shipping-methods');
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      key: key
    }),
    headers: getHeaders()
  });
  return await response.text();
}

export async function removeItem(item) {
  const url = getCartUrl('item');
  const response = await fetch(url, {
    method: 'DELETE',
    body: JSON.stringify({
      cart_item_key: item.key
    }),
    headers: getHeaders()
  });
  return await response.text();
}

export async function getProductById(id) {
  const response = await fetch(
    `${config.app.apiProxyHost}/woocommerce/v3/products/${id}`
  );
  return await response.json();
}

export async function getFullCart() {
  const cocartParams = getCartUrlParams();

  const response = await fetch(
    `${config.app.apiProxyHost}/woocommerce/v2/full-cart${cocartParams}`
  );

  return await response.json();
}

export async function applyCoupon(coupon) {
  const url = getCartUrl('coupon');
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      coupon: coupon
    }),
    headers: getHeaders()
  });
  return await response.text();
}

export async function getCartCoupon() {
  const url = getCartUrl('coupon');
  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders()
  });

  const cupons = await response.json();

  return await cupons;
}

export default {
  addToCart,
  clearCart,
  getCount,
  setQuantity,
  getShippingMethods,
  setShippingMethod,
  removeItem,
  getProductById,
  getFullCart,
  applyCoupon,
  getCartCoupon
};
