/**
 * This is a fragment of the SEO model.
 */

import gql from 'graphql-tag';

const FRAGMENT_SEO = gql`
  fragment SeoModel on ContentNode {
    seo {
      fullHead
      metaDesc
      title
      canonical
    }
  }
`;

export default FRAGMENT_SEO;
