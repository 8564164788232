import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CaptionedImage from '@/components/Images/CaptionedImage';
import Loader from '@/components/Loader/Loader';

// Styled component for ImageSetContainer
const ImageSetContainer = styled.div`
  margin-bottom: 60px;
  ${(props) => props.theme.media.tablet`
    margin-bottom: 48px;
  `};
`;

// Styled component for ImageContainer
const ImageContainer = styled.div`
  max-width: 1020px;
  width: 95%;
  margin: 0 auto;
  .caption-image {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .caption-image__image {
    height: auto !important;
    width: inherit !important;
    max-width: 100% !important;
  }
`;

// Lazy-loaded SlickSlider component
const SlickSlider = lazy(
  () =>
    import(
      /* webpackChunkName: "SlickSlider" */
      '@/components/SlickSlider/SlickSlider'
    )
);

/**
 * React component for displaying a set of images.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.images - Array of image objects.
 * @returns {JSX.Element} React component.
 */
const ImageSet = ({ images }) => {
  if (!Array.isArray(images)) {
    return null;
  }

  return (
    <ImageSetContainer>
      {images.length > 1 ? (
        // Lazy-load SlickSlider component
        <Suspense fallback={<Loader visible={true} />}>
          <SlickSlider images={images} />
        </Suspense>
      ) : (
        <ImageContainer>
          <CaptionedImage image={images[0]} />
        </ImageContainer>
      )}
    </ImageSetContainer>
  );
};

ImageSet.propTypes = {
  images: PropTypes.array
};

export default ImageSet;
