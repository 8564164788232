import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import facet from './Facet';

class TextFacetBase extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    applyFilter: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { value: props.initialValue || '' };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue || '' });
    }
  }
  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.setFilter(event, true);
    }
  }
  setFilter(event, search = false) {
    const value = event.target.value;
    this.props.applyFilter(value, search);
  }

  render() {
    return (
      <input
        type="text"
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={(e) => this.setState({ value: e.target.value })}
        onBlur={(e) => this.setFilter(e)}
        onKeyPress={(e) => this.onKeyPress(e)}
      />
    );
  }
}

class TextFacet extends Component {
  static propTypes = {
    param: PropTypes.string,
    searchPage: PropTypes.string,
    placeholder: PropTypes.string,
    intl: PropTypes.any.isRequired
  };
  static defaultProps = {
    param: 'text',
    placeholder: 'page.search.placeholder'
  };

  constructor(props) {
    super(props);
    const { param, searchPage } = this.props;
    const pageProps = searchPage ? `/fi${searchPage}` : { currentPage: true };
    // NOTE Facet component is constructed here thus 'param' and 'searchPage' props cannot be dynamic
    // We don't want to construct this component everytime filter values change, could cause infinite loop
    this.facetComponent = facet(param, pageProps)(TextFacetBase);
  }

  render() {
    const { placeholder, intl } = this.props;
    const placeholderText = intl.formatMessage({ id: placeholder });
    const TextFacetComponent = this.facetComponent;

    return <TextFacetComponent placeholder={placeholderText} />;
  }
}

export default injectIntl(TextFacet);
