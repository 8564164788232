export default function register({ localeCode }) {
  if ('serviceWorker' in navigator) {
    const script = document.currentScript;
    const bundleName = script.src.substring(script.src.lastIndexOf('/') + 1);

    window.addEventListener('load', () => {
      const SERVICE_WORKER = `/service-worker.js?locale=${localeCode}&bundle=${bundleName}`;
      navigator.serviceWorker.register(SERVICE_WORKER).catch((err) => {
        console.error('Error during service worker registration:', err);
      });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
