import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { OutsideClickHandler } from '@/redandblue/components';
import { reset } from '../../../store/reducers/auth';
import Link from '../../../components/Link/Link';
import { gaCreateEvent, gaSetupLoginEvent } from '../../../utils/gaUtils';

const UserMenu = styled.div`
  color: #fff;
  position: relative;
  > a {
    margin-left: 20px;
    font-size: 11px;
    text-transform: uppercase;
  }
`;

const UserButton = styled.div`
  cursor: pointer;
`;
const UserDropdown = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  padding: 22px;
  display: grid;
  white-space: nowrap;
  row-gap: 12px;
  top: 22px;
  background-color: ${(props) => props.theme.colors.darkGrey};
`;
const Logout = styled.div`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.grey};
  }
`;

const UserName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class LoggedIn extends React.Component {
  state = {
    showUserDropdown: false
  };
  toggleUserDropdown(close) {
    this.setState({
      showUserDropdown: close
    });
  }
  onReset() {
    gaCreateEvent(gaSetupLoginEvent(this.props.user, false));
    this.props.reset();
    this.props.client.resetStore();
    window.location.href =
      window.location.origin +
      window.location.pathname +
      window.location.search;
  }
  render() {
    const { user, ...rest } = this.props;

    return (
      <OutsideClickHandler
        onOutsideClick={() => this.toggleUserDropdown(false)}
      >
        <UserMenu {...rest}>
          <UserButton
            onClick={() =>
              this.toggleUserDropdown(!this.state.showUserDropdown)
            }
            className="user-button"
          >
            <FontAwesomeIcon
              style={{ marginRight: 5 }}
              icon={['fal', 'user']}
            />
            <UserName>{user.email}</UserName>
            <i>
              <FontAwesomeIcon icon={['fal', 'angle-down']} />
            </i>
          </UserButton>
          {this.state.showUserDropdown && (
            <UserDropdown className="user-dropdown">
              <Link to="/käyttäjä" color="white">
                Profiili
              </Link>
              <Logout onClick={() => this.onReset()}>
                <FormattedMessage id="user.label.logout" />
                <FontAwesomeIcon
                  style={{ marginLeft: 5 }}
                  icon={['fal', 'sign-out']}
                />
              </Logout>
            </UserDropdown>
          )}
        </UserMenu>
      </OutsideClickHandler>
    );
  }
}

LoggedIn.propTypes = {
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired
};

export default connect(null, { reset })(withApollo(LoggedIn));
