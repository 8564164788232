function setUtm() {
  const utmData = getUtm();
  if (typeof window !== 'undefined') {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    utmData.utm =
      urlParams.get('utm') !== null ? urlParams.get('utm') : utmData.utm;
    utmData.utm_source =
      urlParams.get('utm_source') !== null
        ? urlParams.get('utm_source')
        : utmData.utm_source;
    utmData.utm_medium =
      urlParams.get('utm_medium') !== null
        ? urlParams.get('utm_medium')
        : utmData.utm_medium;
    utmData.utm_content =
      urlParams.get('utm_content') !== null
        ? urlParams.get('utm_content')
        : utmData.utm_content;
    utmData.utm_campaign =
      urlParams.get('utm_campaign') !== null
        ? urlParams.get('utm_campaign')
        : utmData.utm_campaign;
    utmData.utm_term =
      urlParams.get('utm_term') !== null
        ? urlParams.get('utm_term')
        : utmData.utm_term;

    utmData.utm_id =
      urlParams.get('utm_id') !== null
        ? urlParams.get('utm_id')
        : utmData.utm_id;

    utmData.sfmc_id =
      urlParams.get('sfmc_id') !== null
        ? urlParams.get('sfmc_id')
        : utmData.sfmc_id;
    utmData.sfmc_activity_id =
      urlParams.get('sfmc_activity_id') !== null
        ? urlParams.get('sfmc_activity_id')
        : utmData.sfmc_activity_id;

    utmData.gclide =
      urlParams.get('gclide') !== null
        ? urlParams.get('gclide')
        : utmData.gclide;
    utmData.gauserid =
      urlParams.get('gauserid') !== null
        ? urlParams.get('gauserid')
        : utmData.gauserid;
    utmData.gatrackid =
      urlParams.get('gatrackid') !== null
        ? urlParams.get('gatrackid')
        : utmData.gatrackid;
    utmData.gaclientid =
      urlParams.get('gaclientid') !== null
        ? urlParams.get('gaclientid')
        : utmData.gaclientid;

    sessionStorage.setItem('kuluttajaUTM', JSON.stringify(utmData));
  }
}

function getUtm() {
  if (typeof sessionStorage === 'undefined') {
    return {
      utm: null,
      utm_source: null,
      utm_medium: null,
      utm_content: null,
      utm_campaign: null,
      utm_term: null,

      utm_id: null,
      sfmc_id: null,
      sfmc_activity_id: null,
      gclide: null,
      gauserid: null,
      gatrackid: null,
      gaclientid: null
    };
  }

  const kuluttajaUTM =
    sessionStorage !== undefined
      ? sessionStorage.getItem('kuluttajaUTM')
      : null;
  return kuluttajaUTM !== null
    ? JSON.parse(kuluttajaUTM)
    : {
        utm: null,
        utm_source: null,
        utm_medium: null,
        utm_content: null,
        utm_campaign: null,
        utm_term: null,

        utm_id: null,
        sfmc_id: null,
        sfmc_activity_id: null,
        gclide: null,
        gauserid: null,
        gatrackid: null,
        gaclientid: null
      };
}

function updateUtm(utmData) {
  // get current utm data and update it with new data
  const currentUtmData = getUtm();
  const updatedUtmData = { ...currentUtmData, ...utmData };
  sessionStorage.setItem('kuluttajaUTM', JSON.stringify(updatedUtmData));
}

export { setUtm, getUtm, updateUtm };
