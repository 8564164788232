import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Logo from '@/containers/Header/Logo';
import CheckoutTabs from '@/components/Shop/CheckoutTabs';
import Container from '@/browser/Container';

const Header = styled(Logo)`
  padding-top: 50px;
  width: 100%;
  margin-bottom: 50px;
  ${(props) => props.theme.media.mobile`
    padding-top: 22px;
    margin-bottom: 22px;
    justify-content: center;
    display: flex;
  `};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 64%;
  grid-gap: 4%;
  align-items: start;
  ${(props) => props.theme.media.laptop`
    display: block;
  `};
  ${(props) => props.theme.media.mobile`
    display: grid;
    grid-template-columns: 28% 40% 28%;
    align-items: center;
    padding: 8px 0;
    border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
    border-style: solid;
    border-width: 1px 0;
    margin-bottom: 22px;
    h1 {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 0;
    }
  `};
`;

const Prefix = styled.span`
  display: none;
  ${(props) => props.theme.media.mobile`
    display: inline;
  `}
`;

/**
 * CheckoutHeader component for displaying the header in checkout flow.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} CheckoutHeader component.
 */
const CheckoutHeader = (props) => {
  const { location, intl } = props;
  const { formatMessage } = intl;

  const [tabs, setTabs] = useState([
    // Initial state for tabs
    {
      path: '/kauppa/ostoskori',
      orderNum: 1,
      title: formatMessage({ id: 'shop.cart.title' }),
      active: true,
      disabled: true
    },
    {
      path: '/kauppa/tilaajan-tiedot',
      orderNum: 2,
      title: formatMessage({ id: 'shop.orderDetails.title' }),
      active: false,
      disabled: true
    },
    {
      path: '/kauppa/maksu',
      orderNum: 3,
      title: formatMessage({ id: 'shop.payment.title' }),
      active: false,
      disabled: true
    },
    {
      path: '/kauppa/vahvistus',
      orderNum: 4,
      title: formatMessage({ id: 'shop.confirmation.title' }),
      active: false,
      disabled: true
    }
  ]);

  useEffect(() => {
    // Update tabs' state based on location pathname changes
    let activeIndex = 100;
    const updatedTabs = tabs.map((t, i) => {
      const isActive = location.pathname.includes(t.path);
      t.active = isActive;
      if (isActive) {
        activeIndex = i;
      }
      t.disabled = i + 1 > activeIndex;
      return t;
    });
    setTabs([...updatedTabs]); // Update state using a new array reference
  }, [location.pathname, formatMessage]);

  const getCurrentTab = () => {
    // Find the currently active tab
    return tabs.find((t) => t.active);
  };

  const currentTab = getCurrentTab();

  return (
    <Container>
      <Header />
      <Grid>
        <h1>
          <Prefix>{`${currentTab.orderNum}.`}</Prefix>
          {currentTab.title}
        </h1>
        <CheckoutTabs tabs={tabs} />
      </Grid>
    </Container>
  );
};

CheckoutHeader.propTypes = {
  location: PropTypes.object.isRequired,
  intl: PropTypes.any.isRequired
};

export default injectIntl(CheckoutHeader);
