import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

function SeoHelmet({ seo = false, fallbackTitle = '' }) {
  const { fullHead, title } = seo;

  return (
    <Helmet>
      <title>{title || fallbackTitle}</title>
      {fullHead && parse(fullHead)}
    </Helmet>
  );
}

SeoHelmet.propTypes = {
  seo: PropTypes.object,
  fallbackTitle: PropTypes.string
};

export default SeoHelmet;
