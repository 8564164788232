import React from 'react';
import styled from 'styled-components';
import Container from '@/browser/Container';
import PropTypes from 'prop-types';
import LoadmorePosts from '@/components/GraphQL/LoadmorePosts';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import QUERY_GET_PAGINATED_POSTS from '@/graphql/wordpress/posts/queryGetPaginatedPosts';

const SectionWrapper = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;

/**
 * ArticleList component for displaying a list of articles.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - Title of the article list.
 * @param {number} props.limit - Limit of articles to display.
 * @param {Object} props.category - The category object.
 * @param {string} props.previousSection - Previous section name.
 * @returns {JSX.Element} - Rendered ArticleList component.
 */
const ArticleList = (props) => {
  const { title, category, limit } = props;

  if (!category || !category.id) {
    return null;
  }

  const termIdString = category.categoryId.toString();
  const first = limit;
  const variables = {
    first,
    last: null,
    after: null,
    before: null,
    termIdString,
    taxonomy: 'CATEGORY'
  };

  // Function to update the query with the new results
  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return fetchMoreResult.posts.nodes.length
      ? fetchMoreResult
      : previousResult;
  };

  return (
    <SectionWrapper
      $afterHero={props.previousSection === 'Bodybuilder_HeroUnit'}
    >
      <Container>
        <h2>{title}</h2>
        <QueryHandler
          query={QUERY_GET_PAGINATED_POSTS}
          variables={variables}
          loadmore
          fetchPolicy="cache-first"
          nextFetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ posts }, fetchMore, loading) => (
            <LoadmorePosts
              nodes={posts.nodes}
              refetchState={''}
              hasNextPage={posts.pageInfo.hasNextPage}
              loading={loading}
              fetchMoreMethod={() => {
                fetchMore({
                  variables: {
                    first,
                    after: posts.pageInfo.endCursor || null,
                    last: null,
                    before: null,
                    termIdString
                  },
                  updateQuery
                });
              }}
            />
          )}
        </QueryHandler>
      </Container>
    </SectionWrapper>
  );
};

ArticleList.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  category: PropTypes.object,
  previousSection: PropTypes.string
};

ArticleList.defaultProps = {
  limit: 9,
  category: {}
};

export default ArticleList;
