import React from 'react';
import styled from 'styled-components';
import Content from '@/browser/Content';
import { getMetaValue } from '@/containers/Test/product-review';

const ContentBox = styled.div`
  margin: 32px;
  padding: 46px 32px;
  background-color: ${(props) => props.theme.colors.lightBlueGrey};
  max-width: 33vw;
  ${(props) => props.theme.media.tablet`
    max-width: 100%;
    margin: 8px;
  `}
`;
const Model = styled.h2`
  margin-bottom: 8px;
`;
const Manu = styled.p`
  color: ${(props) => props.theme.colors.gunmetal};
  font-weight: normal;
`;

const ProductContent = (props) => {
  const { product } = props;
  if (!product || !product.content) {
    return null;
  }
  return (
    <div>
      <ContentBox>
        <Manu>{getMetaValue('Merkki', product)}</Manu>
        <Model>{getMetaValue('Malli', product)}</Model>
        <Content html={product.content} />
      </ContentBox>
    </div>
  );
};

export default ProductContent;
