import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '@/browser/Container';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ProductReviewItemThumb from '@/containers/Test/SingleTest/ProductReviewItemThumb';
import { getSelectedIDs, setSelectedIDs } from '@/containers/Test/test-utils';
import { NormalButton } from '@/components/Buttons/Button';

const ProductComparison = styled.div`
  position: fixed;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #424242;
  padding: 1em;
  z-index: 999;
`;

const CompareContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
`;

const CompareCol = styled(TransitionGroup)`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  ${(props) => props.theme.media.mobile`
    display: none;
  `};
`;
const CompareMobile = styled.div`
  display: none;
  color: #fff;
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
`;
const CompareMobileTop = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  padding-bottom: 16px;
  button {
    height: inherit;
  }
`;
const CompareMobileBottom = styled.div`
  padding: 16px 0;
  button {
    width: 100%;
  }
`;

const CompareControls = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  &:before {
    background: linear-gradient(to right, #42424200 0%, #424242 100%);
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: -45px;
    height: 90px;
    width: 45px;
  }
  ${(props) => props.theme.media.mobile`
    display: none;
  `};
`;

class ProductComparisonBar extends Component {
  static propTypes = {
    selected: PropTypes.array,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };
  handleReset = () => {
    setSelectedIDs(null, this.props);
  };

  handleOpen = () => {
    const { history, location } = this.props;
    const path = location.pathname.includes('/product')
      ? location.pathname.replace('/product', '/compare')
      : location.pathname + '/compare';
    history.push(path + location.search);
  };
  render() {
    const { reviews, accessible } = this.props;
    if (!reviews.length) {
      return null;
    }

    // Parse the selected reviews from the URL
    const selected = getSelectedIDs(this.props);

    const selectedReviews = accessible
      ? reviews.filter((r) => selected.includes(r.product_reviewId))
      : selected.map((r) => reviews.find((ri) => ri.product_reviewId === r));

    if (!selected.length) {
      return null;
    }

    return (
      <ProductComparison>
        <CompareContainer>
          <CompareCol>
            {selectedReviews.map((review) => (
              <CSSTransition key={review.id} timeout={500} classNames="zoom">
                <ProductReviewItemThumb review={review} />
              </CSSTransition>
            ))}
          </CompareCol>

          <CompareControls>
            <NormalButton
              onClick={this.handleReset}
              $buttonstyle="plain"
              $txtcolor="white"
            >
              <FormattedMessage id="search.facet.reset" />
            </NormalButton>
            <NormalButton
              onClick={this.handleOpen}
              $color="kuluttaja2021.tummaPetroli[20]"
              $txtcolor="kuluttaja2021.harmaa.full"
            >
              <FormattedMessage id="search.comparison.compare" />
            </NormalButton>
          </CompareControls>
        </CompareContainer>
        <CompareMobile>
          <CompareMobileTop>
            <div>
              {`${selected.length} / 5`}&nbsp;
              <FormattedMessage id="search.comparison.itemsInComparison" />
            </div>
            <NormalButton
              onClick={this.handleReset}
              $buttonstyle="plain"
              $txtcolor="white"
            >
              <FormattedMessage id="search.facet.reset" />
            </NormalButton>
          </CompareMobileTop>
          <CompareMobileBottom>
            <NormalButton
              onClick={this.handleOpen}
              $color="kuluttaja2021.tummaPetroli[20]"
              $txtcolor="kuluttaja2021.harmaa.full"
            >
              <FormattedMessage id="search.comparison.compare" />
            </NormalButton>
          </CompareMobileBottom>
        </CompareMobile>
      </ProductComparison>
    );
  }
}

export default withRouter(ProductComparisonBar);
