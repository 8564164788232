import React from 'react';
import styled from 'styled-components';
import _get from 'lodash.get';
import FormattedDate from './FormattedDate';
import Link from '@/components/Link/Link';

/**
 * Styled component for the meta box containing post date and category information.
 */
const MetaBox = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: ${(props) => props.theme.colors.grey};
  font-weight: 900;
  display: flex;
  flex-wrap: wrap;

  a {
    color: ${(props) => props.theme.colors.grey};
    display: inline-block;
  }

  span {
    margin-right: 10px;
  }
`;

/**
 * PostDate component displaying the formatted date and category information.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.date - Post date.
 * @param {Object} props.categories - Post categories.
 * @returns {JSX.Element} - React component.
 */
const PostDate = ({ date, categories, ...rest }) => {
  const childCategory = _get(categories, 'nodes[0].children.nodes[0]', null);
  const parentCategory = _get(categories, 'nodes[0]', null);
  const productCategory = _get(categories, '[0]', null);
  const category = childCategory || parentCategory || productCategory;
  const catPage = _get(category, 'category_page', null);

  return (
    <MetaBox {...rest}>
      {date && <FormattedDate date={date} />}
      {catPage && category.name !== 'Uncategorized' && (
        <Link to={catPage.uri}>{category.name}</Link>
      )}
      {!catPage && category && category.name !== 'Uncategorized' && (
        <span>{category.name}</span>
      )}
    </MetaBox>
  );
};

export default PostDate;
