import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash.get';
import ExclIcon from '@/icons/exclamationCircleSolid.svg';
import CloseButton from '@/components/Buttons/CloseButton';
import config from '@/config';
import he from 'he';
import ErrorBar from '@/components/ErrorBar';

const StyledPre = styled.pre`
  border: 1px dashed #000;
  padding: 24px;
  font-size: 12px;
  -moz-tab-size: 2;
  tab-size: 2;
`;

const ErrorText = styled.span`
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
    text-decoration: underline;
  }
`;

const CloseError = styled(CloseButton)`
  position: absolute !important;
  top: 15px;
  right: 24px;
`;

const getErrorPolicy = () => {
  if (!process.env.NODE_ENV) return 'ignore';
  if (process.env.NODE_ENV === 'development') return 'all';
  return 'ignore';
};

const ErrorPolicy = getErrorPolicy();

const knownErrorMessages = [
  'graphQLErrors',
  'invalid_username',
  'invalid_email',
  'incorrect_password'
];

/**
 * ErrorHandler component for handling errors.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} ErrorHandler component.
 */
const ErrorHandler = ({ error }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  const getErrorKey = (error) => {
    if (Object.keys(error).includes('graphQLErrors')) {
      let message = _get(error, 'graphQLErrors[0].message', 'graphQLErrors');
      if (
        message.includes('Käyttäjätunnus on jo varattu') ||
        message.includes('Sähköpostiosoite on jo rekisteröity') ||
        message.includes('Käyttäjätunnus on jo rekisteröity')
      ) {
        return 'errors.username_taken';
      }

      if (knownErrorMessages.includes(message)) {
        return `errors.${message}`;
      }

      const decodedMessage = he.decode(message);

      return decodedMessage;
    }
    return null;
  };

  const close = () => {
    setVisible(false);
    window.location.replace(config.app.host);
  };

  if (!error || visible === false) {
    return null;
  }

  const errorKey = getErrorKey(error);
  return (
    <ErrorBar>
      <FormattedMessage id="errors.title" tagName="h4" />
      {errorKey && (
        <FormattedMessage tagName="div" id={errorKey}>
          {(html) => <ErrorText dangerouslySetInnerHTML={{ __html: html }} />}
        </FormattedMessage>
      )}
      {!errorKey &&
        ErrorPolicy === 'all' &&
        process.env.NODE_ENV === 'development' && (
          <StyledPre>{JSON.stringify(error, null, '\t')}</StyledPre>
        )}
      <CloseError onClick={close} type="button" />
    </ErrorBar>
  );
};

ErrorHandler.propTypes = {
  error: PropTypes.object
};

export default ErrorHandler;
export { ErrorPolicy };
