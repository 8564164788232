import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import Loader from '@/components/Loader/Loader';
import ErrorHandler, {
  ErrorPolicy
} from '@/components/ErrorHandler/ErrorHandler';

// Utility function to filter out unknown props
const filterProps = (props) => {
  const { reset, ...allowedProps } = props;
  return allowedProps;
};

/**
 * A wrapper for Apollo Query component to handle loading and errors in a generic way.
 * @param {Object} props - Component properties.
 * @param {Object} props.query - The GraphQL query.
 * @param {Object} [props.variables] - The variables to be used in the GraphQL query.
 * @param {Function} [props.children] - The render function for rendering components based on the query result.
 * @param {boolean} [props.small_loader] - Indicates whether to use a small loader.
 * @param {boolean} [props.hide_loader] - Indicates whether to hide the loader.
 * @param {boolean} [props.loadmore] - Indicates whether it's a loadmore scenario.
 * @param {string} [props.fetchPolicy] - The fetch policy for the query.
 * @returns {JSX.Element} - The rendered component.
 */
const QueryHandler = ({
  query,
  variables,
  children,
  small_loader,
  hide_loader,
  loadmore,
  fetchPolicy,
  ...rest
}) => {
  // Filter out unwanted props
  const filteredProps = filterProps(rest);
  /**
   * Checks if the data is not undefined, null, and has content.
   * @param {*} data - The data to be checked.
   * @returns {boolean} - True if the data is not undefined, null, and has content; otherwise, false.
   */
  const hasData = (data) => {
    if (typeof data === 'undefined' || data === null) {
      return false;
    }
    return (
      (Array.isArray(data) && data.length > 0) || Object.keys(data).length > 0
    );
  };

  return (
    <Query
      query={query}
      variables={variables}
      errorPolicy={ErrorPolicy}
      fetchPolicy={fetchPolicy !== undefined ? fetchPolicy : 'cache-first'}
      {...filteredProps}
    >
      {({ loading, error, data, fetchMore }) =>
        loadmore ? (
          <>
            {error && <ErrorHandler error={error} />}
            {hasData(data) && children(data, fetchMore, loading)}
            {!hide_loader && <Loader visible={loading} small={small_loader} />}
          </>
        ) : (
          <>
            {!hide_loader && <Loader visible={loading} small={small_loader} />}
            {error && <ErrorHandler error={error} />}
            {!loading && hasData(data) && children(data, fetchMore, loading)}
          </>
        )
      }
    </Query>
  );
};

// Prop types for QueryHandler component
QueryHandler.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  children: PropTypes.func,
  small_loader: PropTypes.bool,
  hide_loader: PropTypes.bool,
  loadmore: PropTypes.bool,
  fetchPolicy: PropTypes.string
};

// Default props for QueryHandler component
QueryHandler.defaultProps = {
  children: () => {}
};

// Export the QueryHandler component
export default QueryHandler;
