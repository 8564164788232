import React from 'react';
import PropTypes from 'prop-types';
import RenewSession from './RenewSession';
import LoggedIn from './LoggedIn';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { store } from '@/store/reducers/auth';
import { StyledButton } from '@/components/Buttons/Button';
import OrderButton from '@/components/Buttons/OrderButton';

const Usernav = styled.div`
  display: flex;
`;

const MobileOrderButton = styled(OrderButton)`
  padding-left: 16px;
  padding-right: 16px;
  min-width: inherit;
  letter-spacing: 0;
  width: 50%;
`;

const Button = styled(StyledButton)`
  padding-left: 16px;
  padding-right: 16px;
  min-width: inherit;
  letter-spacing: 0;
  width: 50%;
`;

const MobileUser = styled.div`
  .user-button {
    background-color: ${(props) => props.theme.colors.gunmetal};
    display: flex;
    align-items: center;
    padding-left: 18px;
    text-transform: uppercase;
    span {
      flex: auto;
    }
    i {
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.1);
      text-align: center;
      float: right;
      font-size: 36px;
      padding: 8px 14px;
      line-height: 1;
      .fa-angle-down {
        margin-right: 1px;
      }
    }
  }
  .user-dropdown {
    position: static;
  }
`;

class MobileUserNav extends React.Component {
  static propTypes = {
    auth: PropTypes.object.isRequired
  };
  render() {
    const { user, token } = this.props.auth;
    if (!token) {
      return (
        <Usernav>
          <MobileOrderButton />
          <Button
            $color="kuluttaja2021.harmaa.full"
            $buttonstyle="bordered"
            to="/käyttäjä/kirjaudu"
          >
            Kirjaudu
          </Button>
        </Usernav>
      );
    } else if (token && !user) {
      return <RenewSession />;
    }
    return (
      <MobileUser>
        <LoggedIn user={user} />
      </MobileUser>
    );
  }
}

export default connect((state) => ({ auth: state.auth }), { store })(
  MobileUserNav
);
