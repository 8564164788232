import styled from 'styled-components';
import FaAngleRight from '@/icons/FaAngleRight.svg';

const StyledLevel2NavNode = styled.div`
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 500;
  padding: 0 20px;
  text-transform: none;
  > a {
    padding: 17px 0 13px;
  }
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
      padding: 0;
      > a {
        padding: 0;
      }
    `};
  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.desktop`
      background-color: ${(props) =>
        props.$active
          ? props.theme.colors.kuluttaja2021.tummaPetroli[10]
          : 'inherit'};
        `} > a {
    transition: all 150ms;
    padding-left: ${(props) => (props.$active ? '22px' : '')};
    padding-right: ${(props) => (props.$active ? '0' : '22px')};
    position: relative;
    font-weight: 900;
    display: flex;
    align-items: center;
    &:before,
    &:after {
      position: absolute;
      top: 14px;
      content: ' ';
      background-image: url(${FaAngleRight});
      background-size: contain;
      background-repeat: no-repeat;
      height: 28px;
      width: 28px;
      font-size: 30px;
      transition: all 150ms;
      display: ${(props) => (props.$hasChildren ? 'block' : 'none')};
    }
    &:before {
      left: ${(props) => (props.$active ? '0' : '-10px')};
      opacity: ${(props) => (props.$active ? '1' : '0')};
      ${(props) =>
        !props.$tabletHeader &&
        props.theme.media.tablet`
          display: none;
        `};
    }
    &:after {
      right: ${(props) => (props.$active ? '-28px' : '-18px')};
      opacity: ${(props) => (props.$active ? '0' : '1')};
      ${(props) =>
        !props.$tabletHeader &&
        props.theme.media.tablet`
          opacity: 1;
          right: 1px;
          top: 0;
          background-color: ${
            props.$active
              ? props.theme.colors.kuluttaja2021.tummaPetroli[40]
              : props.theme.colors.kuluttaja2021.tummaPetroli[5]
          };
          background-size: 50% 50%;
          background-position: center center;
          transition: none;
          transform: ${props.active ? 'rotate(270deg)' : 'rotate(90deg)'};
          height: 58px;
          width: 59px;
        `};
    }
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`
        display: flex;
        height: 59px;
        border-color: ${props.theme.colors.white};
        border-style: solid;
        border-width: 0 1px 1px 1px;
        padding: 0 64px 0 16px;
        line-height: 1.1;
      `};
  }
`;

const Level2NavNode = ({
  children,
  active,
  size,
  tabletHeader,
  hasChildren,
  ...rest
}) => {
  return (
    <StyledLevel2NavNode
      $tabletHeader={tabletHeader}
      $active={active}
      $hasChildren={hasChildren}
      {...rest}
    >
      {children}
    </StyledLevel2NavNode>
  );
};

export default Level2NavNode;
