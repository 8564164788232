import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import {
  Label,
  CheckRadioWrapper,
  CheckRadioRow,
  CheckRadioCol
} from '@/components/Form/FormStyler';
import CheckRadio from '@/components/Form/CheckRadio';
import facet from './Facet';

const RadioLabel = styled(Label)`
  white-space: nowrap;
`;

class RadioFacet extends Component {
  static propTypes = {
    attribute: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    displayLabel: PropTypes.bool,
    initialValue: PropTypes.string,
    defaultValue: PropTypes.string,
    column: PropTypes.object,
    values: PropTypes.array.isRequired,
    labels: PropTypes.array
  };
  static defaultProps = {
    column: {},
    displayLabel: true
  };
  constructor(props) {
    super(props);
    this.state = { value: props.initialValue || props.defaultValue };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ value: this.props.initialValue });
    }
  }
  setFilter(event) {
    const { checked } = event.target;
    const value = checked ? event.target.value : null;

    this.setState({ value });
    this.props.applyFilter(value, true);
  }
  render() {
    const { attribute, column, displayLabel, values, labels, name } =
      this.props;

    return (
      <CheckRadioWrapper>
        {displayLabel && <RadioLabel>{attribute}: </RadioLabel>}
        <CheckRadioRow>
          {values.map((value, index) => (
            <CheckRadioCol key={index}>
              <CheckRadio
                id={`checkbox-facet-${attribute}-${value}`}
                type="radio"
                checked={this.state.value === value}
                onChange={(e) => this.setFilter(e)}
                name={name}
                value={value}
              />
              <RadioLabel htmlFor={`checkbox-facet-${attribute}-${value}`}>
                {labels ? labels[index] : `${value} ${column && column.suffix}`}
              </RadioLabel>
            </CheckRadioCol>
          ))}
        </CheckRadioRow>
      </CheckRadioWrapper>
    );
  }
}

const SEARCH_ROOT = { currentPage: true };
export default facet(null, SEARCH_ROOT)(RadioFacet);
