/**
 * This is a fragment of the "BodyBuilder" Page List block.
 */

import gql from 'graphql-tag';

const FRAGMENT_PAGE_LIST = gql`
  fragment PageList on Bodybuilder_PageList {
    title
    category {
      id
      categoryId
    }
  }
`;

export default FRAGMENT_PAGE_LIST;
