// TODO: this can be removed when we don't need to clean the old default cocart cookie

import React, { useEffect } from 'react';
import cookies from '@/utils/cookies';
import { COOKIE_COCART_DEFAULT_SESSION } from '@/configs/cocart';

const RemoveCoCartCookie = () => {
  useEffect(() => {
    const firstVisit = localStorage.getItem('sessionFirstVisit');

    if (!firstVisit) {
      // If it's the user's first visit
      localStorage.setItem('sessionFirstVisit', 'false'); // Set firstVisit in localStorage
      cookies.removeItem(COOKIE_COCART_DEFAULT_SESSION); // Remove CoCart cookie
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  return null; // This component doesn't render anything
};

export default RemoveCoCartCookie;
