import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setMembership } from '@/store/reducers/membership';
import { withRouter } from 'react-router-dom';
import CartAPI from '@/utils/membershipApi';

/**
 * UserMemberships React container.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.auth - Authentication data.
 * @param {Function} props.setMembership - Redux action to set memberships.
 * @param {Object} props.location - Location data from React Router.
 * @returns {null} - This component doesn't render anything.
 */
const UserMembershipsContainer = ({ auth, setMembership, location }) => {
  // State variables to manage user data and memberships
  const [user, setUser] = useState(auth?.user?.userId || null);
  const [userMemberships, setUserMemberships] = useState([]);
  const [prevUserMemberships, setPrevUserMemberships] = useState([]);
  const [prevLocation, setPrevLocation] = useState(location?.pathname || '');

  /**
   * Function to update the user's memberships.
   */
  const updateMemberships = () => {
    if (auth && auth.user) {
      CartAPI.getUserMembershipPlans(auth.user.userId).then(
        (membershipPlans) => {
          // Extract active membership plan IDs
          if (Array.isArray(membershipPlans)) {
            const activeMembershipIds = membershipPlans.map((membership) => {
              if (membership.status === 'active') {
                return membership.plan_id;
              }
              return null; // Return null for inactive memberships
            });
            setUserMemberships(activeMembershipIds.filter(Boolean)); // Filter out null values
          }
        }
      );
    }
  };

  // useEffect hook to update memberships when auth data changes
  useEffect(() => {
    const userId = auth?.user?.userId || null;
    if (user !== userId) {
      updateMemberships();
      setUser(userId);
    }
  }, [auth]);

  // useEffect hook to update memberships when the location changes
  useEffect(() => {
    if (prevLocation !== location?.pathname) {
      updateMemberships();
      setPrevLocation(location?.pathname);
    }
  }, [location]);

  // useEffect hook to update Redux store when userMemberships change
  useEffect(() => {
    if (
      JSON.stringify(prevUserMemberships) !== JSON.stringify(userMemberships)
    ) {
      setMembership({ activeMemberships: userMemberships });
      setPrevUserMemberships(userMemberships);
    }
  }, [userMemberships]);

  // This component doesn't render anything, so return null
  return null;
};

// PropTypes to specify the expected props
UserMembershipsContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  setMembership: PropTypes.func.isRequired,
  location: PropTypes.object
};

// Connect the component to Redux and withRouter for routing-related props
export default connect((state) => ({ auth: state.auth }), { setMembership })(
  withRouter(UserMembershipsContainer)
);
