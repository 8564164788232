import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Money from '@/browser/Money';

const Prices = styled.div`
  & > :last-child {
    font-size: 1.2em;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  line-height: 1.5em;
  > *:nth-child(2) {
    text-align: right;
  }
`;

const cartTaxes = (taxes) => {
  let vats = [];
  Object.keys(taxes).forEach((key) => {
    let vatMessage = 'order.label.vat';
    if (key === '5') {
      vatMessage = 'order.label.vat14';
    }
    if (key === '6') {
      vatMessage = 'order.label.vat10';
    }
    vats.push({
      message: vatMessage,
      value: parseFloat(taxes[key]),
      key: key
    });
  });
  return vats;
};

const CartTotals = (props) => {
  const { cart, plain, hideTax, ...rest } = props;
  const { totals } = props.cart;
  const {
    cart_contents_taxes,
    shipping_tax = 0,
    shipping_total,
    shipping_taxes,
    discount_total,
    discount_tax,
    subtotal,
    subtotal_tax,
    total
  } = totals;

  // TODO Cart API is quite shit atm. It should have these prepared and in numeric format
  const shippingCost = parseFloat(shipping_total) + parseFloat(shipping_tax);
  const shippingCostTaxes = cartTaxes(shipping_taxes);
  const subTotal = parseFloat(subtotal) + parseFloat(subtotal_tax);
  const subTotalTaxes = cartTaxes(cart_contents_taxes);
  const finalTotal =
    parseFloat(total) + (plain && shippingCost ? -shippingCost : 0);
  const discount = discount_total
    ? parseFloat(discount_total) + parseFloat(discount_tax)
    : parseFloat(total) < subTotal + shippingCost
      ? Math.abs(parseFloat(total) - subTotal - shippingCost)
      : 0;

  return (
    <Prices {...rest}>
      {!plain && (
        <div>
          <Row className="total-row">
            <FormattedMessage id="order.label.subtotal" />
            <span>
              <Money value={subTotal} />
            </span>
          </Row>
          {!plain && discount > 0 && (
            <Row>
              <FormattedMessage id="order.label.discount" />
              <span>
                <Money value={-discount} />
              </span>
            </Row>
          )}
          {subTotalTaxes.map((vat) => (
            <Row className="total-row" key={vat.key}>
              <FormattedMessage id={vat.message} />
              <span>
                <Money value={finalTotal === 0 ? 0 : vat.value} />
              </span>
            </Row>
          ))}
          <Row>
            <FormattedMessage id="order.label.shippingCost" />
            <span>
              <Money value={shippingCost} />
            </span>
          </Row>
          {shippingCostTaxes.map((vat) => (
            <Row className="total-row" key={vat.key}>
              <FormattedMessage id={vat.message} />
              <span>
                <Money value={finalTotal === 0 ? 0 : vat.value} />
              </span>
            </Row>
          ))}
        </div>
      )}
      {plain && discount > 0 && (
        <Row>
          <FormattedMessage id="order.label.discount" />
          <span>
            <strong>
              <Money value={-discount} />
            </strong>
          </span>
        </Row>
      )}
      <Row className="total-row total-row-final">
        <FormattedMessage id="order.label.total" />
        <span>
          <strong>
            <Money value={finalTotal} />
          </strong>
        </span>
      </Row>
      {plain && !hideTax && (
        <div className="total-taxes">
          <FormattedMessage id="order.label.vat" />
        </div>
      )}
    </Prices>
  );
};

CartTotals.propsTypes = {
  cart: PropTypes.object.isRequired,
  hideTax: PropTypes.bool,
  plain: PropTypes.bool
};
CartTotals.defaultProps = {
  hideTax: false,
  plain: false
};

export default CartTotals;
