import React from 'react';
import styled from 'styled-components';
import NavigationArticles from '@/components/Navigation/NavigationArticles';

const StyledLevel3Nav = styled.div`
  position: absolute;
  top: 0px;
  left: 300px;
  width: calc(100vw - 300px);
  height: 100%;
  padding-top: 28px;
  padding-left: 8%;
  padding-right: 8%;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[10]};

  ${(props) =>
    !props.$tabletHeader &&
    props.theme.media.tablet`
      position: static;
      width: 100%;
      min-height: inherit;
      left: inherit;
      right: 0;
      padding-top: 0;
      background-color: transparent;
      overflow: hidden;
      border-bottom: 1px solid ${props.theme.colors.kuluttaja2021.harmaa[20]};
  `};

  &.opensub-enter {
    ${(props) =>
      !props.$tabletHeader && props.theme.media.tablet`max-height: 0;`};
  }
  &.opensub-enter-active {
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media.tablet`max-height: 1000px;
    `}
    transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &.opensub-exit {
    ${(props) =>
      !props.$tabletHeader && props.theme.media.tablet`max-height: 1000px;`};
  }
  &.opensub-exit-active {
    ${(props) =>
      !props.$tabletHeader &&
      props.theme.media
        .tablet`max-height: 0;`} transition: all 250ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

const NavColumns = styled.ul`
  columns: 4;
  padding: 22px 0;
  ${(props) =>
    props.theme.media.laptop`
      columns: 3;
    `}
  ${(props) =>
    props.$tabletHeader &&
    props.theme.media.tablet`
      columns: 2;
    `}
  ${(props) =>
    props.theme.media.mobile`
        columns: 1;
        padding: 0;
      `}
`;

const Level3Nav = ({
  children,
  size,
  useColumns,
  category,
  title,
  tabletHeader,
  headerHeight,
  ...rest
}) => {
  if (!useColumns) {
    return (
      <StyledLevel3Nav $tabletHeader={tabletHeader} {...rest}>
        {children}
      </StyledLevel3Nav>
    );
  }
  return (
    <StyledLevel3Nav $tabletHeader={tabletHeader} {...rest}>
      {category && (
        <NavigationArticles
          category={category}
          title={title}
          tabletHeader={tabletHeader}
        />
      )}
      <NavColumns>{children}</NavColumns>
    </StyledLevel3Nav>
  );
};

export default Level3Nav;
