import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Content from '@/browser/Content';
import Container from '@/browser/Container';
import { ActionButton } from '@/components/Buttons/Button';
import LehtiluukkuButton from '@/components/Buttons/LehtiluukkuButton';
import _isEmpty from 'lodash.isempty';
import WpImage from '@/components/Images/WpImage';

/**
 * Styled component for SectionWrapper.
 */
const SectionWrapper = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.$background_color !== 'rgba(0,0,0,0)'
      ? props.$background_color
      : props.theme.colors.kuluttaja2021.harmaa[80]};
  background-image: url(${(props) => props.$background_image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: ${(props) => props.$title_color};
  padding-bottom: 100px;
  margin-bottom: -100px;
  font-size: 20px;
  ${(props) => props.theme.media.tablet`
    padding-bottom: 0;
    margin-bottom: 0;
  `};
  ${(props) => props.theme.media.mobileSmall`
    background-image: none;
    font-size: 16px;
  `};
  p {
    margin: 0;
  }
  h1 {
    font-size: ${(props) => (props.$isFrontpage ? '72px' : '48px')};
    margin-bottom: 0;
    font-family: ${(props) => props.theme.fonts.secondary};
    ${(props) => props.theme.media.tablet`
      font-size: 54px;
    `};
    ${(props) => props.theme.media.mobileSmall`
      font-size: calc(28px + 2vw);
      overflow-wrap: break-word;
      hyphens: auto;
    `};
  }
`;

/**
 * Styled component for ContainerH.
 */
const ContainerH = styled(Container)`
  display: flex;
  align-items: center;
  align-items: stretch;
  ${(props) => props.theme.media.desktopLaptop`
    min-height: ${props.$hasImage ? '450px' : '0'};
  `};
`;

/**
 * Styled component for HeroColumn.
 */
const HeroColumn = styled.div`
  width: 50%;
  padding: ${(props) => (props.$isFrontpage ? '140px 0' : '80px 0')};
  display: grid;
  grid-template-columns: auto;
  row-gap: calc(2vw + 8px);
  ${(props) => props.theme.media.tabletOnly`
    width: 65%;
  `};
  ${(props) => props.theme.media.mobileSmall`
    width: 100%;
    padding: 48px 0;
  `};
  &:first-of-type {
    ${(props) => props.theme.media.desktopLaptop`
      align-self: center;
    `};
  }
`;

/**
 * Styled HeroButtons wrapper.
 */
const HeroButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding-right: 24px;

  ${(props) => props.theme.media.mobileSmall`
    flex-direction: column;
    gap: 16px;
    flex-wrap: nowrap;
    padding-right: 0;
  `};
`;

/**
 * Styled component for HeroButton.
 */
const HeroButton = styled(ActionButton)`
  ${(props) => props.theme.media.mobileSmall`
    width: 100%;
  `};
`;

const HeroLehtiluukkuButton = styled(LehtiluukkuButton)`
  ${(props) => props.theme.media.mobileSmall`
    width: 100%;
  `};
`;

/**
 * Styled component for CroppedImage.
 */
const CroppedImage = styled.div`
  width: 50%;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    object-fit: contain;
    object-position: center center;
  }
  ${(props) => props.theme.media.mobileSmall`
    display: none;
  `};
`;

/**
 * Styled component for MobileHeroImage.
 */
const MobileHeroImage = styled.div`
  display: none;
  ${(props) => props.theme.media.mobileSmall`
    display: block;
    min-height: 265px;
    height: 50vh;
    max-height: 300px;
    width: 100%;
    padding-top: ${(props) => (props.$cropped ? '30px' : '0')};
    img {
      object-fit: ${(props) => (props.$cropped ? 'contain' : 'cover')};
      object-position: ${(props) =>
        props.$cropped ? 'center center' : 'right center'};
      width: 100%;
      height: 100%;
    }
  `};
`;

/**
 * React component for displaying hero content.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title of the hero content.
 * @param {string} [props.title_color] - Color of the title.
 * @param {string} [props.content] - Content of the hero.
 * @param {Object} [props.action] - Action associated with the hero content.
 * @param {Object} [props.secondary_action] - Secondary action associated with the hero content.
 * @param {Object|boolean} [props.image] - Image object for the hero.
 * @param {string} [props.background_color] - Background color of the hero section.
 * @param {Object|boolean} [props.background_image] - Background image object for the hero section.
 * @param {string} [props.match] - Match object.
 * @param {number} [props.magazine_id] - Magazine ID.
 * @returns {JSX.Element} React component.
 */
const HeroUnit = ({
  title,
  title_color,
  content,
  action,
  secondary_action,
  image,
  background_color,
  background_image,
  lehtiluukku_issue_id,
  lehtiluukku_button_text,
  magazine_id,
  match
}) => {
  if (!title) {
    return null;
  }

  const mobileBgImage = background_image ? background_image : image;
  const firstButtonColor =
    background_color === '#033858'
      ? 'kuluttaja2021.tummaPetroli[60]'
      : 'kuluttaja2021.tummaPetroli.full';
  const isFrontpage = match.url === '/fi/';
  return (
    <SectionWrapper
      $background_image={background_image ? background_image.sourceUrl : null}
      $title_color={title_color}
      $background_color={background_color}
      $isFrontpage={isFrontpage}
    >
      {mobileBgImage && !_isEmpty(mobileBgImage) && (
        <MobileHeroImage $cropped={image ? true : false}>
          <WpImage image={mobileBgImage} />
        </MobileHeroImage>
      )}
      <ContainerH
        $isFrontpage={isFrontpage}
        $hasImage={background_image || image}
      >
        <HeroColumn $isFrontpage={isFrontpage}>
          <h1>{title}</h1>
          {content && <Content html={content} />}
          {(action || secondary_action || lehtiluukku_issue_id) && (
            <HeroButtonsWrapper>
              <HeroButton action={action} color={firstButtonColor} />
              <HeroButton
                action={secondary_action}
                color="white"
                txtcolor="white"
                buttonstyle="bordered"
              />
              {lehtiluukku_issue_id && (
                <HeroLehtiluukkuButton
                  issueId={lehtiluukku_issue_id}
                  text={lehtiluukku_button_text}
                  magazineId={magazine_id}
                />
              )}
            </HeroButtonsWrapper>
          )}
        </HeroColumn>
        {image && image.sourceUrl && (
          <CroppedImage>
            <WpImage image={image} />
          </CroppedImage>
        )}
      </ContainerH>
    </SectionWrapper>
  );
};

HeroUnit.propTypes = {
  title: PropTypes.string.isRequired,
  title_color: PropTypes.string,
  content: PropTypes.string,
  action: PropTypes.object,
  secondary_action: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  background_color: PropTypes.string,
  background_image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  match: PropTypes.object,
  magazineId: PropTypes.number
};

HeroUnit.defaultProps = {
  content: null,
  background_image: null,
  match: {
    url: ''
  }
};

export default HeroUnit;
