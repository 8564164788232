import React, { useState, useEffect } from 'react';

/**
 * React component that augments and manages the state of posts for a load-more functionality.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.posts - Array of posts.
 * @param {string} props.refetchState - State used to trigger refetch.
 * @param {Function} props.children - Render prop function to render children with augmented posts.
 * @returns {JSX.Element} React component.
 */
const LoadmoreAugmenter = ({ posts: initialPosts, refetchState, children }) => {
  // State to manage posts
  const [posts, setPosts] = useState(initialPosts);

  // Effect to handle refetchState changes
  useEffect(() => {
    // Reset posts if refetchState changes
    setPosts([]);
  }, [refetchState]);

  // Effect to handle changes in posts
  useEffect(() => {
    // Update posts if initialPosts changes
    setPosts((prevPosts) => removeDuplicates([...prevPosts, ...initialPosts]));
  }, [initialPosts]);

  /**
   * Removes duplicate posts from an array.
   *
   * @param {Array} array - Array of posts.
   * @returns {Array} Array with duplicate posts removed.
   */
  const removeDuplicates = (array) =>
    array.filter(
      (post, index, a) => a.findIndex((p) => p.id === post.id) === index
    );

  return children(posts);
};

export default LoadmoreAugmenter;
