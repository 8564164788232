import React from 'react';
import styled, { css } from 'styled-components';
import Link from '@/components/Link/Link';
import _get from 'lodash.get';

const arrowColor = (props) =>
  props.$color
    ? _get(props.theme.colors, props.$color)
    : props.theme.colors.kuluttaja2021.tummaPetroli.full;
const buttonTxtColor = (props) =>
  props.theme.colors[props.$txtcolor || 'darkGrey'];

const buttonStyles = css`
  text-decoration: none;
  background-color: transparent;
  color: ${buttonTxtColor};
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1;
  font-size: ${(props) => (props.$size === 'small' ? '11px' : '14px')};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: 800;
  padding: 0;
  text-align: center;
  transition: all 350ms;
  box-shadow: none;
  .svg-inline--fa {
    margin: 0 6px;
    position: relative;
    transition: all 100ms;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  &:hover {
    color: ${buttonTxtColor};
    transition: all 50ms;
    .svg-inline--fa.left {
      left: -0.5em;
    }
    .svg-inline--fa.right {
      right: -0.5em;
    }
  }
`;

const StyledButton = styled.button`
  ${buttonStyles} border: none;
  padding-top: 0;
`;
const StyledLink = styled(Link)`
  ${buttonStyles};
`;

const ArrowPath = styled.path`
  fill: ${arrowColor};
`;

const Arrow = (props) => {
  return (
    <svg
      aria-hidden="true"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={`svg-inline--fa ${
        (props.left && 'left') || (props.right && 'right')
      }`}
    >
      {props.left && (
        <ArrowPath
          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
          $color={props.color}
        />
      )}
      {props.right && (
        <ArrowPath
          d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
          $color={props.color}
        />
      )}
    </svg>
  );
};

const BackButton = ({ size, color, children, txtcolor, ...rest }) => {
  return (
    <StyledButton $size={size} $color={color} $txtcolor={txtcolor} {...rest}>
      <Arrow left color={color} />
      {children}
    </StyledButton>
  );
};

const ForwardButton = ({ size, color, children, txtcolor, ...rest }) => {
  return (
    <StyledButton $size={size} $color={color} $txtcolor={txtcolor} {...rest}>
      {children}
      <Arrow right color={color} />
    </StyledButton>
  );
};

const BackLink = ({ size, color, children, txtcolor, ...rest }) => {
  return (
    <StyledLink $size={size} $color={color} $txtcolor={txtcolor} {...rest}>
      <Arrow left color={color} />
      {children}
    </StyledLink>
  );
};

const ForwardLink = ({ size, color, children, txtcolor, ...rest }) => {
  return (
    <StyledLink $size={size} $color={color} $txtcolor={txtcolor} {...rest}>
      {children}
      <Arrow right color={color} />
    </StyledLink>
  );
};

export { BackButton, ForwardButton, BackLink, ForwardLink, Arrow };
