/**
 * This is the query to authenticated user (viewer).
 * Note, this query should have the same data as the MUTATION_DO_LOGIN query ../viewer/mutationDoLogin.js.
 */

import gql from 'graphql-tag';
import FRAGMENT_USER_LOGIN_MODEL from '@/graphql/wordpress/_fragments/fragmentUserLoginModel';

const QUERY_GET_VIEWER = gql`
  ${FRAGMENT_USER_LOGIN_MODEL}
  query getViewer {
    viewer {
      ...UserLoginModel
    }
  }
`;

export default QUERY_GET_VIEWER;
