import config from '@/config';
import { PARTNER_REF } from '@/configs/prisjakt';

// Define the base URL for Prisjakt API.
const BASE_URL = `${config.app.apiProxyHost}/prisjakt`;
const MAX_ITEMS_PER_REQUEST = 20;

/**
 * Generate default URL parameters for Prisjakt API requests.
 * @returns {string} Default URL parameters.
 */
const getDefaultUrlParams = () => {
  return `ref=${PARTNER_REF}&market=fi`;
};

/**
 * Retrieve product offers data for multiple product IDs from Prisjakt.
 * @param {number[]} productIds - An array of product IDs.
 * @returns {Promise<Object|null>} A promise that resolves to product offer data or null.
 */
export async function getOffersForIDs(productIds) {
  // Prisjakt allows requesting multiple product offer data at once by separating the IDs with a comma.
  // clear empty ids
  const idArray = productIds.filter((id) => {
    return id === 0 || (id && !isNaN(id));
  });

  const params = getDefaultUrlParams();

  // Prisjakt API has a limit of 20 items per request.
  const chunkedIdArray = [].concat.apply(
    [],
    idArray.map(function (elem, i) {
      return i % MAX_ITEMS_PER_REQUEST
        ? []
        : [idArray.slice(i, i + MAX_ITEMS_PER_REQUEST)];
    })
  );

  try {
    const responses = await Promise.all(
      chunkedIdArray.map(async (ids) => {
        const response = await fetch(
          `${BASE_URL}/products/${ids.join(',')}?${params}`
        );
        const data = await response.json();

        if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
          console.error('Error occurred on Prisjakt API:', data.error);
        }

        return Array.isArray(data) ? data : [];
      })
    );

    const productData = [].concat.apply([], responses);

    return { productData };
  } catch (err) {
    console.warn('Unable to fetch Prisjakt prices', err);
  }

  return null;
}

/**
 * Retrieve product data by product ID from Prisjakt data.
 * @param {string} productId - The product ID to retrieve data for.
 * @param {Object} prisjaktData - Prisjakt data containing product information.
 * @returns {Object|null} Product data or null if not found.
 */
export function getProductDataById(productId, prisjaktData) {
  if (!prisjaktData || !productId) {
    return null;
  }

  const { productData } = prisjaktData;
  const product = Array.isArray(productData)
    ? productData.find((product) => product.productId === productId)
    : null;

  return product;
}

/**
 * Get an array of sorted offer prices for a specific product.
 * @param {string} productId - The product ID.
 * @param {Object} prisjaktData - Prisjakt data containing product information.
 * @returns {number[]} An array of sorted offer prices or an empty array if no offers are available.
 */
export function getMinMaxPrice(productId, prisjaktData) {
  const product = getProductDataById(productId, prisjaktData);

  if (!product || !product.offers || product.offers.length === 0) {
    return [];
  }

  const offerPrices = product.offers.map((offer) => offer.price.value);
  offerPrices.sort((a, b) => a - b);

  return offerPrices;
}
