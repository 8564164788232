import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * A component for displaying content conditionally based on active memberships.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {ReactNode} props.children - The content to be displayed conditionally.
 * @param {Array} props.includedMemberships - Memberships that allow content visibility.
 * @param {Array} props.excludedMemberships - Memberships that restrict content visibility.
 * @param {boolean} props.initialIsVisible - The initial visibility state.
 * @param {Array} props.activeMemberships - The list of active memberships.
 * @returns {ReactNode|null} - The rendered content or null if not visible.
 */
const MembershipRestrictedContent = ({
  children,
  includedMemberships,
  excludedMemberships,
  initialIsVisible,
  activeMemberships
}) => {
  const [visibility, toggleVisibility] = useState(initialIsVisible);

  useEffect(() => {
    // Handle visibility based on included memberships
    if (includedMemberships.length > 0) {
      if (
        includedMemberships.length > 0 &&
        includedMemberships.some((membership) =>
          activeMemberships.includes(membership)
        )
      ) {
        toggleVisibility(true);
      } else {
        toggleVisibility(false);
      }
      return;
    }

    // Handle visibility based on excluded memberships
    if (
      excludedMemberships.length > 0 &&
      excludedMemberships.some((membership) =>
        activeMemberships.includes(membership)
      )
    ) {
      toggleVisibility(false);
    } else {
      toggleVisibility(true);
    }
  }, [activeMemberships]);

  if (visibility) {
    return <>{children}</>;
  }

  return null;
};

MembershipRestrictedContent.propTypes = {
  children: PropTypes.node,
  activeMemberships: PropTypes.array,
  includedMemberships: PropTypes.array,
  excludedMemberships: PropTypes.array,
  initialIsVisible: PropTypes.bool
};

MembershipRestrictedContent.defaultProps = {
  children: null,
  activeMemberships: [],
  includedMemberships: [],
  excludedMemberships: [],
  initialIsVisible: false
};

/**
 * Connects the component to the Redux store to access active memberships.
 */
export default connect(
  (state) => ({ activeMemberships: state.membership.activeMemberships }),
  null
)(MembershipRestrictedContent);
