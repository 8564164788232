import { onError } from '@apollo/client/link/error';

const loggingMiddleware = onError(
  ({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}, Operation: ${
            operation.operationName
          } with vars: ${JSON.stringify(operation.variables)}`
        )
      );
    }
    if (networkError) {
      const { operationName, variables } = operation;
      const vars = JSON.stringify(variables);
      const errorDesc = `Error on operation '${operationName}' with vars ${vars}`;
      console.error(`[Network error] ${errorDesc}`, networkError);
    }
  }
);

export default loggingMiddleware;
