import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from './Page';
import { urlCoupons } from '../utils/couponHandler';

class Home extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    cart: PropTypes.object
  };
  state = {
    urlRedirect: false
  };

  render() {
    const { match } = this.props;
    const pageSlug = match.params.slug || '/';

    // get url coupons
    if (typeof window !== 'undefined') {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const coupons = urlParams.get('coupon-code');
      const redirect = urlParams.get('sc-page');
      if (coupons && redirect) {
        const urlCoupon = Promise.resolve(urlCoupons());
        urlCoupon.then((redirectCart) => {
          if (redirectCart) {
            this.setState({
              urlRedirect: true
            });
          }
        });
      }
    }

    if (this.state.urlRedirect) {
      return <Redirect to={'/fi/kauppa/ostoskori'} />;
    }

    return (
      <>
        <Page slug={pageSlug} displayChildren />
      </>
    );
  }
}

export default connect((state) => ({ cart: state.cart }))(Home);
