import React from 'react';
import styled from 'styled-components';
import LoadmoreAugmenter from './LoadmoreAugmenter';
import LiftGrid from '@/components/LiftCard/LiftGrid';
import LiftCard from '@/components/LiftCard/LiftCard';
import { FormattedMessage } from 'react-intl';
import { NormalButton } from '@/components/Buttons/Button';

const LoadmoreContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
`;

/**
 * Represents a component that loads more posts and displays them in a grid.
 * @param {Object} props - Component properties.
 * @param {Array} props.nodes - The array of post nodes.
 * @param {Object} props.refetchState - The state for refetching.
 * @param {boolean} props.hasNextPage - Indicates whether there are more pages to load.
 * @param {Function} props.fetchMoreMethod - The method to fetch more posts.
 * @param {boolean} props.loading - Indicates whether the component is in a loading state.
 * @param {Function} props.children - The render function for custom rendering of nodes.
 * @returns {JSX.Element|null} - The rendered component.
 */
const LoadmorePosts = ({
  nodes,
  refetchState,
  hasNextPage,
  fetchMoreMethod,
  loading,
  children
}) => {
  // If nodes array is not valid or empty, return null
  if (!nodes.length) {
    return null;
  }

  return (
    <>
      {/* LoadmoreAugmenter to augment posts with additional functionality */}
      <LoadmoreAugmenter posts={nodes} refetchState={refetchState}>
        {(augmentedNodes) =>
          // Render children if provided, otherwise render LiftGrid with LiftCard components
          children ? (
            children(augmentedNodes)
          ) : (
            <LiftGrid>
              {augmentedNodes.map((node) => (
                <LiftCard
                  key={node.id}
                  title={node.title}
                  restricted={node.paywall.premium}
                  content={node.excerpt}
                  date={node.date}
                  image={node.featuredImage}
                  categories={node.categories}
                  link={node.uri}
                />
              ))}
            </LiftGrid>
          )
        }
      </LoadmoreAugmenter>

      {/* Render load more button if there are more pages to load and not in loading state */}
      {hasNextPage && !loading && (
        <LoadmoreContainer>
          <NormalButton onClick={() => fetchMoreMethod()}>
            <FormattedMessage id="bodybuilder.loadmore" />
          </NormalButton>
        </LoadmoreContainer>
      )}
    </>
  );
};

// Export the LoadmorePosts component
export default LoadmorePosts;
