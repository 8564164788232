import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { parse } from 'qs';
import Container from '@/browser/Container';
import BlackListEntry from '@/components/BlackList/BlackListEntry';
import BlackListSearch from '@/components/BlackList/BlackListSearch';

/**
 * Styled component for BlackListHeaderWrapper.
 */
const BlackListHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  margin-top: 2em;
  padding: 0 26px 12px;

  span {
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 11px;
    color: ${(props) => props.theme.colors.gunmetal};
    line-height: 13px;
    letter-spacing: 2.2px;
    font-weight: 700;
    text-transform: uppercase;
  }

  ${(props) => props.theme.media.tablet`
    display:none;
  `};
`;

/**
 * Styled component for BlackListEntriesWrapper.
 */
const BlackListEntriesWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  margin-bottom: 3em;
`;

/**
 * React component for displaying a black list.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Array} props.entries - Array of black list entries.
 * @param {Object} props.location - Router location object.
 * @returns {JSX.Element} React component.
 */
const BlackList = ({ entries, location }) => {
  const { search, locationFilter, industryFilter, multipleReviews } =
    location.search ? parse(location.search.substring(1)) : {};

  if (!Array.isArray(entries)) {
    return null;
  }

  const filteredEntries = entries.filter(
    (entry) =>
      ((multipleReviews &&
        Array.isArray(entry.reviews) &&
        entry.reviews.length > 1) ||
        !multipleReviews) &&
      (!search || entry.company.toLowerCase().includes(search.toLowerCase())) &&
      (!locationFilter || locationFilter.includes(entry.location)) &&
      (!industryFilter || industryFilter.includes(entry.industry)) &&
      entry
  );

  return (
    <>
      <BlackListSearch entries={entries} />
      <Container>
        <BlackListHeaderWrapper>
          <FormattedMessage id="blacklist.company" />
          <FormattedMessage id="blacklist.industry" />
          <FormattedMessage id="blacklist.location" />
          <FormattedMessage id="blacklist.reviewCount" />
        </BlackListHeaderWrapper>
        <BlackListEntriesWrapper id="BlackListEntries">
          {filteredEntries.map((entry) => (
            <BlackListEntry key={entry.id} {...entry} />
          ))}
        </BlackListEntriesWrapper>
      </Container>
    </>
  );
};

BlackList.propTypes = {
  entries: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(BlackList);
