import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Level1Nav from './navs/Level1Nav';
import Level2Nav from './navs/Level2Nav';
import Level3Nav from './navs/Level3Nav';
import Level4Nav from './navs/Level4Nav';
import Level5Nav from './navs/Level5Nav';

const Containers = [Level1Nav, Level2Nav, Level3Nav, Level4Nav, Level5Nav];

class NavigationStyler extends PureComponent {
  static propTypes = {
    depth: PropTypes.number.isRequired
  };
  state = {
    headerHeight: 180
  };
  componentDidMount() {
    if (document) {
      const breadcrumbs = document.getElementById('breadcrumbs');
      const header = document.getElementById('main-header');
      if (header) {
        const headerHeight = breadcrumbs
          ? header.offsetHeight - breadcrumbs.offsetHeight
          : header.offsetHeight;
        this.setState({ headerHeight: headerHeight });
      }
    }
  }
  render() {
    const { depth, tabletHeader, size, ...rest } = this.props;
    const Container = Containers[depth] || Containers[0];
    return (
      <Container
        tabletHeader={tabletHeader}
        headerHeight={this.state.headerHeight}
        size={size}
        {...rest}
      >
        {this.props.children}
      </Container>
    );
  }
}
export default NavigationStyler;
