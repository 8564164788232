import CartAPI from './cartApi';

const urlCoupons = async () => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const coupons = urlParams.get('coupon-code');
    const redirect = urlParams.get('sc-page');

    if (coupons !== null) {
      const couponsArray = coupons.split(',');
      let ok = false;
      for await (const coupon of couponsArray) {
        const response = await CartAPI.applyCoupon(coupon);
        const data = JSON.parse(response);
        ok = ok ? ok : data.response;
      }

      if (ok && redirect === 'shop') {
        return true;
      }
    }
  }
  return null;
};

export { urlCoupons };
