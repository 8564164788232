import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MetaValueProtection = (props) => {
  const { column } = props;
  if (column.restricted) {
    return (
      <span role="img" aria-label="protected" {...props}>
        <FontAwesomeIcon icon={['fal', 'lock-alt']} />
      </span>
    );
  }

  return props.children;
};

MetaValueProtection.propTypes = {
  column: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default MetaValueProtection;
