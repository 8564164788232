import React from 'react';
import styled from 'styled-components';
import Container from '@/browser/Container';
import PropTypes from 'prop-types';
import LoadmorePosts from '@/components/GraphQL/LoadmorePosts';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import QUERY_GET_PAGINATED_PAGES from '@/graphql/wordpress/pages/queryGetPaginatedPages';

/**
 * Styled component for the section wrapper.
 */
const SectionWrapper = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;

/**
 * React component to display a list of pages.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - The title of the page list.
 * @param {object} props.category - The category object for filtering pages.
 * @param {number} props.limit - The limit of pages to display.
 */
const PageList = (props) => {
  const { title, category, limit } = props;

  // If there is no valid category, return null.
  if (!category || !category.id) {
    return null;
  }

  // Define GraphQL query variables.
  const first = limit;
  const termIdString = category.categoryId.toString();
  const variables = {
    first,
    last: null,
    after: null,
    before: null,
    termIdString
  };

  // Function to update the query with new results.
  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return fetchMoreResult.pages.nodes.length
      ? fetchMoreResult
      : previousResult;
  };

  return (
    <SectionWrapper
      $afterHero={props.previousSection === 'Bodybuilder_HeroUnit'}
    >
      <Container>
        <h2>{title}</h2>
        <QueryHandler
          query={QUERY_GET_PAGINATED_PAGES}
          variables={variables}
          loadmore
          fetchPolicy="cache-first"
          nextFetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ pages }, fetchMore, loading) => (
            <LoadmorePosts
              nodes={pages.nodes}
              refetchState={''}
              hasNextPage={pages.pageInfo.hasNextPage}
              loading={loading}
              fetchMoreMethod={() => {
                fetchMore({
                  variables: {
                    first,
                    after: pages.pageInfo.endCursor || null,
                    last: null,
                    before: null,
                    termIdString
                  },
                  updateQuery
                });
              }}
            />
          )}
        </QueryHandler>
      </Container>
    </SectionWrapper>
  );
};

// Prop type validation for component props.
PageList.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  category: PropTypes.object
};

// Default props for the component.
PageList.defaultProps = {
  limit: 9,
  category: {}
};

export default PageList;
