import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Label, Multiselect } from '@/components/Form/FormStyler';
import facet from './Facet';

const DropdownContainer = styled.div``;

class DropdownFacet extends Component {
  static propTypes = {
    attribute: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    column: PropTypes.object.isRequired,
    values: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      selected: props.initialValue
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({ selected: this.props.initialValue });
    }
  }
  setFilter = (selectedOptions) => {
    // this.setState({ selected: selectedOptions });
    const optionValues = selectedOptions.map((sOption) => sOption.value);
    this.props.applyFilter(optionValues.join(','), true);
  };
  render() {
    const { column, values, keys, label, attribute } = this.props;
    const { selected } = this.state;
    const valueToOption = (value) => ({
      value: encodeURIComponent(value),
      label: [value, column.suffix].join(' ')
    });

    let selectedOptions = [];
    if (selected) {
      const selections = selected.split(',');
      selectedOptions = selections.map(valueToOption);
    }
    const options = values.map(valueToOption);
    const optionsSorted = options.sort((a, b) => (a.label > b.label ? 1 : -1));
    return (
      <DropdownContainer>
        <Label>{label ? label : `${attribute}: `}</Label>
        <Multiselect
          value={selectedOptions}
          options={optionsSorted}
          onChange={this.setFilter}
        />
      </DropdownContainer>
    );
  }
}

const SEARCH_ROOT = { currentPage: true };
export default facet(null, SEARCH_ROOT)(DropdownFacet);
