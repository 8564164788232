import React from 'react';
import styled from 'styled-components';

const Badge = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background-color: #cc0000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  z-index: 100;
`;

const StagingBadge = () => {
  return (
    <Badge>
      <div>OLET STAGING YMPÄRISTÖSSÄ!</div>
    </Badge>
  );
};

export default StagingBadge;
