import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { isCheckout } from '@/utils/helpers.js';
import OrderButton from '@/components/Buttons/OrderButton';
import SocialNetworks from '@/components/Social/SocialNetworks';
import KStar from '@/icons/KStar.svg';
import FooterMenu from './FooterMenu';
import MembershipRestrictedContent from '@/components/Membership/MembershipRestrictedContent';
import { MEMBER_SUBSCRIBER } from '@/configs/memberships';

const FooterWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa.full};
  color: #fff;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  padding: 0 26px;
  position: relative;
  ${(props) => props.theme.media.tablet`
    padding: 0 10px;
  `};
`;

const MainGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 80px 0 40px;
  ${(props) => props.theme.media.tablet`
    width: 90%;
    margin: 0 auto;
  `};
  ${(props) => props.theme.media.mobileSmall`
    width: 100%;
    margin: 0 auto;
    padding: 48px 0;
  `};
`;
const Akstar = styled.div`
  background-image: url(${KStar});
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.2;
  height: 380px;
  width: 380px;
  right: -80px;
  top: -80px;
  position: absolute;
  ${(props) => props.theme.media.tablet`
    width: 200px;
    height: 200px;
    right: -56px;
    top: -10px;
  `};
  ${(props) => props.theme.media.mobileSmall`
    top: inherit;
    bottom: -10px;
  `};
`;

const BottomGrid = styled.div`
  padding: 30px 0;
  display: grid;
  grid-template-columns: 80% 20%;
  border-top: 1px solid #a0aeb7;
  ${(props) => props.theme.media.mobileSmall`
    display: block;
  `};
`;
const Copyright = styled.div`
  text-align: right;
  text-transform: uppercase;
  font-size: 11px;
  color: ${(props) => props.theme.colors.grey};
  font-weight: bold;
  letter-spacing: 1.7px;
  ${(props) => props.theme.media.mobileSmall`
    padding-top: 36px;
    text-align: left;
  `};
`;

const FooterCellBtn = styled.div`
  min-height: 120px;

  ${(props) => props.theme.media.mobileSmall`
    min-height: 0;
  `};
`;

const FooterCellTxt = styled.div`
  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
`;

const Footer = ({ location }) => {
  if (isCheckout(location)) {
    return false;
  }
  return (
    <FooterWrapper>
      <Container>
        <Akstar />
        <MainGrid>
          <MembershipRestrictedContent
            excludedMemberships={[MEMBER_SUBSCRIBER]}
          >
            <FooterCellBtn>
              <OrderButton
                color="kuluttaja2021.tummaPetroli[20]"
                txtcolor="kuluttaja2021.tummaPetroli.full"
              />
            </FooterCellBtn>
          </MembershipRestrictedContent>
          <FooterCellTxt>
            <FormattedMessage id="footer.column1.title" tagName="h4" />
            <FormattedMessage id="footer.column1.text" tagName="p" />
            <SocialNetworks />
          </FooterCellTxt>
        </MainGrid>
        <BottomGrid>
          <FooterMenu />
          <Copyright>© {new Date().getFullYear()} Kuluttajamedia</Copyright>
        </BottomGrid>
      </Container>
    </FooterWrapper>
  );
};

export default withRouter(Footer);
