/**
 * This is a fragment of the "BodyBuilder" Test Article block.
 */

import gql from 'graphql-tag';

const FRAGMENT_TEST_ARTICLE = gql`
  fragment TestArticle on Bodybuilder_TestArticle {
    test_article {
      slug
    }
  }
`;

export default FRAGMENT_TEST_ARTICLE;
