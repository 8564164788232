/**
 * This is a fragment of the "BodyBuilder" Substory block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_SUBSTORY = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment Substory on Bodybuilder_Substory {
    title
    label
    content
    image {
      ...FeaturedImageModel
    }
  }
`;

export default FRAGMENT_SUBSTORY;
