import React from 'react';
import styled from 'styled-components';
import UserPanel from './UserPanel/UserPanel';
import MiniCart from '../Cart/MiniCart';
import InstallWebApp from './InstallWebApp';

const TopRow = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: flex;
  `};
  height: 27px;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gunmetal};
  padding: 5px 26px;
  font-size: 13px;
`;
const TopRowLeft = styled.div`
  flex: auto;
`;
const TopRowRight = styled.div`
  display: flex;
`;

const TopNavigation = () => {
  return (
    <TopRow>
      <TopRowLeft>
        <InstallWebApp />
      </TopRowLeft>
      <TopRowRight>
        <MiniCart />
        <UserPanel />
      </TopRowRight>
    </TopRow>
  );
};

export default TopNavigation;
