import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MetaValue from './MetaValue';
import MetaValueProtection from './MetaValueProtection';
import Reference from './Reference';
import DropdownOpen from '@/components/Buttons/DropdownOpen';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const StyledMobileRow = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.$even ? props.theme.colors.lightGrey : '#fff'};

  &.row-lvl-0,
  &.row-lvl-0 .leftcell-lvl-0 {
    background-color: ${(props) =>
      props.$even ? props.theme.colors.lightBlueGrey : '#fff'};
  }
  &.row-lvl-P,
  &.row-lvl-P .leftcell-lvl-P {
    background-color: #fff;
  }
  &.fadeslide-enter {
    overflow: hidden;
    max-height: 1px;
  }
  &.fadeslide-enter-active {
    overflow: hidden;
    max-height: 1000px;
    transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &.fadeslide-exit {
    overflow: hidden;
    max-height: 1000px;
  }
  &.fadeslide-exit-active {
    overflow: hidden;
    max-height: 1px;
    transition: all 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

const StyledMobileRowInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
`;

const LeftCell = styled.div`
  font-weight: ${(props) => (props.$level === '1' ? 'bold' : 'normal')};
  font-size: ${(props) => (props.$level === '1' ? '11px' : 'inherit')};
  font-family: ${(props) =>
    props.$level === '1'
      ? props.theme.fonts.secondary
      : props.theme.fonts.primary};
  text-transform: ${(props) => (props.$level === '1' ? 'uppercase' : 'none')};
  letter-spacing: ${(props) => (props.$level === '1' ? '2.2px' : 'normal')};
  color: ${(props) =>
    props.$level === '1' ? props.theme.colors.gunmetal : 'inherit'};
  padding: 12px 1rem 12px
    ${(props) =>
      props.$level > 0 ? (1 + parseFloat(props.$level)).toString() : '1'}rem;
  cursor: ${(props) => (props.$level === '1' ? 'pointer' : 'normal')};
  width: ${(props) => (props.$isDropDown ? 'calc(100% - 24px)' : '100%')};
  display: flex;
  text-align: left;
  box-sizing: border-box;
  flex-grow: 2;
  background-color: transparent;

  &.leftcell-lvl-P {
    text-transform: capitalize;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-weight: bold;
    font-size: 17px;
  }
`;

const CompCol = styled.div`
  padding: 0 1rem 12px;
  padding-left: ${(props) => {
    return props.$level > 0 ? (parseFloat(props.$level) + 2).toString() : '2';
  }}rem;
  color: ${(props) => props.theme.colors.gunmetal};
  text-align: left;
  box-sizing: border-box;
  width: 100%;
`;

const Dropdown = styled(DropdownOpen)`
  position: absolute;
  top: 0.5rem;
  left: 0;
  border: 0;
  &:before,
  &:after {
    background-color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    background-color: transparent;
    &:before,
    &:after {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Weight = styled.div`
  color: ${(props) => props.theme.colors.grey};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  flex-shrink: 0;
  margin-right: 0;
  margin-left: auto;
  padding-left: 0.5rem;
`;

/**
 * Represents a mobile comparison row.
 * @param {Object} props - Component properties.
 * @param {Object} props.row - The row object.
 * @param {Object} props.product - The product object.
 * @param {Array} props.children - The children rows.
 * @param {Array} props.references - Array of references.
 * @param {Array} props.reference_descriptions - Array of reference descriptions.
 * @param {number} props.index - The index of the row.
 * @param {number} props.level - The level of the row.
 * @returns {JSX.Element} - The rendered component.
 */
const ComparisonRowMobile = ({
  row,
  product,
  children,
  references,
  reference_descriptions,
  index,
  level
}) => {
  const [active, setActive] = useState(true);

  const hasChildren =
    children && Array.isArray(children) && children.length > 0;

  const onClick = hasChildren ? () => setActive(!active) : null;

  return (
    <StyledMobileRow
      $even={row.orderNum % 2 === 0}
      $level={row.level}
      $hasChildren={hasChildren}
      className={`comprow row-lvl-${row.level ? row.level : '0'}`}
    >
      <StyledMobileRowInner onClick={onClick}>
        {hasChildren && row.level !== 'P' && <Dropdown $active={active} />}
        <LeftCell
          $level={row.level}
          $isDropDown={hasChildren && row.level !== 'P'}
          className={`leftcell leftcell-lvl-${row.level ? row.level : '0'}`}
        >
          {row.name} {row.weight && <Weight>{row.weight}</Weight>}
          <Reference
            column={row}
            references={references}
            descriptions={reference_descriptions}
          />
        </LeftCell>
        <CompCol
          key={`cell-${row.name}-${product.id}`}
          className={`compCol`}
          $level={row.level}
          $isDropDown={hasChildren && row.level !== 'P'}
        >
          <MetaValueProtection column={row}>
            <MetaValue column={row} product={product} />
            <Reference
              column={row}
              product={product}
              descriptions={reference_descriptions}
            />
          </MetaValueProtection>
        </CompCol>
      </StyledMobileRowInner>
      <TransitionGroup>
        {hasChildren &&
          active &&
          children.map((row, rowIndex) => (
            <CSSTransition
              timeout={250}
              classNames="fadeslide"
              key={`row-${row.name}-${rowIndex}`}
            >
              <ComparisonRowMobile
                row={row}
                children={row.children}
                product={product}
                references={references}
                reference_descriptions={reference_descriptions}
                index={index}
              />
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledMobileRow>
  );
};

ComparisonRowMobile.propTypes = {
  row: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  children: PropTypes.array,
  references: PropTypes.array,
  reference_descriptions: PropTypes.array,
  index: PropTypes.number.isRequired,
  level: PropTypes.number
};

ComparisonRowMobile.defaultProps = {
  level: 0
};

export default ComparisonRowMobile;
