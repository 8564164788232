import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from './Loader';

const Container = styled.div`
  position: relative;
`;

const Blur = styled.div`
  filter: blur(2px);
`;

const StyledLoader = styled(Loader)`
  min-height: inherit;
`;

const BlockLoader = (props) => {
  if (!props.visible) {
    return props.children;
  }
  return (
    <Container>
      <StyledLoader visible small />
      <Blur>{props.children}</Blur>
    </Container>
  );
};

BlockLoader.propTypes = {
  visible: PropTypes.bool
};
BlockLoader.defaultProps = {
  visible: false
};

export default BlockLoader;
