import React, { useState } from 'react';
import { NormalButton } from './Button';
import { FormattedMessage } from 'react-intl';
import { Query } from '@apollo/client/react/components';
import QUERY_GET_EPAPER_LINK from '@/graphql/wordpress/ePaper/queryGetEPaperLink';

/**
 * Functional component representing the LehtiluukkuButton component.
 * This component displays a button that opens an ePaper link when clicked.
 * @param {object} props - Component props.
 * @param {string} props.issueId - The ID of the ePaper issue.
 * @param {string} props.text - The text to display on the button.
 * @param {string} props.magazineId - The ID of the magazine.
 * @returns {JSX.Element} - Rendered component.
 */
const LehtiluukkuButton = (props) => {
  const { issueId, text, magazineId } = props;
  // Initialize a state variable to track whether the ePaper link should be opened.
  const [isPaper, setIsPaper] = useState(false);

  return (
    <span>
      {isPaper === true ? (
        <Query
          query={QUERY_GET_EPAPER_LINK}
          variables={{ issueId: +issueId, magazineId: +magazineId }}
          errorPolicy="none"
        >
          {({ data }) => {
            if (data && data.epaperLink) {
              // Open the ePaper link in a new window or tab.
              const epaper = window.open(data.epaperLink);
              if (epaper !== null) {
                epaper.focus();
              } else {
                // If the window couldn't be opened, redirect to the ePaper link.
                window.location.replace(data.epaperLink);
              }
            }

            // Reset the state variable after a delay.
            setTimeout(function () {
              setIsPaper(false);
            }, 3000);

            return null;
          }}
        </Query>
      ) : (
        <NormalButton
          className="lehtiluukku-button"
          onClick={() => setIsPaper(true)}
          {...props}
        >
          {text || <FormattedMessage id="action.lehtiluukku" />}
        </NormalButton>
      )}
    </span>
  );
};

export default LehtiluukkuButton;
