import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash.get';
import Navigation from '@/components/Navigation/Navigation';

/**
 * The Menu component.
 * @param {object} props - The component's properties.
 * @returns {JSX.Element} - The rendered component.
 */
const Menu = (props) => {
  const { menu, tabletHeader, size } = props;

  if (!_get(menu, 'menuItems.nodes')) {
    return null;
  }

  return (
    <Navigation
      nodes={menu.menuItems.nodes}
      size={size || 'large'}
      tabletHeader={tabletHeader}
    />
  );
};

Menu.propTypes = {
  menu: PropTypes.object
};

Menu.defaultProps = {
  menu: {}
};

export default Menu;
