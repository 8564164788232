import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxFacet from '../../SearchFacets/CheckboxFacet';
import DropdownFacet from '../../SearchFacets/DropdownFacet';
import RangeFacet from '../../SearchFacets/RangeFacet';
import RadioFacet from '../../SearchFacets/RadioFacet';
import { SearchFacetBox, MainFacets } from '../../SearchFacets/SearchFacetBox';
import SearchButtons from '../../SearchFacets/SearchButtons';
import { generateFacets } from '../test-utils';

const FACET_TYPES = {
  checkbox: CheckboxFacet,
  dropdown: DropdownFacet,
  radio: RadioFacet,
  range: RangeFacet,
  decimalRange: RangeFacet
};

class SearchFacets extends Component {
  static propTypes = {
    test: PropTypes.object.isRequired
  };
  render() {
    const { test } = this.props;
    if (!test.column_definitions || !test.product_reviews) {
      return null;
    }

    const filterColumns = test.column_definitions.filter(
      // if faces exists and column is not restricted
      (col) => Object.keys(FACET_TYPES).includes(col.filter) && !col.restricted
    );
    const facets = generateFacets(filterColumns, test.product_reviews.reviews);
    return (
      <SearchFacetBox>
        <h2>
          <FormattedMessage id="search.facet.filter" />
        </h2>
        <MainFacets>
          {Object.entries(facets).map(([key, facet]) => {
            const Facet = FACET_TYPES[facet.column.filter];
            return <Facet key={`facet-${key}`} attribute={key} {...facet} />;
          })}
          <SearchButtons />
        </MainFacets>
      </SearchFacetBox>
    );
  }
}

export default SearchFacets;
