import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Container from '@/browser/Container';
import LiftCard from '@/components/LiftCard/LiftCard';
import WpImage from '@/components/Images/WpImage';
import Link from '@/components/Link/Link';
import PriceBall from '@/components/Price/PriceBall';
import { useQuery } from '@apollo/client';
import { StyledButton } from '@/components/Buttons/Button';
import { ForwardLink } from '@/components/Buttons/BackForwardButtons';
import _get from 'lodash.get';

import { connect } from 'react-redux';
import { store } from '@/store/reducers/auth';
import MembershipRestrictedContent from '@/components/Membership/MembershipRestrictedContent';
import { MEMBER_SUBSCRIBER } from '@/configs/memberships';
import QUERY_GET_MAGAZINE_MAIN_ARTICLES from '@/graphql/wordpress/magazine/queryGetMagazineMainArticles';

const Section = styled.div`
  background-color: ${(props) =>
    props.$prevIsHero
      ? 'transparent'
      : props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  margin-bottom: 64px;
`;

const Box = styled(Container)`
  padding: 123px 5%;
  border-width: ${(props) => (props.$prevIsHero ? '16px' : '0')};
  border-style: solid;
  background-color: ${(props) => (props.$prevIsHero ? '#fff' : 'transparent')};
  border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  ${(props) => props.theme.media.tablet`
    padding: 123px 16px 30px;
    border-width: ${(props) => (props.$prevIsHero ? '8px' : '0')};
    width: 100%;
  `};
  ${(props) => props.theme.media.mobile`
    padding-top: 32px;
    padding-bottom: 42px;
    .title-desktop {
      display: none;
    }
  `};
  .title-mobile {
    display: none;
    ${(props) => props.theme.media.mobile`
      display: block;
      margin-bottom: ${(props) => (props.$hasProduct ? '78px' : '24px')};
      text-align: center;
    `};
  }
`;

const Maglift = styled(LiftCard)`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  padding-bottom: 24px;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 24px;
  }
`;

const Subtitle = styled.h3`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  padding-bottom: 24px;
  margin-bottom: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
  grid-gap: 6%;
`;

const Magcol = styled.div`
  ${(props) => props.theme.media.mobile`
    margin-bottom: 32px;
  `};
`;

const MagBlurb = styled.div`
  position: relative;
  max-width: max-content;
  margin: 0 auto 24px;
  ${(props) => props.theme.media.mobile`
    width: 70%;
  `};
`;

const MagPrice = styled(PriceBall)`
  position: absolute;
  top: -70px;
  left: -70px;
`;

const Login = styled(Link)`
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 2;
  letter-spacing: 2.2px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[60]};
  text-align: center;
  font-weight: bold;
  display: block;
  padding-bottom: 24px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin-bottom: 24px;
    &:first-of-type {
      margin-right: 16px;
    }
    ${(props) => props.theme.media.mobile`
      width: 100%;
    `};
  }
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
`;

/**
 * MagazineLift component for displaying a magazine.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.magazine_category - The magazine category object.
 * @param {Object} props.auth - Redux auth object.
 * @param {string} props.previousSection - Name of the previous section.
 * @returns {JSX.Element} - Rendered MagazineLift component.
 */
const MagazineLift = (props) => {
  const magazine = props.magazine_category;
  const { auth } = props;
  if (!magazine) {
    return null;
  }
  const { name, cover_image, product, subscription_product, category_page } =
    magazine;
  const { data, loading } = useQuery(QUERY_GET_MAGAZINE_MAIN_ARTICLES, {
    variables: { termId: magazine.id }
  });
  const postNodes = _get(data, 'magazine.posts.nodes', []);
  const pageNodes = _get(data, 'magazine.pages.nodes', []);
  const combinedNodes = [...postNodes, ...pageNodes].sort(
    (a, b) => new Date(b.dateGmt) - new Date(a.dateGmt)
  );

  return (
    <Section $prevIsHero={props.previousSection === 'Bodybuilder_HeroUnit'}>
      <Box
        $prevIsHero={props.previousSection === 'Bodybuilder_HeroUnit'}
        $hasProduct={product}
      >
        <Grid>
          <h2 className="title-mobile">{name}</h2>
          <Magcol>
            <MagBlurb>
              {product && <MagPrice price={product.price} title="Irtonumero" />}
              <WpImage image={cover_image} />
            </MagBlurb>
            <MembershipRestrictedContent
              excludedMemberships={[MEMBER_SUBSCRIBER]}
            >
              <Actions>
                {product && (
                  <StyledButton to={product.uri}>
                    <FormattedMessage id="bodybuilder.magazinelift.purchase" />
                  </StyledButton>
                )}
                {subscription_product && (
                  <StyledButton
                    to={subscription_product.uri}
                    $color="kuluttaja2021.tyrni.full"
                    $txtcolor="kuluttaja2021.tummaPetroli.full"
                  >
                    <FormattedMessage id="action.subscribe" />
                  </StyledButton>
                )}
              </Actions>
            </MembershipRestrictedContent>
            <MembershipRestrictedContent
              includedMemberships={[MEMBER_SUBSCRIBER]}
            >
              <Actions>
                {category_page && (
                  <StyledButton to={category_page.uri}>
                    <FormattedMessage id="bodybuilder.magazinelift.explore" />
                  </StyledButton>
                )}
              </Actions>
            </MembershipRestrictedContent>
            {!auth.token && (
              <Login to="/käyttäjä/kirjaudu">
                <FormattedMessage id="user.questions.alreadyHaveAccount" />
                <br />
                <FormattedMessage id="bodybuilder.magazinelift.login" />
              </Login>
            )}
          </Magcol>
          <div>
            <h2 className="title-desktop">{name}</h2>
            <Subtitle>
              <FormattedMessage id="bodybuilder.magazinelift.mainArticles" />
            </Subtitle>
            {!loading &&
              combinedNodes.length > 0 &&
              combinedNodes.map((node, index) => (
                <Maglift
                  key={`node-${node.id}`}
                  image={node.featuredImage}
                  title={node.title}
                  restricted={node.paywall.premium}
                  date={node.dateGmt}
                  size={3}
                  content={index < 1 && node.excerpt}
                  categories={node.categories}
                  link={node.uri}
                />
              ))}
            {category_page && (
              <ForwardLink to={category_page.uri}>
                <FormattedMessage id="bodybuilder.magazinelift.explore" />
              </ForwardLink>
            )}
          </div>
        </Grid>
      </Box>
    </Section>
  );
};

MagazineLift.propTypes = {
  magazine_category: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  previousSection: PropTypes.string
};

MagazineLift.defaultProps = {
  magazine_category: {},
  auth: {},
  previousSection: ''
};

export default connect((state) => ({ auth: state.auth }), { store })(
  MagazineLift
);
