import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const InstallButton = styled.button`
  border: none;
  background: none;
  color: white;
`;

class InstallAction extends Component {
  state = {
    eventTriggered: false
  };
  componentDidMount() {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Stash the event so it can be triggered later.
      this.installPromptEvent = event;

      this.setState({ eventTriggered: true });
    });
  }

  promptInstall = () => {
    this.installPromptEvent.prompt();
    // Wait for the user to respond to the prompt
    this.installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }

      // Clear the saved prompt since it can't be used again
      this.installPromptEvent = null;
    });
  };

  render() {
    if (!this.installPromptEvent) {
      return null;
    }

    return (
      <InstallButton onClick={this.promptInstall}>
        <FormattedMessage id="action.install" />
      </InstallButton>
    );
  }
}

export default InstallAction;
