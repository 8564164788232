export function getMetaValue(rawKey, review) {
  const metaKey = rawKey.toLowerCase();
  const metaObj = review.metadata.find(({ key, value }) => {
    return key.toLowerCase() === metaKey;
  });
  return metaObj && metaObj.value ? metaObj.value : null;
}
export function getOverallScore(review) {
  const metaKey = 'kokonaisarvosana';
  const metaObj = review.metadata.find(({ key, value }) => {
    return key.toLowerCase().includes(metaKey);
  });
  return metaObj && metaObj.value ? metaObj.value.replace(',', '.') : null;
}

export function getPriceClass(review) {
  const metaKey = 'hinta';
  const metaObj = review.metadata.find(({ key, value }) => {
    return key.toLowerCase().includes(metaKey);
  });
  return metaObj && metaObj.value ? metaObj.value.replace(',', '.') : null;
}

export function isNumericValue(originalValue) {
  if (!originalValue) {
    return false;
  }

  let value = `${originalValue}`;
  // Numbers are in finnish format, e.g. 4,3425s
  if (originalValue.includes(',')) {
    value = originalValue.replace(',', '.');
  }
  return !isNaN(value);
}

export function getStars(value, type = 'stars(1-100)') {
  const count = Math.ceil((Math.round(value) + 1) / 20);
  const clamped = Math.min(Math.max(count, 1), 5);
  return clamped;
}

export function getFullProductName(review) {
  return [getMetaValue('Merkki', review), getMetaValue('Malli', review)].join(
    ' '
  );
}
