import React from 'react';
import styled from 'styled-components';

const BurgerIcon = styled.button`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
  position: absolute;
  height: 60px;
  width: 61px;
  right: 0;
  top: 0;
  overflow: hidden;
  padding: 0;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  border: none;
  transition: background-color 100ms;
  background-color: ${(props) =>
    props.$active ? props.theme.colors.kuluttaja2021.harmaa[10] : '#fff'};
  z-index: 12;
  div {
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.harmaa.full};
    position: absolute;
    height: 2px;
    transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:nth-child(-n + 3) {
      width: ${(props) => (props.$active ? 0 : '26px')};
      left: 17px;
    }
    &:nth-child(1) {
      top: 19px;
    }
    &:nth-child(2) {
      top: 29px;
      transition-delay: 70ms;
    }
    &:nth-child(3) {
      top: 39px;
      transition-delay: 140ms;
    }
    &:nth-child(4) {
      opacity: ${(props) => (!props.$active ? 0 : 1)};
      transform: rotate(-45deg);
    }
    &:nth-child(5) {
      opacity: ${(props) => (!props.$active ? 0 : 1)};
      transform: rotate(45deg);
    }
    &:nth-child(n + 4) {
      transition: all 100ms ease-in;
      top: 30px;
      left: 16px;
      width: 30px;
    }
  }
`;

const Burger = (props) => {
  const { active, ...rest } = props;
  return (
    <BurgerIcon $active={active} {...rest}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </BurgerIcon>
  );
};

export default Burger;
