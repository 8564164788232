import React from 'react';
import styled from 'styled-components';
import Container from '@/browser/Container';
import PropTypes from 'prop-types';
import LoadmorePosts from '@/components/GraphQL/LoadmorePosts';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import QUERY_GET_PAGINATED_POSTS from '@/graphql/wordpress/posts/queryGetPaginatedPosts';

/**
 * Styled component for the section wrapper.
 */
const SectionWrapper = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;

/**
 * React component to display a list of magazine articles.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - The title of the magazine article list.
 * @param {object} props.magazine_category - The magazine category object.
 * @param {number} props.limit - The limit of articles to display.
 */
const MagazineArticleList = (props) => {
  const { title, magazine_category, limit } = props;

  // If there is no valid magazine category, return null.
  if (!magazine_category || !magazine_category.id) {
    return null;
  }

  // Define GraphQL query variables.
  const variables = {
    first: limit || 9,
    last: null,
    after: null,
    before: null,
    termIdString: magazine_category.magazineId.toString(),
    taxonomy: 'MAGAZINE'
  };

  // Function to update the query with new results.
  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return fetchMoreResult.posts.nodes.length
      ? fetchMoreResult
      : previousResult;
  };

  return (
    <SectionWrapper
      $afterHero={props.previousSection === 'Bodybuilder_HeroUnit'}
    >
      <Container>
        <h2>{title}</h2>
        <QueryHandler
          query={QUERY_GET_PAGINATED_POSTS}
          variables={variables}
          loadmore
          fetchPolicy="cache-first"
          nextFetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ posts }, fetchMore, loading) => (
            <LoadmorePosts
              nodes={posts.nodes}
              refetchState={''}
              hasNextPage={posts.pageInfo.hasNextPage}
              loading={loading}
              fetchMoreMethod={() => {
                fetchMore({
                  variables: {
                    first: 3,
                    after: posts.pageInfo.endCursor || null,
                    last: null,
                    before: null,
                    termIdString: magazine_category.magazineId.toString()
                  },
                  updateQuery
                });
              }}
            />
          )}
        </QueryHandler>
      </Container>
    </SectionWrapper>
  );
};

// Prop type validation for component props.
MagazineArticleList.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  magazine_category: PropTypes.object
};

// Default props for the component.
MagazineArticleList.defaultProps = {
  magazine_category: {}
};

export default MagazineArticleList;
