import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WpImage from '@/components/Images/WpImage';
import { getBackgroundForPath } from '@/utils/helpers';
import { useQuery } from '@apollo/client';
import QUERY_GET_BACKGROUND_IMAGES from '@/graphql/wordpress/allSettings/queryGetBackgroundImages';
import _get from 'lodash.get';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  ${(props) => props.theme.media.tablet`
    display: block;
    `};
`;

const ContentSection = styled.div`
  background-color: ${(props) =>
    props.$bgc
      ? _get(props.theme.colors, props.$bgc)
      : props.theme.colors.kuluttaja2021.tummaPetroli.full};
  padding: 5em 0;
`;

const Content = styled.div`
  width: 90%;
  max-width: 660px;
  margin: 0 auto;
  h2 {
    margin-bottom: 0.2em;
  }
  p {
    margin-bottom: 2em;
  }
`;

const RightSection = styled.div`
  background-color: ${(props) =>
    props.$bgc
      ? _get(props.theme.colors, props.$bgc)
      : props.theme.colors.kuluttaja2021.tummaPetroli.full};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  ${(props) => props.theme.media.tablet`
    display: none;
    `};
`;

/**
 * A full-section component with content and an optional background image.
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - Content to be displayed.
 * @param {string} [props.backgroundPath] - The background image path.
 * @param {string} [props.backgroundColor] - The background color.
 * @returns {React.Element} - The FullSection component.
 */
const FullSection = ({ children, backgroundPath, backgroundColor }) => {
  const { data, loading } = useQuery(QUERY_GET_BACKGROUND_IMAGES);
  const image =
    !loading && getBackgroundForPath(data.allSettings, backgroundPath);

  return (
    <Grid>
      <ContentSection $bgc={backgroundColor}>
        <Content>{children}</Content>
      </ContentSection>
      <RightSection>{image && <WpImage image={image} />}</RightSection>
    </Grid>
  );
};

FullSection.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundPath: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default FullSection;
