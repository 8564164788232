/**
 * This is the query to get the main menu from WordPress.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_MENU_ITEM_MODEL from '@/graphql/wordpress/_fragments/fragmentMenuItemModel';

const QUERY_GET_MAIN_MENU = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_MENU_ITEM_MODEL}

  fragment ConnectedPage on Page {
    ...ContentNodeBasicModel
    title
    categories {
      nodes {
        categoryId
        slug
        icon {
          sourceUrl
          altText
        }
        category_page {
          link
          uri
        }
      }
    }
  }

  query getMainMenu {
    menu(id: "main_menu", idType: NAME) {
      menuItems(first: 8, where: { parentDatabaseId: 0 }) {
        nodes {
          ...MenuItemModel
          childItems(first: 99) {
            nodes {
              ...MenuItemModel
              connectedObject {
                ...ConnectedPage
              }
              childItems(first: 99) {
                nodes {
                  ...MenuItemModel
                  childItems(first: 99) {
                    nodes {
                      ...MenuItemModel
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default QUERY_GET_MAIN_MENU;
