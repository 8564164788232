import { useQuery } from '@apollo/client';
import QUERY_GET_MAIN_MENU from '@/graphql/wordpress/menus/queryGetMainMenu';
import QUERY_GET_MENU from '@/graphql/wordpress/menus/queryGetMenu';

/**
 * Query handler for the Header component.
 *
 * @param {*} param0
 * @returns
 */
const HeaderQueries = ({ children }) => {
  const mainMenu = useQuery(QUERY_GET_MAIN_MENU);
  const subMenu = useQuery(QUERY_GET_MENU, {
    variables: { id: 'submenu' }
  });

  return children({
    mainMenu: !mainMenu.loading ? mainMenu.data.menu : {},
    subMenu: !subMenu.loading ? subMenu.data.menu : {}
  });
};

export default HeaderQueries;
