import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { OutsideClickHandler } from '@/redandblue/components';
import { isCheckout } from '@/utils/helpers.js';
import CheckoutHeader from '@/components/Shop/CheckoutHeader';
import HeaderQueries from './HeaderQueries';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import FixedHeader, { StyledFixedHeader } from './FixedHeader';
import TopNavigation from './TopNavigation';
import Breadcrumb from './Breadcrumb';
import Menu from './Menu';
import Logo from './Logo';
import Burger from './Burger';
import MobileMenu from './MobileMenu';

const HeaderContainer = styled.div`
  display: block;
  background-color: #fff;
  ${(props) => props.theme.media.tablet`
    padding-top: 60px;
  `};
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  z-index: 9999;
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    padding-top: 181px;
  `};
`;

const HeaderInner = styled.div`
  display: flex;
  width: 100%;
  padding: 0 26px 0 38px;
  min-height: 60px;
  justify-content: space-between;
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
    // padding: 0 10px 0 10px;
  `};
  ${(props) => props.theme.media.tablet`
    padding: 0 60px 0 16px;
  `}
`;

const HeaderLeft = styled.div`
  width: 26%;
  min-width: 220px;
  display: flex;
  justify-content: left;
  align-items: center;
  ${(props) =>
    !props.$isSticky &&
    props.theme.media.laptopOnly`
    position: absolute;
    padding-top: 40px;
    min-width: 220px;
    width: 220px;
  `};
  ${(props) => props.theme.media.tablet`
    padding-top: 6px;
    min-width: inherit;
    width: auto;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
     width: 180px;
     min-width: inherit;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
      margin-bottom: 0;
  `};
`;

const Navigation = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: flex;
  `};
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space between;
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    padding-top: 28px;
    flex: auto;
    justify-content: center;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
    padding: 24px 0 26px;
  `};
`;

const HeaderMiddle = styled.div`
  display: none;
  ${(props) => props.theme.media.tabletOnly`
    display: block;
  `};
  width: 100%;
`;

const HeaderRight = styled.div`
  width: 100%;
  ${(props) => props.theme.media.tablet`
    width: auto;
    z-index: 13;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    display: flex;
    flex-direction: row-reverse;
  `};
`;

/**
 * The Header component.
 * @param {object} props - The component's properties.
 * @param {object} props.location - The current location object from router.
 * @returns {JSX.Element} - The rendered component.
 */
const Header = ({ location }) => {
  const [mobileNavActive, setMobileNavActive] = useState(false);

  const toggleState = () => {
    setMobileNavActive(!mobileNavActive);
    document.body.classList.toggle('subnav-open');
  };

  useEffect(() => {
    // Reset mobileNavActive when the route changes
    setMobileNavActive(false);
  }, [location]);

  if (isCheckout(location)) {
    return <CheckoutHeader location={location} />;
  }

  return (
    <HeaderQueries>
      {({ mainMenu, subMenu }) => (
        <>
          <FixedHeader>
            {({ beforeSticky, isSticky }) => (
              <HeaderContainer id="main-header" $isSticky={isSticky}>
                <StyledFixedHeader
                  $isSticky={isSticky}
                  $beforeSticky={beforeSticky}
                >
                  <TopNavigation />

                  <OutsideClickHandler
                    onOutsideClick={() => setMobileNavActive(false)}
                  >
                    <HeaderInner $isSticky={isSticky}>
                      <HeaderLeft $isSticky={isSticky}>
                        <Logo isSticky={isSticky} />
                      </HeaderLeft>

                      <HeaderMiddle>
                        {mainMenu && <Menu menu={mainMenu} tabletHeader />}
                      </HeaderMiddle>

                      <HeaderRight $isSticky={isSticky}>
                        <HeaderSearch />

                        <Navigation $isSticky={isSticky}>
                          {mainMenu ? (
                            <Menu menu={mainMenu} />
                          ) : (
                            <div style={{ height: 24 }} />
                          )}
                          {subMenu && <Menu menu={subMenu} size="small" />}
                        </Navigation>
                      </HeaderRight>

                      <MobileMenu
                        active={mobileNavActive}
                        menus={[mainMenu, subMenu]}
                        loading={!mainMenu || !subMenu}
                      />
                      <Burger active={mobileNavActive} onClick={toggleState} />
                    </HeaderInner>
                  </OutsideClickHandler>
                </StyledFixedHeader>
              </HeaderContainer>
            )}
          </FixedHeader>
          <Breadcrumb menus={[mainMenu, subMenu]} />
        </>
      )}
    </HeaderQueries>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Header);
