/**
 * This is a fragment of the "BodyBuilder" Image Set block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_IMAGE_SET = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment ImageSet on Bodybuilder_ImageSet {
    images {
      ...FeaturedImageModel
    }
  }
`;

export default FRAGMENT_IMAGE_SET;
