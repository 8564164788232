import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../../components/NotFound/NotFound';
import SearchFacets from './SingleTest/SearchFacets.js';
import ProductReviewsList from './SingleTest/ProductReviewsList.js';
import TestHero from './Components/TestHero';

const SingleTest = (props) => {
  const { test, accessible } = props;
  if (!test) {
    return <NotFound />;
  }

  return (
    <div>
      <TestHero overlap={true} test={test} />
      <SearchFacets test={test} />
      <ProductReviewsList data={test.product_reviews} accessible={accessible} />
    </div>
  );
};

SingleTest.propTypes = {
  test: PropTypes.object.isRequired
};
export default SingleTest;
