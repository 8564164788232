import styled from 'styled-components';

// WordPress Wysiwyg editor mostly works with plain HTML, but for alignments
// it relies on classes. So we have to re-implement them.
const ContentStyler = styled.div`
  position: relative;
  .alignleft {
    float: left;
    margin: 1em;
  }
  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .alignright {
    float: right;
    margin: 1em;
  }
  .alignnone {
    display: block;
  }
  div.wp-caption {
    width: auto !important;
    font-size: 11px;
    line-height: 1.55;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${(props) => props.theme.colors.grey};
    white-space: wrap;
    display: inline;
  }
  .ingress {
    font-size: 1.2em;
    font-family: ${(props) => props.theme.fonts.secondary};
  }
  .dropcap {
    p {
      float: left;
      &:first-letter {
        font-size: 5em;
        float: left;
        color: ${(props) => props.theme.colors.grey};
        line-height: 0.73em;
        padding: 0.12em 0.2em 0 0;
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  a {
    color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
    font-weight: bold;
  }
  blockquote {
    position: relative;
    padding: 16px 16px 16px 4em;
    margin: 32px 0;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
    font-family: ${(props) => props.theme.fonts.secondary};
    border-width: 1px 0;
    font-size: 17px;
    line-height: 1.71;
    min-height: 122px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    &:before {
      content: '“';
      top: 0;
      left: 0;
      font-weight: bold;
      color: ${(props) => props.theme.colors.paleteal};
      font-family: ${(props) => props.theme.fonts.secondary};
      font-size: 6em;
      position: absolute;
    }
  }
  ul,
  ol {
    margin: 0;
    padding: 16px 0 16px 32px;
    li {
      padding-left: 8px;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul li {
    position: relative;
    &:before {
      content: ' ';
      display: block;
      height: 6px;
      width: 6px;
      position: absolute;
      top: 8px;
      left: -12px;
      background-color: #000;
      border-radius: 50%;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  // Forms
  label {
    font-family: ${(props) => props.theme.fonts.secondary};
    margin-bottom: 8px;
    display: inline-block;
  }
  input,
  textarea {
    border-radius: 0;
    box-shadow: 0;
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 16px;
    &:focus {
      box-shadow: 2px 2px 2px 0 rgba(87, 87, 87, 0.36);
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  select {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
    margin-bottom: 8px;
    background-color: white;
    height: 42px;
    padding-left: 16px;
  }
  #ot-sdk-btn.ot-sdk-show-settings,
  input[type='submit'],
  .button,
  button {
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
    border: 0;
    display: block;
    color: #fff;
    padding: 0 32px;
    transition: all 350ms;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 800;
    font-size: 14px;
    outline: 3px solid rgba(255, 255, 255, 0);
    outline-offset: -3px;
    box-shadow: 0 0 0 0 rgba(87, 87, 87, 0.36);
    apparence: none;
    &:hover,
    &:focus,
    &:active {
      background-color: ${(props) =>
        props.theme.colors.kuluttaja2021.tummaPetroli.full};
      outline-color: rgba(255, 255, 255, 1);
      box-shadow: 0 0 14px 0 rgba(87, 87, 87, 0.36);
      transition: all 50ms;
    }
    &::-moz-focus-inner {
      border: 0;
    }
    &.button-hollow {
      background-color: transparent;
      color: white;
      outline: 3px solid white;
      &:hover,
      &:focus,
      &:active {
        background-color: white;
        color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
      }
    }
  }
  button {
    padding-top: 20px;
    height: 56px;
  }
  #ot-sdk-btn.ot-sdk-show-settings,
  .button {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  input[type='submit'] {
    height: 56px;
    border-radius: 0;
    box-shadow: 0;
  }
  select {
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position:
      right 22px top 50%,
      0 0;
    background-size:
      0.65em auto,
      100%;
    appearance: none;
  }
  textarea {
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
    margin-bottom: 8px;
    background-color: white;
    height: 42px;
    min-height: 128px;
    padding: 16px;
  }
`;

export default ContentStyler;
