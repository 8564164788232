import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { ensureReady, After } from '@jaredpalmer/after';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import App from './pages/App';
import { determineClientLocale, getLocaleData } from './utils/locale';
import registerServiceWorker from './utils/service-worker';
import routes from './routes';
import theme from './theme';
import createApolloClient from './utils/apollo/createApolloClient';
import cookies from './utils/cookies';
import { setUtm } from './utils/utm';
import './fonts.css';

const localeCode = determineClientLocale();
const locale = getLocaleData(localeCode);

// Register the service worker for cache and offline functionality
if (process.env.NODE_ENV !== 'development') {
  registerServiceWorker({ localeCode });
  // save utm-tags to session storage
  setUtm();
}

// Restore the state from server & merge previous from localStorage
const authToken = cookies.getItem('authToken');
const initialData = Object.assign({}, window.__data || {}, {
  auth: {
    token: authToken || null
  }
});
const store = configureStore(initialData);
const client = createApolloClient();

// Monkey-patch window.scrollTo, see https://github.com/jaredpalmer/after.js/blob/57301bbb6a7103fc5438972b484fd9c747a8d7be/src/After.tsx#L38
// We should fork and make a PR so that After.js scrollTo could be controlled e.g. via props
window.scrollTo = () => {};

function renderApp() {
  ensureReady(routes).then((data) => {
    return hydrate(
      <ThemeProvider theme={theme}>
        <IntlProvider
          locale={localeCode}
          messages={locale.messages}
          textComponent="span"
        >
          <BrowserRouter>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <App>
                  <After data={data} routes={routes} store={store} />
                </App>
              </ApolloProvider>
            </Provider>
          </BrowserRouter>
        </IntlProvider>
      </ThemeProvider>,
      document.getElementById('root')
    );
  });
}

renderApp();

if (module.hot) {
  module.hot.accept('./routes', renderApp);
}
