import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComparisonRow from '@/containers/Test/Comparison/ComparisonRow';
import ComparisonReviewItem from '@/containers/Test/Comparison/ComparisonReviewItem';
import ComparisonReviewItemMobile from '@/containers/Test/Comparison/ComparisonReviewItemMobile';
import TestHero from '@/containers/Test/Components/TestHero';
import { getComparisonTree } from '@/containers/Test/test-utils';
import Slider from 'react-slick';
import ProductContent from '@/containers/Test/Components/ProductContent';

/**
 * Styled component for the table.
 */
const Table = styled.div`
  font-size: 14px;
  margin-bottom: ${(props) => (props.$prevIsHero ? '0' : '60px')};
  position: ${(props) => (props.$prevIsHero ? 'relative' : 'static')};
  width: auto;
  overflow-y: auto;
`;

/**
 * Styled component for the desktop container.
 */
const DesktopContainer = styled.div`
  display: none;
  ${(props) => props.theme.media.desktopTablet`
    display: block;
    width: fit-content;
    min-width: 100%;
  `};
`;

/**
 * Styled component for the mobile container.
 */
const MobileContainer = styled.div`
  display: none;
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
`;

/**
 * Styled component for the slider container.
 */
const SliderContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-top: 64px;
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slide {
    padding: 0 6px;
    width: ${(props) => (props.$singleItem ? '100%' : '85vw')};
    min-width: 300px;
  }

  .slick-track {
    display: flex;
    transform: translate3d(0, 0, 0);
    position: relative;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;

      img {
        display: block;
      }
      &.slick-loading img {
        display: none;
      }
      &.dragging img {
        pointer-events: none;
      }
    }
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  // fix dotted outline when focused or active (using tab-index)
  .slick-slide > div > div,
  .slick-slide > div > div:focus,
  .slick-slide > div > div:active {
    outline: none;
  }
  &:hover {
    .SliderPagination {
      opacity: 1;
    }
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translateX(-50%);
    z-index: 9;
    max-width: 90vw;
    width: max-content;

    li {
      display: inline-block;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      background: #d8d8d8;
      margin: 2px 7px;

      button {
        opacity: 0;
      }

      &.slick-active {
        background: ${(props) => props.theme.colors.paleteal};
      }
    }
  }
`;

/**
 * Styled component for the content wrapper.
 */
const ContentWrapper = styled.div`
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
  }
`;

/**
 * Styled component for the table container.
 */
const TableContainer = styled.div`
  display: ${(props) => (props.$isGrid ? 'grid' : 'block')};
  grid-template-columns: max-content auto;
  position: relative;
  ${(props) => props.theme.media.tablet`
    display: block;
  `}
`;

/**
 * Settings for the Slider component.
 */
const settings = {
  slidesToScroll: 1,
  dots: true,
  infinite: false,
  variableWidth: true,
  autoplay: false,
  centerMode: true,
  arrows: false,
  centerPadding: 0,
  useTransform: false,
  responsive: []
};

/**
 * ComparisonTable functional component.
 *
 * @param {Object} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
const ComparisonTable = (props) => {
  const {
    column_definitions,
    references,
    reference_descriptions,
    title,
    excerpt
  } = props.test;
  const { products, previousSection, singleProductPage, accessible, onRemove } =
    props;

  const tableElementRef = useRef();
  const tableTopElementRef = useRef();

  const visibleColumns = column_definitions.filter(
    (col) => col.visible === true
  );

  const compareTree = getComparisonTree(visibleColumns);

  return (
    <Table
      $prevIsHero={previousSection === 'Bodybuilder_HeroUnit'}
      ref={tableElementRef}
    >
      <DesktopContainer>
        <TestHero test={{ title, excerpt }} ref={tableTopElementRef}>
          {products.map((product) => (
            <ComparisonReviewItem
              key={`col-${product.id}`}
              product={product}
              onRemove={onRemove}
              singleProductPage={singleProductPage}
              accessible={accessible}
              reference_descriptions={reference_descriptions}
            />
          ))}
        </TestHero>
        <TableContainer $isGrid={products.length === 1}>
          <ContentWrapper>
            {compareTree.map((row, rowIndex) => (
              <ComparisonRow
                key={`row-${row.name}-${rowIndex}`}
                row={row}
                children={row.children}
                products={products}
                references={references}
                reference_descriptions={reference_descriptions}
              />
            ))}
          </ContentWrapper>
          {accessible && products.length === 1 && (
            <ProductContent product={products[0]} />
          )}
        </TableContainer>
      </DesktopContainer>
      <MobileContainer>
        <TestHero test={{ title, excerpt }} overlap />
        {accessible && products.length === 1 && (
          <ProductContent product={products[0]} />
        )}
        <SliderContainer $singleItem={products.length === 1}>
          <Slider {...settings}>
            {products.map((product, index) => (
              <ComparisonReviewItemMobile
                key={`col-${product.id}`}
                product={product}
                index={index}
                compareTree={compareTree}
                references={references}
                reference_descriptions={reference_descriptions}
                singleProductPage={singleProductPage}
                accessible={accessible}
                onRemove={onRemove}
              />
            ))}
          </Slider>
        </SliderContainer>
      </MobileContainer>
    </Table>
  );
};

ComparisonTable.propTypes = {
  /**
   * Test object.
   */
  test: PropTypes.object.isRequired,

  /**
   * Array of products.
   */
  products: PropTypes.array.isRequired,

  /**
   * Previous section.
   */
  previousSection: PropTypes.string,

  /**
   * Flag indicating if it's a single product page.
   */
  singleProductPage: PropTypes.bool,

  /**
   * Flag indicating if the component is accessible.
   */
  accessible: PropTypes.bool,

  /**
   * Function to remove a product.
   */
  onRemove: PropTypes.func.isRequired
};

export default ComparisonTable;
