import styled from 'styled-components';

const StyledLevel1Nav = styled.ul`
  list-style: none;
  background-color: #fff;
  ${(props) => props.$size === 'small' && `margin-left: auto;`}
  ${(props) => props.theme.media.tablet`
    display: block;
    background-color: transparent;
    width: 100%;
    &:first-of-type {
      ${(props) =>
        !props.$tabletHeader &&
        'border-top: 1px solid' + props.theme.colors.white};
      > li {
        ${(props) =>
          !props.$tabletHeader
            ? props.theme.media.tabletOnly`
          &:nth-child(-n+2){
            display: none;
          }
        `
            : props.theme.media.tabletOnly`
          &:nth-child(n+3){
            display: none;
          }
        `};
      }
    }
    &:nth-child(3) {
      ${(props) =>
        !props.$tabletHeader &&
        'background-color:' +
          props.theme.colors.kuluttaja2021.tummaPetroli[20]};
    }
  `};
  ${(props) =>
    props.$tabletHeader &&
    props.theme.media.tablet`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    `};
  & > li {
    display: inline-block;
    ${(props) =>
      !props.$tabletHeader && props.theme.media.tablet`display: block;`};
  }
`;

const Level1Nav = ({
  children,
  size,
  useColumns,
  category,
  title,
  tabletHeader,
  headerHeight,
  ...rest
}) => {
  return (
    <StyledLevel1Nav $tabletHeader={tabletHeader} $size={size} {...rest}>
      {children}
    </StyledLevel1Nav>
  );
};

export default Level1Nav;
