import { css } from 'styled-components';

const colors = {
  primary: '#777',
  secondary: '#A4A6D0',
  secondarylight: '#F0F1F7',
  black: '#000',
  white: '#ffffff',
  grey: '#79838b',
  greyblue: '#73a6b2',
  lightGrey: '#f1f1f1',
  lightGreyBlue: '#a4a6d0',
  borderGrey: '#a0aeb7',
  borderGreyLight: '#e2e2e2',
  borderGrayMidLight: '#dedede',
  darkGrey: '#373939',
  gunmetal: '#53595c',
  blue: '#A3D1DD',
  mediumBlue: '#D4E5EA',
  lightBlue: '#D0E5EB',
  bluegrey: '#b3d6df',
  bluegreyTwo: '#d0e5eb',
  bluegreyThree: '#c9e9e4',
  lightBlueGrey: '#F0F7F8',
  iceBlue: '#e5f1f4',
  lightTan: '#f9f6b4',
  paleteal: '#91d2c9',
  alert: '#F192AF',
  palePink: '#f0d1df',
  lightBlueGreyTwo: '#c5c6e1',
  kuluttaja2021: {
    army: {
      full: '#004C54',
      80: '#306D73',
      60: '#618F93',
      40: '#91AFB3',
      20: '#C1D1D3',
      10: '#DAE2E2',
      5: '#E6EAEA'
    },
    tummaPetroli: {
      full: '#033858',
      80: '#356079',
      60: '#68889B',
      40: '#9AAFBC',
      20: '#CDD7DE',
      10: '#E6EBEE',
      5: '#F2F5F7'
    },
    tummaViini: {
      full: '#591D35',
      80: '#7A4A5D',
      60: '#9B7786',
      40: '#BDA5AE',
      20: '#DED2D7',
      10: '#EEE8EB',
      5: '#F7F4F5'
    },
    tyrni: {
      full: '#FFA900',
      80: '#FFBA33',
      60: '#FFCB66',
      40: '#FFDD99',
      20: '#FFEECC',
      10: '#FFF6E6',
      5: '#FFFBF2'
    },
    harmaa: {
      full: '#1c1c1c',
      80: '#494949',
      60: '#777777',
      40: '#a4a4a4',
      20: '#d2d2d2',
      10: '#E8E8E8',
      5: '#F4F4F4'
    },
    lila: {
      full: '#2B194F',
      80: '#554772',
      60: '#807595',
      40: '#AAA3B9',
      20: '#D5D1DC',
      10: '#EAE8ED',
      5: '#F4F4F6'
    }
  }
};

const fonts = {
  primary: 'Lato, sans-serif',
  secondary: 'GTWalsheim, sans-serif'
};

const scrSizes = {
  laptop: 1380,
  tablet: 992,
  mobile: 768,
  mobileSmall: 576
};

const scrSizesMQ = {
  desktop: `(min-width: ${scrSizes.laptop + 1}px)`,
  desktopLaptop: `(min-width: ${scrSizes.tablet + 1}px)`,
  desktopTablet: `(min-width: ${scrSizes.mobile + 1}px)`,
  laptop: `(max-width: ${scrSizes.laptop}px)`,
  laptopOnly: `(min-width: ${scrSizes.tablet + 1}px) and (max-width: ${
    scrSizes.laptop
  }px)`,
  tablet: `(max-width: ${scrSizes.tablet}px)`,
  tabletOnly: `(min-width: ${scrSizes.mobileSmall + 1}px) and (max-width: ${
    scrSizes.tablet
  }px)`,
  menuSmallScreen: `(min-width: ${
    scrSizes.tablet + 1
  }px) and (max-width: 1100px)`,
  mobileTabletOnly: `(min-width: ${
    scrSizes.mobileSmall + 1
  }px) and (max-width: ${scrSizes.tablet}px)`,
  mobile: `(max-width: ${scrSizes.mobile}px)`,
  mobileSmall: `(max-width: ${scrSizes.mobileSmall}px)`
};

const media = Object.keys(scrSizesMQ).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${scrSizesMQ[label]} {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default { colors, fonts, media, scrSizes };
