import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Content from '@/browser/Content';
import Container from '@/browser/Container';
import { ActionButton } from '@/components/Buttons/Button';
import CircleImage from '@/components/Images/CircleImage';
import WpImage from '@/components/Images/WpImage';

// Styled component for InfoSection
const InfoSection = styled.div`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[10]};
  padding: 140px 0 80px;
  ${(props) => props.theme.media.tablet`
    padding-top: 48px;
  `};
  ${(props) => props.theme.media.mobile`
    padding-bottom: 58px;
  `};
`;

// Styled component for InfoGrid
const InfoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 36% 18% 18% 18%;
  grid-gap: 2%;
  padding-bottom: 80px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  ${(props) => props.theme.media.tablet`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `};
  ${(props) => props.theme.media.mobileSmall`
    display: block;
    padding-bottom: 16px;
  `};
`;

// Styled component for InfoExcerpt
const InfoExcerpt = styled.p`
  margin-bottom: 30px;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.secondary};
`;

// Styled component for InfoCol
const InfoCol = styled.div`
  ${(props) => props.theme.media.tablet`
    text-align: center;
    display: inline-block;
    max-width: 280px;
    margin-bottom: 16px;
    &:first-of-type{
      margin-bottom: 36px;
      max-width: 768px;
    }
  `} ${(props) => props.theme.media.mobileSmall`
    display: block;
    margin: 0 auto 32px;
    &:first-of-type{
      margin-bottom: 64px;
    }
    img {
      margin-bottom: 16px;
    }
  `};
`;

// Styled component for SymbolContainer
const SymbolContainer = styled.div`
  padding-top: 80px;
  max-width: 960px;
  margin: 0 auto;
  ${(props) => props.theme.media.tablet`
    padding-top: 58px;
    text-align: center;
    h2 {
      max-width: 562px;
      display: inline-block;
      margin-bottom: 48px;
    }
  `};
`;

// Styled component for SymbolGrid
const SymbolGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 2%;
  ${(props) => props.theme.media.tablet`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `};
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
`;

// Styled component for SymbolCol
const SymbolCol = styled.div`
  display: flex;
  align-items: start;
  p {
    margin-top: 0;
  }
  ${(props) => props.theme.media.tablet`
    display: block;
    text-align: center;
    display: inline-block;
    max-width: 280px;
  `};
  ${(props) => props.theme.media.mobile`
    margin-bottom: 32px;
  `};
  img {
    width: 30%;
    margin-right: 22px;
    ${(props) => props.theme.media.tablet`
      margin-bottom: 18px;
      display: inline-block;
      width: 95%;
      max-width: 154px;
      margin-right: 0;
    `};
  }
`;

// Styled component for SymbolAction
const SymbolAction = styled.div`
  text-align: center;
  padding-top: 60px;
  ${(props) => props.theme.media.mobile`
    padding-top: 0;
  `};
`;

/**
 * React component for displaying consumer information.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - The title of the consumer information section.
 * @param {string} props.excerpt - The excerpt or brief description.
 * @param {Object} props.action - The action button details.
 * @param {Array} props.content_blocks - An array of content blocks.
 * @param {Array} props.symbols - An array of symbols.
 * @param {string} props.symbols_title - The title for the symbols section.
 * @param {Object} props.symbols_action - The action button details for the symbols section.
 * @returns {JSX.Element} React component.
 */
const KuluttajaInfo = (props) => {
  const {
    title,
    excerpt,
    action,
    content_blocks,
    symbols,
    symbols_title,
    symbols_action
  } = props;

  return (
    <InfoSection>
      <Container>
        <InfoGrid>
          <InfoCol>
            <h2>{title}</h2>
            <InfoExcerpt>{excerpt}</InfoExcerpt>
            <ActionButton action={action} />
          </InfoCol>
          {content_blocks &&
            content_blocks.map((node, index) => (
              <InfoCol key={index}>
                {node.image && (
                  <CircleImage image={node.image} optimalWidth={156} />
                )}
                {node.content && <Content html={node.content} />}
              </InfoCol>
            ))}
        </InfoGrid>
        <SymbolContainer>
          <h2>{symbols_title}</h2>
          <SymbolGrid>
            {symbols &&
              symbols.map((node, index) => (
                <SymbolCol key={index}>
                  {node.symbol_image && (
                    <WpImage image={node.symbol_image} optimalWidth={154} />
                  )}
                  {node.symbol_description && (
                    <Content html={node.symbol_description} />
                  )}
                </SymbolCol>
              ))}
          </SymbolGrid>
          {symbols_action && (
            <SymbolAction>
              <ActionButton
                action={symbols_action}
                color="kuluttaja2021.harmaa[60]"
              />
            </SymbolAction>
          )}
        </SymbolContainer>
      </Container>
    </InfoSection>
  );
};

KuluttajaInfo.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  content_blocks: PropTypes.array.isRequired,
  symbols: PropTypes.array.isRequired,
  symbols_title: PropTypes.string.isRequired,
  symbols_action: PropTypes.object.isRequired
};

export default KuluttajaInfo;
