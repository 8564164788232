import { ApolloLink } from '@apollo/client';
import cookies from '@/utils/cookies';
const STELLATE_API_KEY = process.env.RAZZLE_STELLATE_API_KEY || false;
const ENABLE_STELLATE =
  /^true$/i.test(process.env.RAZZLE_ENABLE_STELLATE) || false;

const authMiddleware = (initialToken) =>
  new ApolloLink((operation, forward) => {
    const authToken = cookies.getItem('authToken') || initialToken;

    // If user is authenticated, add the x-bypass-cache to the request headers to bypass the Stellate cache
    const autHeaders = authToken
      ? {
          Authorization: `Bearer ${authToken}`
        }
      : {};

    const stellateTokenHeaders =
      STELLATE_API_KEY && ENABLE_STELLATE
        ? {
            'Stellate-Api-Token': STELLATE_API_KEY
          }
        : {};

    // Add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...autHeaders,
        ...stellateTokenHeaders
      }
    }));

    return forward(operation);
  });

export default authMiddleware;
