import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SeoHelmet from '@/components/Yoast/SeoHelmet';
import { connect } from 'react-redux';
import { store } from '@/store/reducers/auth';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Tabs, Tab } from '@/components/Navigation/Subnavigation';
import { Dropdown } from '@/components/Navigation/DropdownSubNav';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import SinglePage from '@/containers/Page/SinglePage';
import NotFound from '@/components/NotFound/NotFound';
import QUERY_GET_PAGE_BODYBUILDER from '@/graphql/wordpress/pages/queryGetPageBodybuilder';
import styled from 'styled-components';
import _get from 'lodash.get';

const MqDropdown = styled.div`
  display: none;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
`;

const MqTabs = styled.div`
  display: block;
  ${(props) => props.theme.media.tablet`
    display: none;
  `};
`;

/**
 * Page component renders a WordPress page.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.displayChildren - Flag to display child pages.
 * @param {Object} props.match - React Router match object.
 * @param {string} props.slug - Slug of the page.
 * @param {Object} props.location - React Router location object.
 * @param {Object} [props.auth] - Authentication information from Redux store.
 * @returns {JSX.Element} React component.
 */
const Page = ({ displayChildren, match, slug, location, auth }) => {
  const [locationMatch, setLocationMatch] = useState({});

  useEffect(() => {
    // Save page to localstorage for login redirect on match change
    if (JSON.stringify(locationMatch) !== JSON.stringify(match)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    if (typeof localStorage !== 'undefined') {
      const { pathname, search } = location;
      localStorage.setItem('lastPageBeforeLogin', pathname + search);
    }

    setLocationMatch(match);
  }, [match, location]);

  /**
   * Sorts an array of page children based on menu order.
   *
   * @param {Array} children - Array of child pages.
   * @returns {Array|boolean} Sorted array of child pages or false if no children.
   */
  const sortChildren = (children) => {
    if (!children || !children.length) {
      return false;
    }
    // Sort items by menu order
    return children.slice().sort((a, b) => a.menuOrder - b.menuOrder);
  };

  return (
    <>
      <QueryHandler
        query={QUERY_GET_PAGE_BODYBUILDER}
        variables={{ slug }}
        loadmore
      >
        {({ page }) => {
          if (!page) {
            return <NotFound />;
          }
          const tabColor = _get(page, 'acf[0]background_color');
          const childPages = sortChildren(_get(page, 'children.nodes'));
          const { title, seo } = page;

          return (
            <>
              <SeoHelmet seo={seo} fallbackTitle={title} />
              {displayChildren && childPages && (
                <MqTabs>
                  <Tabs>
                    <Tab exact={true} to={match.url} bgcolor={tabColor}>
                      {page.title}
                    </Tab>
                    {childPages.map((node) => (
                      <Tab
                        key={node.id}
                        to={`${match.url}/${node.slug}`}
                        bgcolor={tabColor}
                      >
                        {node.title}
                      </Tab>
                    ))}
                  </Tabs>
                </MqTabs>
              )}

              {displayChildren && childPages && (
                <MqDropdown>
                  <Dropdown
                    pages={page.children.nodes}
                    parentTo={match.url}
                    parentTitle={page.title}
                  />
                </MqDropdown>
              )}

              <Switch>
                {childPages &&
                  childPages.map((node) => (
                    <Route
                      key={node.id}
                      path={`${match.url}/${node.slug}`}
                      children={
                        <Page
                          slug={node.uri}
                          match={match}
                          location={location}
                        />
                      }
                    />
                  ))}

                <Route
                  children={
                    <SinglePage
                      page={page}
                      match={match}
                      location={location}
                      auth={auth}
                    />
                  }
                />
              </Switch>
            </>
          );
        }}
      </QueryHandler>
    </>
  );
};

// Prop types validation
Page.propTypes = {
  displayChildren: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  auth: PropTypes.object
};

// Default prop values
Page.defaultProps = {
  displayChildren: false
};

export default connect((state) => ({ auth: state.auth }), { store })(
  withRouter(Page)
);
