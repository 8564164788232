import React from 'react';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
    console.error('Error was thrown', error);
  }

  render() {
    if (this.state.error !== null) {
      return <ErrorHandler error={this.state.error} />;
    }
    return this.props.children;
  }
}
