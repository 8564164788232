import styled from 'styled-components';
import Container from '../../browser/Container';

const SearchFacetBox = styled(Container)`
  border: 16px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  background-color: #fff;
  padding: 22px;
  ${(props) => props.theme.media.tablet`
    padding: 16px;
    border-width: 8px;
    width: 100%;
  `};
`;

const MainFacets = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$fullWidth ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'};
  grid-gap: 24px 2%;
  ${(props) => props.theme.media.tablet`
    grid-template-columns: repeat(2,1fr);
  `} ${(props) => props.theme.media.mobile`
    grid-template-columns: auto;
    grid-gap: 24px 0;
  `};
`;

export { SearchFacetBox, MainFacets };
