/**
 * This is a fragment of the "BodyBuilder" Category List block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_CATEGORY_LIST = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment CategoryList on Bodybuilder_CategoryList {
    display_blacklist
    blacklist_image {
      ...FeaturedImageModel
    }
    post_count
    categories {
      id
      categoryId
      name
      slug
      icon {
        id
        title
        sourceUrl
        link
        altText
        uri
      }
      category_page {
        link
        uri
      }
    }
    magazine_categories {
      id
      magazineId
      name
      slug
      category_page {
        link
        uri
      }
    }
  }
`;

export default FRAGMENT_CATEGORY_LIST;
