import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ErrorBoundary from '@/containers/ErrorBoundary/ErrorBoundary';
import configFi from '@/i18n/config.fi';
import config from '@/config';
import { PageContainer, PageContentContainer } from '@/browser/PageContainer';
import StagingBadge from '@/components/StagingBadge';
import Header from '@/containers/Header/Header';
import Footer from '@/containers/Footer/Footer';
import CocartDefaultCookieRemover from '@/containers/CocartDefaultCookieRemover';
import UserMembershipsContainer from '@/containers/User/UserMembershipsContainer';
import { asyncComponent } from '@jaredpalmer/after';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch,
  faArrowRight,
  faUser,
  faHome,
  faAngleRight,
  faAngleDown,
  faSignOut,
  faPen,
  faCheck,
  faShoppingBasket,
  faTrashAlt,
  faExternalLink,
  faLockAlt,
  faLink,
  faSave,
  faEnvelope,
  faPrint,
  faEye,
  faEyeSlash
} from '@fortawesome/pro-light-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

library.add(
  // fal
  faSearch,
  faArrowRight,
  faUser,
  faHome,
  faAngleRight,
  faAngleDown,
  faSignOut,
  faPen,
  faCheck,
  faShoppingBasket,
  faTrashAlt,
  faExternalLink,
  faLockAlt,
  faLink,
  faSave,
  faEnvelope,
  faPrint,
  faEye,
  faEyeSlash,
  // fab
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
);

const API_HOST =
  config.app.apiHost?.replace(new RegExp(/^http\:\/\/|^https\:\/\//i), '') ||
  '';

const Popups = asyncComponent({
  loader: () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "popups" */
      '../components/Popups/Popups'
    ),
  chunkName: 'popups',
  Placeholder: () => <></>
});

/**
 * The main application component.
 * @returns {JSX.Element} The main application JSX element.
 */
const App = ({ children }) => (
  <PageContainer>
    <Helmet {...configFi.head} />
    <Header />
    <UserMembershipsContainer />
    <PageContentContainer>
      <ErrorBoundary>{children}</ErrorBoundary>
    </PageContentContainer>
    <Popups />
    <Footer />
    {/* // TODO: this can be removed when we don't need to clean the old default cocart cookie */}
    <CocartDefaultCookieRemover />
    {API_HOST.startsWith('staging') && <StagingBadge />}
  </PageContainer>
);

App.propTypes = {
  children: PropTypes.any
};

export default App;
