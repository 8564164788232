import styled from 'styled-components';

const StyledLevel5NavNode = styled.div`
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  text-decoration: none;
  text-transform: none;
`;

const Level5NavNode = ({
  children,
  active,
  size,
  tabletHeader,
  hasChildren,
  ...rest
}) => {
  return <StyledLevel5NavNode {...rest}>{children}</StyledLevel5NavNode>;
};

export default Level5NavNode;
