/**
 * This is a fragment of the "BodyBuilder" Hero Unit block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_HERO_UNIT = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment HeroUnit on Bodybuilder_HeroUnit {
    title
    title_color
    content
    lehtiluukku_issue_id
    lehtiluukku_button_text
    magazine_id
    background_color
    action {
      title
      url
      target
    }
    secondary_action {
      title
      url
      target
    }
    image {
      ...FeaturedImageModel
    }
    background_image {
      ...FeaturedImageModel
    }
  }
`;

export default FRAGMENT_HERO_UNIT;
