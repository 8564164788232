/**
 * This is a fragment of the "BodyBuilder" Kuluttaja Info block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_KULUTTAJA_INFO = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment KuluttajaInfo on Bodybuilder_KuluttajaInfo {
    title
    excerpt
    symbols_title
    action {
      title
      url
      target
    }
    content_blocks {
      content
      image {
        ...FeaturedImageModel
      }
    }
    symbols {
      symbol_description
      symbol_image {
        ...FeaturedImageModel
      }
    }
    symbols_action {
      title
      url
      target
    }
  }
`;

export default FRAGMENT_KULUTTAJA_INFO;
