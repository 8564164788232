import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled.div`
  cursor: pointer;
`;

class ReviewProductLink extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  };
  handleSingleProduct = () => {
    const { history, location, reviewId } = this.props;
    const path = location.pathname.includes('/compare')
      ? location.pathname.replace('/compare', '/product')
      : location.pathname + '/product';
    const selected = location.search ? '&' + location.search.substr(1) : '';
    history.push(path + '?id=' + reviewId + selected);
  };
  render() {
    const { children, history, location, reviewId, ...rest } = this.props;
    return (
      <Link onClick={this.handleSingleProduct} {...rest}>
        {children}
      </Link>
    );
  }
}

export default withRouter(ReviewProductLink);
