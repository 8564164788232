import cookies from './cookies';
import { COOKIE_COCART_API_KEY } from '@/configs/cocart';

/**
 * Checks if cart key is set to cookies and returns cart URL params.
 *
 * @returns {string} - Cart URL params.
 */
export default function getCartUrlParams() {
  const cocartApiKey = cookies.getItem(COOKIE_COCART_API_KEY);
  const cocartParams = cocartApiKey ? `?cart_key=${cocartApiKey}` : '';

  return cocartParams;
}
