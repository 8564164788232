import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Multiselect } from '@/components/Form/FormStyler';
import { FormattedMessage } from 'react-intl';
import Content from '@/browser/Content';
import CartAPI from '@/utils/cartApi';
import Price from '@/components/Product/ProductPrice';
import _get from 'lodash.get';

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 20% 20% min-content;
  align-content: center;
  line-height: 1.5em;
  width: 100%;

  ${(props) =>
    !props.$plain &&
    props.theme.media.tablet`
    grid-template-columns: 30% auto min-content;
    grid-template-areas: "a a a" "b c d";
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
  `};

  ${(props) =>
    props.$plain &&
    `
    grid-template-columns: auto 9rem min-content;
    column-gap: 2%;
    font-size: 17px;
  `};
`;

const RowItem = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 1em 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;

  ${(props) =>
    props.$plain &&
    `
    padding: 0.5em 0;
    border: none;
  `};

  ${(props) =>
    props.$plain &&
    props.theme.media.tablet`
    padding: 0;
  `};

  ${(props) =>
    !props.$plain &&
    props.theme.media.tablet`
    border: none;
  `};
`;

const ProductTitle = styled(RowItem)`
  ${(props) =>
    props.$plain &&
    props.theme.media.tablet`
      grid-column-start: 1;
      grid-column-end: 3;
  `};

  ${(props) =>
    !props.$plain &&
    props.theme.media.tablet`
    grid-area: a;
    flex-direction: row;
  `};
`;

const ProductQuantity = styled(RowItem)`
  ${(props) => props.theme.media.tablet`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const ProductPrice = styled(RowItem)`
  ${(props) =>
    props.$plain &&
    props.theme.media.tablet`
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
  `};

  ${(props) =>
    !props.$plain &&
    props.theme.media.tablet`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const ProductRemove = styled(RowItem)`
  ${(props) =>
    props.$plain &&
    props.theme.media.tablet`
      grid-row-start: 1;
      grid-row-end: 3;
  `};
`;

const Image = styled.img`
  display: inline-block;
  margin-right: 1em;
  max-width: 150px;
`;

const Remove = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  ${(props) => props.theme.media.tablet`
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }
  `};
`;

const MobileLabel = styled.div`
  display: none;
  font-size: 12px;
  letter-spacing: 1.5px;
  margin-bottom: 8px;
  text-transform: uppercase;
  ${(props) => props.theme.media.tablet`
    display: block;
  `};
`;

export default class CartItems extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  state = {
    product: {}
  };

  async componentDidMount() {
    const { item } = this.props;
    const product = await CartAPI.getProductById(item.product_id);
    this.setState({ product });
  }

  render() {
    const { product } = this.state;
    const { item, plain } = this.props;
    if (Object.keys(product).length === 0) {
      return null;
    }
    item.product = product;
    // WooCommerce is funny
    const values = [1, 2, 3, 4, 5];
    const options = values.map((value) => ({ value: value, label: value }));

    const itemQuantity = !_get(item, 'product.product.virtual') ? (
      <Multiselect
        value={{ label: item.quantity, value: item.quantity }}
        options={options}
        multi={false}
        onChange={(opt) => this.props.onUpdate(item, opt.value)}
      />
    ) : (
      <div>{item.quantity}</div>
    );
    return (
      <RowContainer $plain={plain}>
        {!plain && (
          <MobileLabel>
            <FormattedMessage id="order.label.items" />
          </MobileLabel>
        )}
        <Row $plain={plain}>
          <ProductTitle $plain={plain}>
            {!plain && item.product_image && (
              <Image src={item.product_image} alt={item.product_name} />
            )}
            <div>
              <b>
                {plain && item.quantity > 1 && `${item.quantity} x `}
                {item.product_name}
              </b>
              {!plain && <Content html={item.product.description} />}
            </div>
          </ProductTitle>
          {!plain && (
            <ProductQuantity>
              <MobileLabel>
                <FormattedMessage id="order.label.quantity" />
              </MobileLabel>
              {itemQuantity}
            </ProductQuantity>
          )}
          <ProductPrice $plain={plain}>
            {!plain && (
              <MobileLabel>
                <FormattedMessage id="order.label.price" />
              </MobileLabel>
            )}
            <Price product={item.product.product} color={plain && 'darkGrey'} />
          </ProductPrice>
          <ProductRemove $plain={plain}>
            <Remove
              icon={['fal', 'trash-alt']}
              onClick={() => this.props.onRemove(item)}
            />
          </ProductRemove>
        </Row>
      </RowContainer>
    );
  }
}
