/**
 * This is a fragment of the "BodyBuilder" Black List block.
 */

import gql from 'graphql-tag';

const FRAGMENT_BLACK_LIST = gql`
  fragment BlackList on Bodybuilder_BlackList {
    entries {
      id
      dateGmt
      company
      industry
      location
      reviews {
        title
        date
        content
        solution
      }
    }
  }
`;

export default FRAGMENT_BLACK_LIST;
