import React from 'react';
import styled from 'styled-components';

const CheckRadioContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  background-color: #fff;
  min-height: 24px;
  height: 24px;
  min-width: 24px;
  width: 24px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  border-radius: ${(props) => (props.$type === 'radio' ? '50%' : '0')};
  svg {
    width: 100%;
  }
  input {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    opacity: 0;
  }
`;
const Check = styled.path`
  stroke-dasharray: 26;
  stroke-dashoffset: ${(props) => (props.$checked ? 0 : 26)};
  transition: all 100ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  stroke-width: 1.5;
  stroke: ${(props) => props.theme.colors.primary};
  fill: none;
`;

const Radio = styled.circle`
  transition: transform 100ms;
  transform-origin: 50% 50%;
  transform: ${(props) => (props.$checked ? 'scale(1)' : 'scale(0)')};
  fill: ${(props) => props.theme.colors.primary};
`;

export default class CheckRadio extends React.Component {
  // REMEMBER SET TYPE FOR DEFAULT INPUT BEHAVIOUR
  handleClick = () => {
    if (!this.props.type) {
      this.props.onChange(!this.props.checked);
    }
  };
  render() {
    const { checked, type, onChange, ...rest } = this.props;
    return (
      <CheckRadioContainer
        onClick={!type ? this.handleClick : () => {}}
        $type={type}
      >
        {type && (
          <input
            defaultChecked={checked}
            type={type}
            onChange={type ? onChange : () => {}}
            {...rest}
          />
        )}
        <svg viewBox="0 0 24 24">
          {type === 'radio' ? (
            <Radio $checked={this.props.checked} cx="12" cy="12" r="6" />
          ) : (
            <Check $checked={this.props.checked} d="M 3,14 8,19 21,6" />
          )}
        </svg>
      </CheckRadioContainer>
    );
  }
}
