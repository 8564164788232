import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NotFound from '@/components/NotFound/NotFound';
import Bodybuilder from '@/containers/Bodybuilder/Bodybuilder';
import Paywall from '@/components/Paywall/Paywall';
import { gaCreateEvent, gaSetupCustomPageviews } from '@/utils/gaUtils';
import { asyncComponent } from '@jaredpalmer/after';

/**
 * Functional component for rendering a single page.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.page - The page data.
 * @param {Object} props.auth - The authentication information.
 * @param {Object} props.location - The current location object.
 * @param {Object} props.match - The current match object.
 * @returns {JSX.Element} React component.
 */
const SinglePage = ({ page, auth, location, match }) => {
  // Set up Google Tag Manager data on component mount
  useEffect(() => {
    gaCreateEvent(gaSetupCustomPageviews(page, location, auth));
  }, [page, location, auth]);

  // Render NotFound component if no page data is available
  if (!page) {
    return <NotFound />;
  }

  const { title } = page;
  const { accessible } = page.paywall;

  // Define a HeroUnit component using asyncComponent
  const HeroUnit = asyncComponent({
    loader: () =>
      import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "herounit-chunk" */
        '../Bodybuilder/components/HeroUnit'
      ),
    chunkName: 'herounit-chunk',
    Placeholder: () => <></>
  });

  return (
    <div>
      {/* Display simple banner & paywall on restricted content */}
      {!accessible && (
        <HeroUnit
          title={title}
          title_color="#fff"
          background_color="#033858"
          background_image={false}
          image={false}
        />
      )}
      {/* For users that have access, render full content */}
      {accessible && (
        <Bodybuilder
          sections={page.acf}
          paywall={page.paywall}
          match={match}
          location={location}
        />
      )}
      {/* Render Paywall component with paywall data */}
      <Paywall {...page.paywall} />
    </div>
  );
};

// Prop types validation
SinglePage.propTypes = {
  page: PropTypes.object.isRequired,
  auth: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default SinglePage;
