import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { NormalButton } from '../../components/Buttons/Button';
import facet from './Facet';

const ButtonsContainer = styled.div`
  grid-column: span 3;
  display: flex;
  justify-content: flex-end;
  ${(props) => props.theme.media.tablet`
    grid-column: span 2;
  `};
  ${(props) => props.theme.media.mobile`
    grid-column: span 1;
  `};
  ${(props) => props.theme.media.mobileSmall`
    display: block;
    button {
      display: block;
      width: 100%;
    }
  `}
`;

class SearchButtons extends Component {
  static propTypes = {
    doSearch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  resetFilters = () => {
    this.props.history.push(this.props.location.pathname);
  };

  render() {
    return (
      <ButtonsContainer>
        <NormalButton onClick={this.resetFilters} $buttonstyle="plain">
          <FormattedMessage id="search.facet.reset" />
        </NormalButton>
      </ButtonsContainer>
    );
  }
}

const SEARCH_ROOT = { currentPage: true };
export default facet(null, SEARCH_ROOT)(withRouter(SearchButtons));
