import React, { Component } from 'react';
import styled from 'styled-components';
import WpImage from '../../../components/Images/WpImage';
import ReviewProductLink from '../Components/ReviewProductLink';

const ReviewItem = styled(ReviewProductLink)`
  margin-right: 16px;
  display: inline-block;
  overflow: hidden;
  min-width: 90px;
  &.zoom-enter {
    opacity: 0.01;
    transform: scale(0.01);
  }
  &.zoom-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: all 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  &.zoom-exit {
    transform: scale(1);
    opacity: 1;
  }
  &.zoom-exit-active {
    opacity: 0.01;
    transform: scale(0.01);
    transition: all 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;

const ProductImage = styled(WpImage)`
  height: 90px;
  width: 90px;
  padding: 5px;
  background-color: #fff;
  max-height: inherit;
  max-width: inherit;
  object-fit: contain;
  object-position: center center;
`;

class ProductReviewItemThumb extends Component {
  render() {
    const { review } = this.props;
    return (
      <ReviewItem reviewId={review.product_reviewId}>
        <ProductImage
          className="product-thumb"
          image={review.featuredImage}
          placeholder
        />
      </ReviewItem>
    );
  }
}

export default ProductReviewItemThumb;
