import React from 'react';
import { StyledButton } from './Button';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash.get';

const OrderButton = ({ color, txtcolor }) => {
  return (
    <StyledButton
      className="tilaa-button"
      to="/tilaa-kuluttaja"
      $color={color}
      $txtcolor={txtcolor}
    >
      <FormattedMessage id="action.subscribe" />
    </StyledButton>
  );
};

export default OrderButton;
