import React from 'react';
import styled from 'styled-components';
import WpImage from './WpImage';
import Content from '@/browser/Content';

const ImgContainer = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  width: min-content;
  .caption-image__image {
    width: auto;
    height: 450px;
    display: block;
    max-width: inherit;
    margin-bottom: 16px;
  }
`;

const Caption = styled(Content)`
  font-size: 11px;
  line-height: 1.55;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grey};
  white-space: wrap;
  display: inline;
`;

const CaptionedImage = ({ image, ...rest }) => {
  if (!image) {
    return null;
  }

  return (
    <ImgContainer className="caption-image" {...rest}>
      <WpImage image={image} className="caption-image__image" />
      {image.caption && (
        <Caption className="caption-image__caption" html={image.caption} />
      )}
    </ImgContainer>
  );
};

export default CaptionedImage;
