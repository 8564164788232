/**
 * This is a fragment of the "BodyBuilder" Magazine List block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_MAGAZINE_LIST = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment MagazineList on Bodybuilder_MagazineList {
    magazine_categories {
      id
      name
      cover_image {
        ...FeaturedImageModel
      }
      category_page {
        link
        uri
      }
      parent {
        node {
          id
          name
        }
      }
    }
  }
`;

export default FRAGMENT_MAGAZINE_LIST;
