import React from 'react';
import styled from 'styled-components';

const Icon = styled.img`
  display: inline-block;
  height: 0.9em;
  width: 0.9em;
  object-fit: contain;
  object-position: center center;
  position: relative;
  top: -0.2em;
  left: 0.3em;
  margin-right: 1em;
  transform: scale(2.4);
`;

const CategoryIcon = (props) => {
  if (!props.icon) {
    return null;
  }
  return <Icon src={props.icon.sourceUrl} alt="Category icon" />;
};

export default CategoryIcon;
