import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NormalButton } from '../../components/Buttons/Button';
import facet from './Facet';

const SearchSubmit = styled.button`
  border: none;
  background: none;
  padding: 0;
`;

const faIconStyle = {
  fontSize: '24px',
  margin: '16px'
};

class SearchButtonBase extends Component {
  static propTypes = {
    doSearch: PropTypes.func.isRequired,
    icon: PropTypes.string
  };

  render() {
    const { icon, color } = this.props;
    if (icon) {
      return (
        <SearchSubmit onClick={this.props.doSearch}>
          <FontAwesomeIcon icon={['fal', icon]} style={faIconStyle} />
        </SearchSubmit>
      );
    }

    return (
      <NormalButton onClick={this.props.doSearch} $color={color}>
        <FormattedMessage id="action.search" />
      </NormalButton>
    );
  }
}

class SearchButton extends Component {
  static propTypes = {
    searchPage: PropTypes.string
  };

  constructor(props) {
    super(props);
    const { searchPage } = this.props;
    const pageProps = searchPage ? `/fi${searchPage}` : { currentPage: true };
    // NOTE Facet component is constructed here thus 'param' and 'searchPage' props cannot be dynamic
    // We don't want to construct this component everytime filter values change, could cause infinite loop
    this.facetComponent = facet(null, pageProps)(SearchButtonBase);
  }

  render() {
    const { searchPage, ...rest } = this.props;
    const TextFacetComponent = this.facetComponent;
    return <TextFacetComponent {...rest} />;
  }
}

export default SearchButton;
