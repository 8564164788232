import styled from 'styled-components';

const GreyBox = styled.div`
  border: 16px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[10]};
  padding: 2em;
  ${(props) => props.theme.media.tablet`
    border-width: 8px;
    padding: 2em 1em;
  `};
`;

export default GreyBox;
