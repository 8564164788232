import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Button = styled.button`
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 1em;
  border: none;
  background: none;
  padding: 16px;
  width: calc(100% - 20px);
  z-index: 14;
  background: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[60]};
  color: white;
  img {
    height: 36px;
    display: inline-block;
  }
  span {
    margin-left: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CodeScannerGetTutorialButton = ({ clickTutorial }) => {
  return (
    <Button onClick={clickTutorial}>
      <FormattedMessage id="codeScanner.goToTutorialButton" />
    </Button>
  );
};

CodeScannerGetTutorialButton.propTypes = {
  clickTutorial: PropTypes.func.isRequired
};

export default CodeScannerGetTutorialButton;
