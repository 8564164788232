import styled, { css } from 'styled-components';
import { Field } from 'formik';
import { asyncComponent } from '@jaredpalmer/after';

const Select = asyncComponent({
  loader: () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "react-select" */
      'react-select'
    ),
  chunkName: 'react-select',
  Placeholder: () => <></>
});

const Label = styled.label`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => (props.$caps ? '14px' : 'inherit')};
  font-weight: ${(props) => (props.$caps ? 'bold' : 'normal')};
  margin-bottom: 8px;
  display: inline-block;
  text-transform: ${(props) => (props.$caps ? 'uppercase' : 'none')};
`;

const Multiselect = styled(Select).attrs((props) => ({
  isMulti: typeof props.multi !== 'undefined' ? props.multi : true,
  className: 'react-select-container',
  classNamePrefix: 'react-select',
  placeholder: 'Valitse...'
}))`
  .react-select {
    &__control {
      border-radius: 0;
      padding: 4px;
      box-shadow: none;
      border-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
      &--is-focused {
        border-color: ${(props) => props.theme.colors.primary};
        &:hover {
          border-color: ${(props) => props.theme.colors.primary};
        }
      }
    }
    &__multi-value {
      border-radius: 0;
      padding: 4px;
    }
    &__input {
      padding: 4px 0;
      border-radius: 0;
      input {
        height: inherit;
      }
    }
    &__menu {
      border-radius: 0;
      top: 42px;
    }
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  margin-bottom: 8px;
  background-color: white;
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.secondary};
  border-radius: 0;
  box-shadow: 0;
  font-size: 16px;
`;
const FieldStyles = css`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  margin-bottom: 8px;
  background-color: white;
  height: 42px;
  padding-left: 16px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.secondary};
  border-radius: 0;
  box-shadow: 0;
  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledField = styled(Field)`
  ${FieldStyles}
`;

const StyledInput = styled.input`
  ${FieldStyles}
`;

const FieldGroup = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
`;

const FieldDesc = styled.p`
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli.full};
  }
`;

const Colspan = styled.div`
  grid-column: 1 / 3;
  ${(props) => props.theme.media.mobile`
    grid-column: inherit;
  `}
`;

const Separator = styled.div`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.harmaa[20]};
  grid-column: 1 / 3;
  ${(props) => props.theme.media.mobile`
    grid-column: inherit;
  `}
`;

const CheckRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${(props) => (props.$values > 2 ? 'span 2' : 'span 1')};
  ${(props) => props.theme.media.mobile`
    grid-column: span 1;
  `}
`;

const CheckRadioRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    ${(props) => props.theme.media.mobile`
      margin-bottom: 8px;
    `}
  }
  ${(props) => props.theme.media.mobile`
    display: block;
  `}
`;
const CheckRadioCol = styled.div`
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export {
  Multiselect,
  Label,
  Textarea,
  StyledField,
  StyledInput,
  FieldDesc,
  Colspan,
  Separator,
  CheckRadioWrapper,
  CheckRadioRow,
  CheckRadioCol,
  FieldGroup
};
