/**
 * This is a fragment of the Content Node (Page & Post) basic fields.
 */

import gql from 'graphql-tag';

const FRAGMENT_CONTENT_NODE_BASIC_MODEL = gql`
  fragment ContentNodeBasicModel on ContentNode {
    id
    link
    uri
    dateGmt
  }
`;

export default FRAGMENT_CONTENT_NODE_BASIC_MODEL;
