/**
 * This is a fragment of the Category with children object model.
 */

import gql from 'graphql-tag';

const FRAGMENT_CATEGORY_CHILDREN_MODEL = gql`
  fragment CategoryChildrenModel on Category {
    id
    name
    category_page {
      link
      uri
    }
    children {
      nodes {
        id
        name
        category_page {
          link
          uri
        }
      }
    }
  }
`;

export default FRAGMENT_CATEGORY_CHILDREN_MODEL;
