import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookies from '@/utils/cookies';
import CartAPI from '@/utils/cartApi';
import { OutsideClickHandler } from '@/redandblue/components';
import SideCart from './SideCart';
import CloseButton from '@/components/Buttons/CloseButton';
import CartButton from './CartButton';

const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-blend-mode: multiply;
  background-color: rgba(20, 20, 20, 0.7);
  z-index: 999;

  ${(props) => props.theme.media.tablet`
    background-color: transparent;
  `};
`;

const Close = styled(CloseButton)`
  width: 36px;
  height: 36px;
  &:after,
  &:before {
    border-radius: 1px;
    height: 3px;
    width: 28px;
    top: 16px;
  }
`;

class MiniCart extends React.Component {
  static propTypes = {
    cart: PropTypes.object.isRequired
  };
  state = {
    loading: true,
    cartCount: 0,
    showSideCart: false,
    swing: 'none'
  };
  async componentDidMount() {
    // We just trigger a load to WordPress, which will re-set the cookies
    const apiCount = await CartAPI.getCount();
    // Changing the state will re-render this component, and use the value from cookie
    this.setState({
      cartCount: apiCount,
      loading: false
    });
    this.swing();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      Number(prevState.cartCount) < Number(this.state.cartCount) &&
      Number(this.state.cartCount)
    ) {
      this.setState({
        showSideCart: true
      });
      this.swing();
    }
  }
  async UNSAFE_componentWillUpdate(prevProps, prevState) {
    const apiCount = await CartAPI.getCount();
    if (prevState.cartCount !== apiCount) {
      this.setState({
        cartCount: apiCount
      });
    }
  }
  swing() {
    this.setState({ swing: 'swing' });
    setTimeout(() => this.setState({ swing: 'none' }), 1000);
  }
  showCart(event) {
    event.preventDefault();
    this.setState({
      showSideCart: true
    });
  }
  hideCart() {
    this.setState({
      showSideCart: false
    });
  }
  render() {
    if (this.state.loading) {
      return null;
    }

    const itemCount = cookies.getItem('woocommerce_items_in_cart');
    if (itemCount === 0 || this.state.cartCount === '0') {
      return null;
    }

    if (this.state.cartCount.includes('Fatal error')) {
      if (process.env.NODE_ENV === 'development') {
        console.error('[cartCount] Fatal error: ', this.state.cartCount);
      }
      return null;
    }

    return (
      <div>
        <CartButton
          onClick={(e) => this.showCart(e)}
          swing={this.state.swing}
          to="/kauppa/ostoskori"
          cartCount={this.state.cartCount}
        />
        {this.state.showSideCart && (
          <Overlay>
            <OutsideClickHandler onOutsideClick={() => this.hideCart()}>
              <SideCart active={this.state.showSideCart}>
                <Close onClick={() => this.hideCart()} type="button" />
              </SideCart>
            </OutsideClickHandler>
          </Overlay>
        )}
      </div>
    );
  }
}

// NOTE On add-to-cart we increment a counter in redux to trigger a re-render.
// Real item count we get from WooCommerce cookies instead of redux.
// Some browsers also have cookies.onChanged API but is not widely supported yet,
// so this should be more reliable way to re-render this component.
export default connect((state) => ({ cart: state.cart }))(MiniCart);
