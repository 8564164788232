import styled from 'styled-components';
import WpImage from '../Images/WpImage';

const CircleImage = styled(WpImage)`
  object-fit: cover;
  object-position: center center;
  height: 156px;
  width: 156px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 30px;
`;

export default CircleImage;
