import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import CodeScannerIcon from './CodeScannerIcon.svg';

const Button = styled.button`
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 1em;
  border: none;
  background: none;
  padding: 16px;
  width: calc(100% - 20px);
  z-index: 14;
  color: white;

  background: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[60]};
  img {
    height: 36px;
    display: inline-block;
    filter: invert(1);
  }
  span {
    margin-left: 20px;
    font-family: ${(props) => props.theme.fonts.secondary};
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

const CodeScannerTutorialButton = ({ clickScan }) => {
  return (
    <Button onClick={clickScan}>
      <img
        src={CodeScannerIcon}
        alt="Skannaa koodi"
        aria-label="Skannaa koodi"
      />
      <FormattedMessage id="codeScanner.tutorialButton" />
    </Button>
  );
};

CodeScannerTutorialButton.propTypes = {
  clickScan: PropTypes.func.isRequired
};

export default CodeScannerTutorialButton;
