/**
 * This is a fragment of the "BodyBuilder" Article Head Lift block.
 */

import gql from 'graphql-tag';
import FRAGMENT_CONTENT_NODE_BASIC_MODEL from '@/graphql/wordpress/_fragments/fragmentContentNodeBasicModel';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_ARTICLE_HEAD_LIFT = gql`
  ${FRAGMENT_CONTENT_NODE_BASIC_MODEL}
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment ArticleHeadLift on Bodybuilder_ArticleHeadLift {
    content
    article {
      ...ContentNodeBasicModel
      title
      extra {
        article_author
        author_email
        author_image {
          ...FeaturedImageModel
        }
      }
      author {
        node {
          name
          email
          avatar(size: 214) {
            url
          }
        }
      }
      categories(where: { childless: true }) {
        nodes {
          slug
          name
        }
      }
    }
  }
`;

export default FRAGMENT_ARTICLE_HEAD_LIFT;
