/**
 * This is a fragment of the "BodyBuilder" Magazine Lift block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_MAGAZINE_LIFT = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment MagazineLift on Bodybuilder_MagazineLift {
    magazine_category {
      id
      name
      link
      uri
      cover_image {
        ...FeaturedImageModel
      }
      category_page {
        link
        uri
      }
      product {
        id
        title
        price
        price_unit
        link
        uri
      }
      subscription_product {
        id
        title
        price
        price_unit
        link
        uri
      }
    }
  }
`;

export default FRAGMENT_MAGAZINE_LIFT;
