export function isLaptop() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(max-width: 1380px)').matches;
}

export function isTablet() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(max-width: 992px)').matches;
}

export function isMobile() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(max-width: 768px)').matches;
}

export function isMobileSmall() {
  if (typeof window === 'undefined') {
    return false;
  }
  return window.matchMedia('(max-width: 576px)').matches;
}
