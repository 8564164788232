import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from '@/components/Form/FormStyler';
import Range from '@/components/Form/Range';
import facet from './Facet';
import { getDecimalCounter } from '@/containers/Test/test-utils';

class RangeFacet extends Component {
  static propTypes = {
    attribute: PropTypes.string.isRequired,
    applyFilter: PropTypes.func.isRequired,
    displayLabel: PropTypes.bool,
    initialValue: PropTypes.string,
    column: PropTypes.object,
    values: PropTypes.array.isRequired,
    labels: PropTypes.array
  };
  static defaultProps = {
    displayLabel: true
  };

  constructor(props) {
    super(props);

    const decimalType = this.getFixedDecimalType(props.column.type);
    const decimalCounter = getDecimalCounter(decimalType);

    // Convert commas to dots in value arr
    const values = props.values.map((v) => (v ? v.replace(/,/g, '.') : 0));

    // Get min & max values for range component options
    const optionMin =
      props.column.filter === 'decimalRange'
        ? Math.floor(Math.min(...values) * decimalCounter) / decimalCounter
        : Math.floor(Math.min(...values));
    const optionMax =
      props.column.filter === 'decimalRange'
        ? Math.ceil(Math.max(...values) * decimalCounter) / decimalCounter
        : Math.ceil(Math.max(...values));

    // Sets the steps in range input slider
    const steps = props.column.filter === 'decimalRange' ? decimalType / 10 : 1;

    // Set decimal precision as an integer
    const precision = this.getDecimalPrecision(decimalType);

    // Set inital values to option values
    let initialMin = optionMin;
    let initialMax = optionMax;

    // Get initial values from props and sanitize them
    if (
      props.initialValue &&
      props.initialValue.includes('range') &&
      props.initialValue.includes('-')
    ) {
      const ivArr = props.initialValue
        .replace('range', '')
        .split('-')
        .map((v) => parseFloat(v));
      if (!isNaN(ivArr[0]) && ivArr[0] > optionMin) {
        initialMin = ivArr[0];
      }
      if (!isNaN(ivArr[1]) && ivArr[1] < optionMax) {
        initialMax = ivArr[1];
      }
    }

    this.state = {
      options: {
        min: optionMin,
        max: optionMax,
        steps
      },
      value: {
        min: initialMin,
        max: initialMax,
        steps
      },
      precision
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.initialValue !== this.props.initialValue &&
      !this.props.initialValue
    ) {
      const { min, max } = this.state.options;
      this.setState({ value: { min, max } });
    }
  }
  capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  getFixedDecimalType(type) {
    if (type === 'auto') {
      return 0.01;
    }
    return parseFloat(type.replace('num(', '').replace(')', ''));
  }
  getDecimalPrecision() {
    const dtype = this.getFixedDecimalType(this.props.column.type);
    const dtypeValue = `${dtype}`.split('.')[1];
    return typeof dtypeValue !== 'undefined' ? dtypeValue.length : 1;
  }
  getFormattedNum(value) {
    return value.toFixed(this.state.precision);
  }

  setFilter(value, filter) {
    this.setState(value);

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (filter !== 'decimalRange') {
        this.props.applyFilter(
          'range' + [value.value.min, value.value.max].join('-'),
          true
        );
      } else {
        this.props.applyFilter(
          'range' +
            [
              this.getFormattedNum(value.value.min),
              this.getFormattedNum(value.value.max)
            ].join('-'),
          true
        );
      }
    }, 500);
  }

  formatValue(value, filter) {
    if (filter !== 'decimalRange') {
      return value;
    }
    const decimalValue = this.getFormattedNum(value);
    return decimalValue.toString().includes('.')
      ? decimalValue.toString().replace('.', ',')
      : decimalValue.toString();
  }

  render() {
    if (!this.props || !this.props.values || !this.props.values[0]) {
      return null;
    }
    const { attribute, displayLabel, column } = this.props;
    const { min, max, steps } = this.state.options;
    return (
      <div>
        {displayLabel && <Label>{this.capitalize(attribute)}: </Label>}
        <Range
          maxValue={max}
          minValue={min}
          step={steps}
          formatLabel={(value) => this.formatValue(value, column.filter)}
          value={this.state.value}
          onChange={(value) => this.setFilter({ value }, column.filter)}
        />
      </div>
    );
  }
}

const SEARCH_ROOT = { currentPage: true };
export default facet(null, SEARCH_ROOT)(RangeFacet);
