/**
 * This is a fragment of the "BodyBuilder" Test Relation block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_TEST_RELATION = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment TestRelation on Bodybuilder_TestRelation {
    test {
      title
      excerpt
      paywall {
        restricted
        accessible
        products {
          title
          description
          price
          price_unit
          marketing_text
          excerpt
          link
          uri
          featuredImage {
            node {
              ...FeaturedImageModel
              slug
            }
          }
        }
      }
      product_reviews {
        reviews {
          id
          product_reviewId
          title
          slug
          content
          excerpt
          code
          group
          hintaopas_id
          attributes
          references {
            key
            value
          }
          metadata {
            key
            value
          }
          featuredImage {
            node {
              ...FeaturedImageModel
            }
          }
          product_review_categories {
            icon {
              altText
              sourceUrl
            }
          }
        }
        labels
      }
      column_definitions {
        restricted
        protected
        visible
        level
        weight
        name
        suffix
        filter
        type
      }
      references {
        key
        value
      }
      reference_descriptions
    }
  }
`;

export default FRAGMENT_TEST_RELATION;
