import {
  determineLocale as getLocale,
  determineClientLocale as getClientLocale
} from '@/redandblue/utils/locale';
import fiMessages from '../i18n/fi.json';

// Modify this to add/remove locales from the application
const LOCALE_DATA = {
  // Other languages will come in later phases. FI only for now.
  fi: {
    messages: fiMessages
  }
};
const APP_LOCALES = Object.keys(LOCALE_DATA);

export function determineLocale(req, res) {
  return getLocale(APP_LOCALES)(req, res);
}

export function determineClientLocale(req, res) {
  return getClientLocale(APP_LOCALES)(req, res);
}

export function getLocaleData(localeCode) {
  return LOCALE_DATA[localeCode];
}
