/**
 * This is the query to get menu from WordPress.
 */

import gql from 'graphql-tag';
import FRAGMENT_MENU_ITEM_MODEL from '@/graphql/wordpress/_fragments/fragmentMenuItemModel';

const QUERY_GET_MENU = gql`
  ${FRAGMENT_MENU_ITEM_MODEL}

  query getMenu($id: ID!) {
    menu(id: $id, idType: NAME) {
      menuItems {
        nodes {
          ...MenuItemModel
        }
      }
    }
  }
`;

export default QUERY_GET_MENU;
