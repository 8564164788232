import React from 'react';
import styled from 'styled-components';
import HeaderSearchBar from './HeaderSearchBar';
import FixedHeader from '@/containers/Header/FixedHeader';
import OrderButton from '@/components/Buttons/OrderButton';
import MembershipRestrictedContent from '@/components/Membership/MembershipRestrictedContent';
import { MEMBER_SUBSCRIBER } from '@/configs/memberships';

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 24px;
  ${(props) => props.theme.media.laptopOnly`
    padding-left: 220px;
  `};
  ${(props) => props.theme.media.tablet`
    padding-top: 0;
    justify-content: right;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.desktopLaptop`
    width: auto;
    padding: 16px 0 16px 16px;
    position: relative;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
      display: none;
  `};
`;

const SearchRight = styled.div`
  width: 33%;
  min-width: 220px;
  text-align: right;
  display: none;
  ${(props) => props.theme.media.desktopLaptop`
    display: block;
  `};
  ${(props) =>
    props.$isSticky &&
    props.theme.media.laptopOnly`
    min-width: inherit;
    .tilaa-button{
      display: none;
    }
  `};
`;

const HeaderSearch = (props) => {
  return (
    <FixedHeader>
      {({ beforeSticky, isSticky }) => (
        <SearchContainer $isSticky={isSticky}>
          <HeaderSearchBar isSticky={isSticky} beforeSticky={beforeSticky} />
          <MembershipRestrictedContent
            excludedMemberships={[MEMBER_SUBSCRIBER]}
          >
            <SearchRight $isSticky={isSticky}>
              <OrderButton />
            </SearchRight>
          </MembershipRestrictedContent>
        </SearchContainer>
      )}
    </FixedHeader>
  );
};

export default HeaderSearch;
