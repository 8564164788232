import config from '../config';
import _get from 'lodash.get';
import { setUtm, getUtm } from './utm';
import { toFixedNumber } from '@/utils/helpers';

// Set UTM parameters
setUtm();

/**
 * Check if users consent to analytics.
 * @returns {boolean} Returns true if users consent to analytics.
 *
 * TODO: Check consent status from cookie or elsewhere.
 */
function usersAnalyticsConsent() {
  return true;
}

/**
 * Format a product item for Google Analytics.
 * @param {Object} item - The product item to format.
 * @param {number} [index=0] - The index of the item.
 * @returns {Object} The formatted product item.
 */
export function gaFormatProductItem(item, index = 0) {
  if (!item) {
    return {};
  }

  const product = _get(item, 'product.product') || item;
  const quantity = _get(item, 'quantity', 1);
  const defaultPrice = _get(item, 'regular_price', 0);
  const price = _get(item, 'price', 0);
  const item_list_name = _get(item, 'item_list_name', null);

  const extraKeys = {};

  if (item_list_name) {
    extraKeys.item_list_name = item_list_name;
  }

  return {
    item_id: product.sku || product.id,
    item_name: product.name,
    discount: toFixedNumber(defaultPrice - price, 2),
    index: index,
    item_category: _get(product, 'categories[0].name', null),
    item_category2: _get(product, 'categories[1].name', null),
    item_category3: _get(product, 'categories[2].name', null),
    item_category4: _get(product, 'categories[3].name', null),
    item_category5: _get(product, 'categories[4].name', null),
    price: toFixedNumber(defaultPrice, 2),
    quantity: +quantity,
    ...extraKeys
  };
}

/**
 * Get the eCommerce data for Google Analytics.
 * @param {Object} data - The eCommerce data.
 * @returns {Object} The formatted eCommerce data.
 */
export function gaGetECommerce(data) {
  const { items, ...rest } = data;

  if (!items || items.length === 0) {
    return {};
  }

  const itemsExtraKeys = {};

  if (data.item_list_name) {
    itemsExtraKeys.item_list_name = data.item_list_name;
  }

  const itemsECommerce = items.map((item, index) =>
    gaFormatProductItem({ ...itemsExtraKeys, ...item }, index)
  );

  const ecommerce = {
    items: itemsECommerce,
    ...rest
  };

  return ecommerce;
}

/**
 * Calculate the total value of items for Google Analytics.
 * @param {Array} items - An array of items.
 * @returns {number} The total value of items.
 */
export function gaGetTotalValue(items) {
  if (!items || items.length === 0) {
    return 0;
  }

  let totalValue = 0;

  items.forEach((item) => {
    totalValue += _get(item, 'price', 0) * _get(item, 'quantity', 1);
  });

  return toFixedNumber(totalValue, 2);
}

/**
 * Setup a signup event for Google Analytics.
 * @param {Object} user - The user object.
 * @returns {Object|null} The event options or null if the user is not provided.
 */
export function gaSetupSignupEvent(user) {
  if (!user) {
    return null;
  }

  const { sfmc_id } = getUtm();
  const event = 'sign_up';
  const customer_id = _get(user, 'userId', 'none');
  const user_type = _get(user, 'roles.nodes[0].name', 'none');
  const options = {
    event,
    method: 'Form', // Currently there is no other method for signup.
    login_status: 'Logged Out', // Registration doesn't login user automatically
    customer_id,
    user_type,
    sfmc_id
  };
  return options;
}

/**
 * Setup a login event for Google Analytics.
 * @param {Object} user - The user object.
 * @param {boolean} [isLogin=true] - Indicates if it's a login event (default is true).
 * @returns {Object|null} The event options or null if the user is not provided.
 */
export function gaSetupLoginEvent(user, isLogin = true) {
  if (!user) {
    return null;
  }

  const { sfmc_id } = getUtm();
  const login_status = isLogin ? 'Logged In' : 'Logged Out';
  const event = isLogin ? 'login' : 'logout';
  const customer_id = _get(user, 'userId', 'none');
  const user_type = _get(user, 'roles.nodes[0].name', 'none');
  const options = {
    event,
    method: 'Form', // Currently there is no other method for signup.
    login_status,
    customer_id,
    user_type,
    sfmc_id
  };
  return options;
}

/**
 * Setup custom pageview event for Google Analytics.
 * @param {Object} page - The page object.
 * @param {Object} location - The location object.
 * @param {Object} [auth={}] - Authentication information.
 * @returns {Object|null} The event options or null if page or location is not provided.
 */
export function gaSetupCustomPageviews(page, location, auth = {}) {
  if (!page || !location) {
    return null;
  }
  const firstPageCatSlug = _get(
    page,
    'categories.nodes[0].slug',
    'Uncategorized'
  );

  const { sfmc_id } = getUtm();
  const login_status = auth.token ? 'Logged In' : 'Not logged In';
  const customer_id = _get(auth, 'user.userId', 'none');
  const user_type = _get(auth, 'user.roles.nodes[0].name', 'none');
  const { pathname = '', search = '' } = location;
  const options = {
    event: 'custom_pageview',
    page_url: `${config.app.host}${pathname}${search}`,
    page_path: location.pathname,
    page_title: page.title,
    page_category: firstPageCatSlug,
    login_status,
    customer_id,
    user_type,
    sfmc_id
  };
  return options;
}

/**
 * Create a Google Analytics event.
 * @param {Object} [options={}] - Event options.
 * @param {boolean} [addSfmcId=false] - Whether to add the sfmc_id to options.
 */
export function gaCreateEvent(options = {}, addSfmcId = false) {
  if (typeof window !== 'undefined' && usersAnalyticsConsent()) {
    const { sfmc_id } = getUtm();
    const opts = addSfmcId ? { ...options, sfmc_id } : options;
    window.dataLayer.push(opts);
  }
}
