import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fixURL } from '@/utils/helpers';
import { gaCreateEvent } from '@/utils/gaUtils';
import config from '@/config';

/**
 * Styled component for styling NavLink.
 */
const Link = styled(NavLink)`
  text-decoration: none;
  color: ${(props) =>
    props.$color === 'white'
      ? props.theme.colors.white
      : props.theme.colors.darkGrey};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

/**
 * Styled component for FontAwesomeIcon used as an external link icon.
 */
const ExternalLink = styled(FontAwesomeIcon)`
  padding-top: 2px;
  padding-left: 5px;
  font-size: 15px;
`;

/**
 * Component for handling localized links.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.to - URL path.
 * @param {ReactNode} props.children - Child components.
 * @param {Function} props.onClick - Click event handler.
 * @param {Object} props.ga_data - Google Analytics data.
 * @param {Object} props.intl - React Intl object.
 * @returns {JSX.Element} - React component.
 */
const LocalizedLink = ({
  intl,
  to,
  children,
  onClick,
  ga_data,
  color,
  ...rest
}) => {
  const handleOnClick = (e) => {
    // Set GTM data
    const { locale } = intl;
    const fixedTo = fixURL(to, config.app.host);
    const { event, prefix, ...rest } = ga_data || {
      event: 'link_click',
      prefix: 'link'
    };
    let options = { event, ...rest };
    if (fixedTo.startsWith('http')) {
      options[`${prefix}_url`] = fixedTo;
    } else {
      const url = fixedTo.startsWith(`/${locale}`)
        ? fixedTo
        : `/${locale}${fixedTo}`;
      options[`${prefix}_url`] = url;
    }
    const nameKey = prefix === 'nav' ? 'nav_item' : `${prefix}_name`;
    if (
      Array.isArray(children) &&
      children.length > 0 &&
      typeof children[0] === 'string'
    ) {
      options[nameKey] = children[0];
    } else if (typeof children === 'string') {
      options[nameKey] = children;
    }
    // Add GTM data to datalayer
    gaCreateEvent(options);

    // Fire default onClick function
    if (onClick) {
      onClick(e);
    }
  };

  const { locale } = intl;
  const fixedTo = fixURL(to, config.app.host);
  if (!fixedTo) {
    return null;
  }

  const isAbsolute = fixedTo.startsWith('http');
  if (isAbsolute) {
    return (
      <a
        href={fixedTo}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => handleOnClick(e)}
        {...rest}
      >
        {children}
        <ExternalLink icon={['fal', 'external-link']} />
      </a>
    );
  }

  const url = fixedTo.startsWith(`/${locale}`)
    ? fixedTo
    : `/${locale}${fixedTo}`;
  return (
    <Link
      exact
      to={url}
      onClick={(e) => handleOnClick(e)}
      $color={color}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default injectIntl(LocalizedLink);
