import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { isTablet } from '../../utils/mediaquery';

const Container = styled.div`
  display: flex;
  padding: 40px 0 55px;
  position: relative;
  #copyurl-field {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    padding: 0;
  }
`;

const Button = styled.a`
  display: inline-block;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli.full};
  border-radius: 50%;
  height: 28px;
  width: 28px;
  font-size: 16px;
  text-align: center;
  margin-right: 16px;
  color: ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[20]};
  transition: all 50ms;
  position: relative;
  svg {
    position: relative;
    top: 4px;
  }
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli[80]};
    transform: scale(1.2);
  }
`;

const Copyinfo = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 150px;
  padding: 8px;
  font-size: 11px;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli.full};
  color: #fff;
  z-index: 999;
`;

class SocialNetworks extends React.Component {
  state = { showCopyAlert: false };

  copyUrl(event, url) {
    event.preventDefault();
    if (!navigator.clipboard) {
      const pasteText = document.querySelector('#copyurl-field');
      pasteText.focus();
      pasteText.select();
      try {
        document.execCommand('copy');
        this.flashCopyNotification();
      } catch (err) {
        console.error('Oops, unable to copy', err);
      }
      return;
    }
    navigator.clipboard.writeText(url).then(
      () => {
        this.flashCopyNotification();
      },
      (err) => {
        console.error('Could not copy url: ', err);
      }
    );
  }
  flashCopyNotification() {
    this.setState({ showCopyAlert: true });
    window.setTimeout(() => this.setState({ showCopyAlert: false }), 1000);
  }
  // hardcoded placeholders
  render() {
    const { share, ...rest } = this.props;
    const URL = typeof window !== 'undefined' ? window.location.href : false;
    const networks = [
      {
        name: 'facebook',
        faIcon: 'facebook-f',
        url: 'http://www.facebook.com/Kuluttaja',
        shareUrl: 'http://www.facebook.com/sharer.php?u=' + URL
      },
      {
        name: 'instagram',
        faIcon: 'instagram',
        url: 'https://www.instagram.com/kuluttajamedia/',
        shareUrl: null
      },
      {
        name: 'twitter',
        faIcon: 'twitter',
        url: 'https://twitter.com/kuluttajalehti',
        shareUrl: 'http://twitter.com/share?url=' + URL
      },
      {
        name: 'youtube',
        faIcon: 'youtube',
        url: 'https://www.youtube.com/channel/UCAUl27Bk9zOsOi0EYT8VwbA',
        shareUrl: null
      }
    ];
    if (!URL) {
      return null;
    }
    if (!process.browser) {
      return <Container />;
    }
    const Buttons = networks.map(
      (network, index) =>
        ((!share && network.url) || (share && network.shareUrl)) && (
          <Button
            key={index}
            href={share ? network.shareUrl : network.url}
            target="_blank"
            className="social-button"
          >
            <FontAwesomeIcon icon={['fab', network.faIcon]} />
          </Button>
        )
    );
    return (
      <Container {...rest}>
        {Buttons}
        {share && (
          <React.Fragment>
            <Button href={`mailto:?Body=${URL}`} className="social-button">
              <FontAwesomeIcon icon={['fal', 'envelope']} />
            </Button>
            {!isTablet() && (
              <React.Fragment>
                <Button
                  onClick={(e) => this.copyUrl(e, URL)}
                  className="social-button"
                >
                  <FontAwesomeIcon icon={['fal', 'link']} />
                </Button>
                <input id="copyurl-field" type="text" value={URL} readOnly />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {!isTablet() && this.state.showCopyAlert && (
          <Copyinfo>
            <FormattedMessage id="post.copyurl" tagName="span" />
          </Copyinfo>
        )}
      </Container>
    );
  }
}

export default SocialNetworks;
