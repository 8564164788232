import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { getUrlForNode } from '@/utils/helpers';
import Link from '@/components/Link/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _get from 'lodash.get';

const Row = styled.div`
  padding: 1.2em 26px;
  font-size: 10px;
  letter-spacing: 1.4px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grey};
  text-transform: uppercase;
  background-color: #fff;
  .svg-inline--fa {
    font-size: 1.4em;
  }
  a {
    color: ${(props) => props.theme.colors.grey};
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  ${(props) => props.theme.media.mobileSmall`
    display:none;
  `};
`;

const Crumb = styled.span`
  .svg-inline--fa {
    font-size: 1.6em;
    margin: 0 18px;
    position: relative;
    bottom: -2px;
  }
`;

function getActiveMenuItems(nodes, pathname, items = []) {
  // Go through all the nodes until a match is found
  for (let key in nodes) {
    const node = nodes[key];

    // If node has children, we go directly into the depts to find deepest possible match
    if (_get(node, 'childItems.nodes')) {
      const children = getActiveMenuItems(
        node.childItems.nodes,
        pathname,
        items
      );
      // If children were found (meaning we have a match)
      // Then also collect the current node as a parent for that
      if (children.length > 0) {
        children.push(node);
        return children;
      }
    }

    // If node did not have children, we compare if this is the current node
    const url = getUrlForNode(node);
    if (pathname.includes(url) || url.includes(pathname)) {
      items.push(node);
      break;
    }
  }

  return items;
}

/*
  Breadcrumb is constructed based on menus.
  We find the active menu item and use that to create breadcrumb.
*/
class Breadcrumb extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired
  };

  render() {
    const { location, menus } = this.props;
    const isFrontpage = location.pathname === '/fi/';
    const isUser = location.pathname.includes('/fi/käyttäjä');
    const isSearch = location.pathname.includes('/fi/search');

    if (!menus || isFrontpage || isUser || isSearch) {
      return null;
    }

    // Find the menu nodes for breadcrumb
    let nodes = [];
    for (let key in menus) {
      const menu = menus[key];
      const items = getActiveMenuItems(
        _get(menu, 'menuItems.nodes'),
        location.pathname
      );
      if (items.length > 0) {
        items.reverse();
        nodes = items;
      }
    }
    if (!nodes.length) {
      return null;
    }

    return (
      <Row id="breadcrumbs">
        <Link to="/">
          <FontAwesomeIcon icon={['fal', 'home']} />
        </Link>
        {nodes.map((node) => {
          return (
            <Crumb key={node.id}>
              <FontAwesomeIcon icon={['fal', 'angle-right']} />
              <Link to={node.uri}>{node.label}</Link>
            </Crumb>
          );
        })}
      </Row>
    );
  }
}

export default withRouter(Breadcrumb);
