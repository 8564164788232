import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Facet } from '@/redandblue/components';
import { toggleFilter } from '@/redandblue/components/Facet/reducer';

// Higher Order Component that adds filtering functionality for the wrapped component
export default function facetFactory(attribute, searchRoot) {
  return function facetHoC(WrappedComponent) {
    const ConnectedFacet = connect(
      (state) => ({
        filters: state.facets.filters
      }),
      {
        toggleFilter
      }
    )(Facet(attribute, searchRoot)(WrappedComponent));

    return withRouter(ConnectedFacet);
  };
}
