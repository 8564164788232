import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ProductReviewItem from './ProductReviewItem';
import { getMetaValue } from '../product-review';
import Container from '../../../browser/Container';
import { filterReviews, getSelectedIDs, setSelectedIDs } from '../test-utils';
import ProductComparisonBar from '../Components/ProductComparisonBar';

const ReviewsContainer = styled(Container)`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const HelpTextContainer = styled(Container)`
  margin-top: 32px;
`;

class ProductReviewsList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  handleSelect = (review, checked) => {
    const { product_reviewId } = review;
    let selected = getSelectedIDs(this.props);
    if (checked) {
      selected.push(product_reviewId);
    } else {
      selected = selected.filter((id) => id !== product_reviewId);
    }
    setSelectedIDs(selected, this.props);
  };

  renderGroupTitle(groupKey) {
    const { labels } = this.props.data;

    if (!isNaN(groupKey) && labels[groupKey - 1]) {
      const label = labels[groupKey - 1];
      return <h3>{label}</h3>;
    }

    return <FormattedMessage id="productReview.text.noGrouping" tagName="h3" />;
  }

  render() {
    const { reviews } = this.props.data;
    if (!Array.isArray(reviews) || reviews.length === 0) {
      return null;
    }

    // Filter the displayed reviews based on current filters
    const { filters, accessible } = this.props;
    const filteredReviews = filterReviews(reviews, filters);

    // If user is now logged in, sort reviews alphabetically
    const sortedReviews = accessible
      ? filteredReviews
      : filteredReviews.sort((a, b) => {
          const aVal = getMetaValue('Malli', a);
          const bVal = getMetaValue('Malli', b);

          if (aVal < bVal) {
            return -1;
          }
          if (aVal > bVal) {
            return 1;
          }
          return 0;
        });

    // Parse the selected reviews from the URL
    const selected = getSelectedIDs(this.props);

    // Finally, group the filtered reviews by their group
    const groupedReviews = sortedReviews.reduce((grouping, review) => {
      const groupKey = review.group || 'no-group';
      const reviews = grouping[groupKey] || [];
      reviews.push(review);
      grouping[groupKey] = reviews;
      return grouping;
    }, {});

    const groupArray = Object.entries(groupedReviews);

    return (
      <React.Fragment>
        <HelpTextContainer>
          <FormattedMessage id="productReview.text.help" tagName="p" />
        </HelpTextContainer>
        <ReviewsContainer>
          {groupArray.map(([groupKey, reviews]) => (
            <React.Fragment key={groupKey}>
              {groupArray.length > 1 && this.renderGroupTitle(groupKey)}
              {reviews.map((review) => (
                <ProductReviewItem
                  key={review.id}
                  review={review}
                  location={this.props.location}
                  checked={selected.includes(review.product_reviewId)}
                  onSelect={this.handleSelect}
                  accessible={accessible}
                />
              ))}
            </React.Fragment>
          ))}
        </ReviewsContainer>
        <ProductComparisonBar reviews={reviews} accessible={accessible} />
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({
    filters: state.facets.filters
  }))(ProductReviewsList)
);
