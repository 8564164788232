import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentStyler from './ContentStyler';

const PaywallShadow = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Content = ({ html, accessible, as, ...rest }) => {
  if (!html || html === 'false') {
    return null;
  }
  const Element = as;
  return (
    <ContentStyler {...rest}>
      <Element dangerouslySetInnerHTML={{ __html: html }} />
      {!accessible && <PaywallShadow />}
    </ContentStyler>
  );
};

Content.propTypes = {
  as: PropTypes.string,
  html: PropTypes.string,
  accessible: PropTypes.bool
};
Content.defaultProps = {
  as: 'div',
  accessible: true
};

export default Content;
