import React from 'react';
import styled, { css } from 'styled-components';
import PostMeta from '@/components/PostMeta/PostMeta';
import Excerpt from '@/browser/Excerpt';
import Link from '@/components/Link/Link';
import WpImage from '@/components/Images/WpImage';

/**
 * Determines the small card style based on the card size.
 *
 * @param {Object} props - Component properties.
 * @param {Function} style - Style function.
 * @returns {Function} - CSS function.
 */
const smallCard = (props, style) => {
  const cardcss = (...style) =>
    (props.$size === 2 &&
      props.theme.media.mobile`
        ${css(...style)}
      `) ||
    (props.$size === 3 && css(...style));
  return cardcss;
};

/**
 * Styled component for the card container.
 */
const Card = styled.div`
  margin-bottom: 30px;

  ${(props) => props.theme.media.mobile`
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${(props) => smallCard(props)`
    display: grid;
    grid-template-columns: 123px auto;
    grid-gap: 5%;

    .liftcard-thumb .placeholder-img {
      max-height: 82px;
    }
  `}

  img {
    max-height: ${(props) => (props.$size === 1 ? '560px' : '268px')};
    object-fit: cover;
    width: 100%;
    margin-bottom: ${(props) => (props.$size === 1 ? '30px' : '16px')};

    ${(props) => smallCard(props)`
      height: auto;
    `}
  }

  .placeholder-img {
    height: 20.2vw;
    max-height: ${(props) => (props.$size === 1 ? '560px' : '268px')};

    ${(props) => props.theme.media.tablet`
      height: 31vw;
    `}

    ${(props) => props.theme.media.mobile`
      height: 82px;
    `}

    margin-bottom: ${(props) => (props.$size === 1 ? '30px' : '16px')};

    img {
      margin-bottom: 0;
    }
  }

  p {
    font-family: ${(props) => props.theme.fonts.secondary};
  }
`;

/**
 * Styled component for the card content.
 */
const CardContent = styled.div`
  ${(props) => smallCard(props)`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .card-content {
      display: none;
    }
  `};
`;

/**
 * LiftCard component for displaying a card with an image, title, and content.
 *
 * @component
 * @param {Object} props - Component properties.
 * @returns {JSX.Element} - React component.
 */
const LiftCard = ({
  image,
  title,
  date,
  content,
  size,
  categories,
  link,
  restricted,
  placeholderImage,
  onClick
}) => {
  /**
   * Gets the optimal image width based on the card size.
   *
   * @returns {number} - Optimal image width.
   */
  const getOptimalImageWidth = () => {
    switch (size) {
      case 1:
        return 800;
      case 2:
        return 300;
      case 3:
        return 120;
      default:
        return 400;
    }
  };

  return (
    <Card $size={size}>
      <Link to={link} className="liftcard-thumb" onClick={onClick}>
        <WpImage
          image={image}
          restricted={restricted}
          optimalWidth={getOptimalImageWidth()}
          placeholder={placeholderImage !== undefined ? placeholderImage : true}
        />
      </Link>
      <CardContent $size={size}>
        <Link to={link} onClick={onClick}>
          {size === 1 ? (
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          ) : (
            <h4 dangerouslySetInnerHTML={{ __html: title }} />
          )}
        </Link>
        <PostMeta date={date} categories={categories} />
        {content && (
          <Link to={link} onClick={onClick}>
            <Excerpt html={content} className="card-content" />
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

// Default props for LiftCard component
LiftCard.defaultProps = {
  size: 2,
  date: null,
  categories: null,
  restricted: false
};

export default LiftCard;
