import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Loader from '@/components/Loader/Loader';
import ErrorHandler, {
  ErrorPolicy
} from '@/components/ErrorHandler/ErrorHandler';
import NotFound from '@/components/NotFound/NotFound';
import QUERY_GET_POST_BODYBUILDER from '@/graphql/wordpress/posts/queryGetPostBodybuilder';
import SinglePost from '@/containers/Post/SinglePost';
import PropTypes from 'prop-types';

/**
 * Functional component for rendering a test article.
 * @param {Object} props - Component properties.
 * @param {Object} props.test_article - The test article object.
 * @returns {JSX.Element} - The rendered component.
 */
const TestArticle = ({ test_article }) => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0
    });
  }, []);

  const { loading, error, data } = useQuery(QUERY_GET_POST_BODYBUILDER, {
    variables: { slug: test_article?.slug },
    errorPolicy: ErrorPolicy
  });

  return (
    <>
      <Loader visible={loading} />
      {!loading && !data?.post && <NotFound />}
      {!loading && data?.post && <SinglePost post={data.post} />}
      {error && <ErrorHandler error={error} />}
    </>
  );
};

// Prop types for TestArticle component
TestArticle.propTypes = {
  test_article: PropTypes.object
};

// Export the TestArticle component
export default TestArticle;
