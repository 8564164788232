import styled from 'styled-components';

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  border: 0 none;
  background: none;
  box-shadow: none;
  border-radius: 0;
  position: relative;
  transition: all 200ms;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 none;
  &:hover {
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli[20]};
  }
  &:after,
  &:before {
    content: ' ';
    display: block;
    height: 2px;
    width: 16px;
    position: absolute;
    top: 11px;
    background-color: ${(props) => props.theme.colors.darkGrey};
  }
  &:after {
    left: 4px;
    transform: rotate(45deg);
  }
  &:before {
    left: 4px;
    transform: rotate(-45deg);
  }
`;

export default CloseButton;
