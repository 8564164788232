import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Content from '@/browser/Content';
import Container from '@/browser/Container';
import { ActionButton } from '@/components/Buttons/Button';
import WpImage from '@/components/Images/WpImage';

/**
 * Styled component for ContainerA.
 */
const ContainerA = styled(Container)`
  border: ${(props) =>
    props.$boxed
      ? '16px solid' + props.theme.colors.kuluttaja2021.harmaa[20]
      : 'none'};
  background-color: #fff;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: ${(props) =>
    props.$imageRight ? '"text image"' : '"image text"'};
  grid-template-rows: ${(props) => (props.$boxed ? 'min-content' : 'auto')};
  margin-bottom: 100px;
  ${(props) => props.theme.media.tablet`
    border-width: ${props.$boxed ? '8px' : '0'};
    width: ${props.$boxed ? '100%' : '95vw'};
    margin-bottom: 64px;
    ${
      !props.$boxed
        ? `
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      grid-template-areas: "image" "text";
      max-width: 768px;
    `
        : ``
    }
  `};
  ${(props) =>
    props.$boxed &&
    props.theme.media.mobile`
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "image" "text";
  `}
  > img {
    object-fit: cover;
    object-position: center center;
    grid-area: image;
    ${(props) => props.theme.media.tablet`
      margin-bottom: 32px;
    `};
  }
  .text-content {
    padding-top: ${(props) => (props.$boxed ? '70px' : '16px')};
    padding-right: ${(props) =>
      !props.$imageRight && !props.$boxed ? '0' : '80px'};
    padding-bottom: ${(props) => (props.$boxed ? '70px' : '16px')};
    padding-left: ${(props) =>
      props.$imageRight && !props.$boxed ? '0' : '80px'};
    grid-area: text;
    ${(props) => props.theme.media.tablet`
      ${
        props.$boxed
          ? `
        padding: 48px 24px;
      `
          : `
        padding: 16px 0;
      `
      }
      width: 100%;
      margin-bottom: 0;
    `};
  }
`;

/**
 * Styled component for Image.
 */
const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  grid-area: image;
  align-self: stretch;
  width: 100%;
  ${(props) => props.theme.media.mobile`
    height: 70vw;
    max-height: 320px;
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

/**
 * Styled component for HCButton.
 */
const HCButton = styled(ActionButton)`
  margin-top: 32px;
`;

/**
 * React component for displaying hero content.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title of the hero content.
 * @param {string} [props.content] - Content of the hero.
 * @param {Object} [props.action] - Action associated with the hero content.
 * @param {Object} [props.image] - Image object for the hero.
 * @param {boolean} [props.boxed] - Indicates if the hero content is boxed.
 * @param {boolean} [props.image_on_right] - Indicates if the image is on the right.
 * @param {string} [props.previousSection] - Identifier for the previous section.
 * @returns {JSX.Element} React component.
 */
const HeroContent = ({
  title,
  content,
  action,
  image,
  boxed,
  image_on_right,
  previousSection
}) => {
  if (!title) {
    return null;
  }

  const hasImage = image && image.sourceUrl !== null;

  return (
    <ContainerA
      $boxed={boxed}
      $imageRight={image_on_right}
      $hasImage={hasImage}
      $previousSection={previousSection}
      $afterHero={previousSection === 'Bodybuilder_HeroUnit'}
    >
      {hasImage &&
        (boxed ? (
          <ImageWrapper>
            <WpImage image={image} />
          </ImageWrapper>
        ) : (
          <WpImage image={image} />
        ))}
      <div className="text-content">
        <h1>{title}</h1>
        <Content html={content} />
        <HCButton action={action} />
      </div>
    </ContainerA>
  );
};

HeroContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  action: PropTypes.object,
  image: PropTypes.object,
  boxed: PropTypes.bool,
  image_on_right: PropTypes.bool,
  previousSection: PropTypes.string
};
HeroContent.defaultProps = {
  content: null,
  boxed: false,
  image_on_right: false,
  previousSection: null
};

export default HeroContent;
