import React from 'react';
import styled from 'styled-components';

const Ball = styled.div`
  height: ${(props) => (props.$small ? '94px' : '156px')};
  width: ${(props) => (props.$small ? '94px' : '156px')};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[40]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-weight: bold;
  color: ${(props) => props.theme.colors.kuluttaja2021.harmaa.full};
  line-height: 0.75;
`;
const Title = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.1;
`;
const Price = styled.div`
  display: grid;
  align-items: center;
  column-gap: 2px;
  grid-template-columns: repeat(2, auto);
  grid-template-areas: 'euros cents' 'euros currency';
`;
const Euros = styled.div`
  font-size: ${(props) => (props.$small ? '32px' : '56px')};
  grid-area: euros;
  text-align: right;
`;
const Currency = styled.div`
  font-size: ${(props) => (props.$small ? '14px' : '22px')};
  grid-area: currency;
  text-align: left;
`;
const Cents = styled.div`
  font-size: ${(props) => (props.$small ? '14px' : '24px')};
  grid-area: cents;
  text-align: left;
`;
const PriceUnit = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 8px;
  line-height: 1.1;
`;

const PriceBall = ({ price, priceUnit, title, small }) => {
  if (!price || !priceUnit) {
    return null;
  }

  const fixedPrice = isNaN(parseFloat(price)) ? 0 : parseFloat(price);
  const euros = Math.floor(fixedPrice);
  const cents = (fixedPrice - euros).toFixed(2) * 100;
  const fixedPriceUnit =
    priceUnit && priceUnit !== '' ? `/ ${priceUnit}` : null;

  return (
    <Ball title={title} $small={small}>
      {title && <Title>{title}</Title>}
      <Price>
        <Euros $small={small}>{euros.toString()}</Euros>
        <Currency $small={cents > 0 && small}>€</Currency>
        {cents > 0 && <Cents $small={small}>{cents.toString()}</Cents>}
      </Price>
      {fixedPriceUnit && <PriceUnit>{fixedPriceUnit}</PriceUnit>}
    </Ball>
  );
};

export default PriceBall;
