import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { store, reset } from '@/store/reducers/auth';
import QueryHandler from '@/components/GraphQL/QueryHandler';
import { updateUtm } from '@/utils/utm';
import QUERY_GET_VIEWER from '@/graphql/wordpress/viewer/queryGetViewer';

/**
 * The RenewSession component.
 * @param {object} props - The component's properties.
 * @returns {JSX.Element} - The rendered component.
 */
const RenewSession = ({ store, reset }) => {
  /**
   * Handles the completion of the query.
   * @param {object} data - The query data.
   */
  const handleComplete = (data) => {
    if (!data.viewer) {
      reset();
      return;
    }
    // Update utm
    updateUtm({ sfmc_id: data.viewer.sfmcId });

    store({ user: data.viewer });
  };

  return (
    <QueryHandler
      query={QUERY_GET_VIEWER}
      onCompleted={handleComplete}
      onError={() => reset()}
    >
      {() => <div>Restoring your session...</div>}
    </QueryHandler>
  );
};

RenewSession.propTypes = {
  store: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default connect(null, { store, reset })(RenewSession);
