import styled from 'styled-components';

const DropdownOpen = styled.button`
  width: 24px;
  height: 24px;
  border: 1px solid
    ${(props) => props.theme.colors.kuluttaja2021.tummaPetroli[60]};
  background: none;
  box-shadow: none;
  border-radius: 0;
  position: relative;
  &:hover {
    border-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
    background-color: ${(props) =>
      props.theme.colors.kuluttaja2021.tummaPetroli.full};
    &:after,
    &:before {
      background-color: white;
    }
  }
  &:after,
  &:before {
    content: ' ';
    display: block;
    height: 1.2px;
    width: 8px;
    position: absolute;
    top: 10px;
    background-color: ${(props) => props.theme.colors.kuluttaja2021.harmaa[60]};
    transition: all 200ms;
  }
  &:after {
    left: 4px;
    transform: rotate(${(props) => (props.$active ? '-45deg' : '45deg')});
  }
  &:before {
    left: 10px;
    transform: rotate(${(props) => (props.$active ? '45deg' : '-45deg')});
  }
`;

export default DropdownOpen;
