import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import FullSection from '@/browser/FullSection';
import SearchBar from '@/containers/Search/SearchBar';

const Section = styled.section`
  color: ${(props) => props.theme.colors.white};
  h1,
  h2 {
    color: ${(props) => props.theme.colors.kuluttaja2021.tyrni[20]};
  }
`;

const SearchContainer = styled.div`
  background-color: #fff;
`;

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet title="Sivua ei löytynyt - Kuluttaja" />
      <FullSection
        backgroundPath="404"
        backgroundColor="kuluttaja2021.tummaPetroli.full"
      >
        <Section>
          <FormattedMessage id="page.404.title" tagName="h1" />
          <FormattedMessage id="page.404.subtitle" tagName="h2" />
          <FormattedMessage id="page.404.description" tagName="p" />
          <SearchContainer>
            <SearchBar param="text" searchPage="/search" displayButton="true" />
          </SearchContainer>
        </Section>
      </FullSection>
    </React.Fragment>
  );
};

export default NotFound;
