// Constants for action types
export const STORE_DATA = 'membership/STORE_DATA';

// Initial state for the membership reducer
const initialState = {
  activeMemberships: []
};

/**
 * Reducer function for handling membership-related actions.
 *
 * @param {Object} state - The current state of the membership reducer.
 * @param {Object} action - The action object describing the change to apply to the state.
 * @returns {Object} - The new state after applying the action.
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_DATA:
      // When the STORE_DATA action is dispatched, replace the entire state with the new data.
      return action.payload;
    default:
      // If the action type is not recognized, return the current state.
      return state;
  }
};

// Export the reducer as the default export of this module.
export default reducer;

/**
 * Action creator function for setting membership data.
 *
 * @param {Object} data - The membership data to be stored in the state.
 * @returns {Object} - An action object to dispatch to the reducer.
 */
export const setMembership = (data) => ({
  type: STORE_DATA,
  payload: data
});
