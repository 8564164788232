import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '@/browser/Container';
import LiftCard from '@/components/LiftCard/LiftCard';

/**
 * Styled component for ContainerA.
 */
const ContainerA = styled(Container)`
  border: ${(props) =>
    props.$unboxed
      ? '0'
      : `16px solid ${props.theme.colors.kuluttaja2021.harmaa[10]}`};
  background-color: ${(props) => (props.$unboxed ? 'transparent' : '#fff')};
  padding: ${(props) => (props.$unboxed ? '0' : '56px 22px')};
  margin-bottom: 64px;
  ${(props) =>
    !props.$unboxed &&
    props.theme.media.tablet`
      padding: 48px 16px;
      border-width: 8px;
      width: 100%;
      margin-bottom: 48px;
    `};
`;

/**
 * Styled component for LiftGrid.
 */
const LiftGrid = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 2%;
  ${(props) =>
    props.$hasFour
      ? `
      grid-template-columns: 78% 20%;
      grid-template-areas: 'al1 al2' 'al1 al3' 'al1 al4';`
      : `
      grid-template-columns: 68% 30%;
      grid-template-areas: 'al1 al2' 'al1 al3';
  `};

  ${(props) => props.theme.media.tablet`
    grid-template-columns: 65% 33%;
  `};
  ${(props) => props.theme.media.mobile`
    display: block;
  `};
  > div {
    &:nth-child(1) {
      grid-area: al1;
    }
    &:nth-child(2) {
      grid-area: al2;
    }
    &:nth-child(3) {
      grid-area: al3;
    }
    &:nth-child(4) {
      grid-area: al4;
    }
  }
`;

/**
 * React component for displaying a lift of articles.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title of the article lift.
 * @param {Array} props.articles - Array of articles.
 * @param {boolean} props.unboxed - Whether the container is unboxed.
 * @returns {JSX.Element} React component.
 */
const ArticleLift = ({ title, articles, unboxed }) => {
  if (!Array.isArray(articles)) {
    return null;
  }

  return (
    <ContainerA $unboxed={unboxed}>
      <h2>{title}</h2>
      <LiftGrid $hasFour={articles.length > 3}>
        {articles.map((node, index) => (
          <LiftCard
            key={node.id}
            title={node.title}
            restricted={node.paywall.premium}
            content={index === 0 && node.excerpt ? node.excerpt : null}
            date={node.dateGmt}
            size={index === 0 ? 1 : 2}
            image={node.featuredImage}
            categories={node.categories}
            link={node.uri}
          />
        ))}
      </LiftGrid>
    </ContainerA>
  );
};

ArticleLift.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array.isRequired,
  unboxed: PropTypes.bool
};

ArticleLift.defaultProps = {
  articles: [],
  unboxed: false
};

export default ArticleLift;
