/**
 * This is a fragment of the "BodyBuilder" Hero Content block.
 */

import gql from 'graphql-tag';
import FRAGMENT_FEATURED_IMAGE_MODEL from '@/graphql/wordpress/_fragments/fragmentFeaturedImageModel';

const FRAGMENT_HERO_CONTENT = gql`
  ${FRAGMENT_FEATURED_IMAGE_MODEL}

  fragment HeroContent on Bodybuilder_HeroContent {
    title
    content
    boxed
    image_on_right
    action {
      title
      url
      target
    }
    image {
      ...FeaturedImageModel
    }
  }
`;

export default FRAGMENT_HERO_CONTENT;
