import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { store } from '@/store/reducers/auth';
import Container from '@/browser/Container';
import GreyBox from '@/browser/GreyBox';
import OptionColumn from '@/components/MemberOptions/OptionColumn';

// Styled component for Section
const Section = styled.div`
  margin-bottom: 64px;

  ${(props) => props.theme.media.mobileSmall`
    margin-bottom: 0;
  `};
`;

// Styled component for Container
const PromoContainer = styled(Container)`
  ${(props) => props.theme.media.tablet`
    width: 100%;
  `}
`;

// Styled component for PromoBox
const PromoBox = styled(GreyBox)`
  background-color: ${(props) =>
    props.theme.colors.kuluttaja2021.tummaPetroli[5]};
  display: block;
  padding: 89px 86px;

  ${(props) => props.theme.media.laptop`
    padding: 32px;
  `} > div {
    &:nth-child(3) {
    }
  }
  ul {
    list-style: none;
    li {
      margin-bottom: 32px;
      font-size: 17px;
      font-family: ${(props) => props.theme.fonts.secondary};
      font-weight: bold;
      display: flex;
      align-items: center;
    }
    .fa-check {
      margin-right: 16px;
      font-size: 46px;
      color: ${(props) => props.theme.colors.greyblue};
    }
    ${(props) => props.theme.media.mobileSmall`
      display: none;
    `};
  }
  ${(props) => props.theme.media.tablet`
    padding: 32px 15px;
  `}
  ${(props) => props.theme.media.mobileSmall`
    background-color: #FFF;
    padding: 64px 0;
    border: 0 none;
  `};
`;

// Styled component for Header
const Header = styled.div`
  font-size: 20px;
  font-family: ${(props) => props.theme.fonts.secondary};
  display: grid;
  grid-template-columns: 47% 47%;
  grid-gap: 6%;
  margin-bottom: 32px;
  ${(props) => props.theme.media.mobileSmall`
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

// Styled component for Comparison
const Comparison = styled.div`
  h2 {
    text-align: center;
  }
`;

// Styled component for MemberOptions
const MemberOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32%);
  grid-gap: 2%;
  ${(props) => props.theme.media.mobileSmall`
    display: block;
  `};
`;

/**
 * React component for displaying promotional content for members.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.title - The title of the promotional content.
 * @param {string} props.excerpt - The excerpt of the promotional content.
 * @param {Array} props.bullets - An array of bullet points for the promotional content.
 * @param {string} props.products_title - The title of the products section.
 * @param {Object} props.digital_subscription_product - Information about the digital subscription product.
 * @param {Array} props.digital_product_bullets - An array of bullet points for the digital subscription product.
 * @param {Object} props.subscription_product - Information about the subscription product.
 * @param {Array} props.subscription_product_bullets - An array of bullet points for the subscription product.
 * @param {Array} props.free_product_bullets - An array of bullet points for the free subscription product.
 * @param {Object} props.auth - Authentication information from the Redux store.
 * @returns {JSX.Element} React component.
 */
const MembersPromo = (props) => {
  const {
    title,
    excerpt,
    bullets,
    products_title,
    digital_product_bullets,
    digital_subscription_product,
    subscription_product,
    subscription_product_bullets,
    free_product_bullets,
    auth
  } = props;

  if (!title || !excerpt) {
    return null;
  }

  return (
    <Section>
      <PromoContainer>
        <PromoBox>
          <Header>
            <div>
              <h2>{title}</h2>
              <p>{excerpt}</p>
            </div>
            <div>
              <ul>
                {bullets.map((bullet, i) => (
                  <li key={i}>
                    <FontAwesomeIcon icon={['fal', 'check']} /> {bullet}
                  </li>
                ))}
              </ul>
            </div>
          </Header>
          <Comparison>
            <h2>{products_title}</h2>
            <MemberOptions>
              {digital_subscription_product && (
                <OptionColumn
                  data={digital_subscription_product}
                  bullets={digital_product_bullets}
                  featurekey="digital_subscription_product"
                />
              )}
              {subscription_product && (
                <OptionColumn
                  data={subscription_product}
                  bullets={subscription_product_bullets}
                  featurekey="subscription_product"
                />
              )}
              {subscription_product && (
                <FormattedMessage id="user.label.freeUser">
                  {(text) => (
                    <OptionColumn
                      data={{
                        title: text,
                        price: false,
                        featuredImage: false,
                        uri: !auth.token && '/käyttäjä/rekisteröidy',
                        buttonText: !auth.token && 'Rekisteröidy'
                      }}
                      bullets={free_product_bullets}
                      featurekey="free_subscription_product"
                    />
                  )}
                </FormattedMessage>
              )}
            </MemberOptions>
          </Comparison>
        </PromoBox>
      </PromoContainer>
    </Section>
  );
};

MembersPromo.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  bullets: PropTypes.array,
  products_title: PropTypes.string,
  digital_subscription_product: PropTypes.object,
  digital_product_bullets: PropTypes.array,
  subscription_product: PropTypes.object,
  subscription_product_bullets: PropTypes.array,
  free_product_bullets: PropTypes.array,
  auth: PropTypes.object.isRequired
};

MembersPromo.defaultProps = {
  bullets: []
};

export default connect((state) => ({ auth: state.auth }), { store })(
  MembersPromo
);
